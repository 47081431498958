import { createChangeLog } from './changelog'

export const visibilityChanges = createChangeLog<DocumentVisibilityState>(
  'visibility',
  document.visibilityState
)

document.addEventListener('visibilitychange', (e) => {
  visibilityChanges.record(document.visibilityState, e.timeStamp)
})
