export const getCookieValue = (name: string): string | undefined =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop()

export const getAnonymousId = () => getCookieValue('mot_session')

export const getTrackingCookies = () => {
  const fbclid = getCookieValue('mot_fbclid')
  return {
    fbc:
      getCookieValue('_fbc') || (fbclid ? `fb.1.${Date.now()}.${fbclid}` : ''),
    fbclid,
    fbp: getCookieValue('_fbp'),
    gclid: getCookieValue('mot_gclid'),
    irclickid: getCookieValue('irclickid'),
    mot_session: getAnonymousId(),
    ttclid: getCookieValue('mot_ttclid'),
    ps_xid: getCookieValue('ps_xid'),
    survey_selection: getCookieValue('survey_selection'),
    onboarding_survey_submitted: getCookieValue('onboarding_survey_submitted'),
    onboarding_survey_qualified: getCookieValue('onboarding_survey_qualified'),
  }
}
