export function timeout(operation: string, ms: number) {
  return new Promise<never>((resolve, reject) => {
    setTimeout(() => reject(new TimeoutError(operation, ms)), ms)
  })
}

export class TimeoutError extends Error {
  constructor(operation: string, ms: number) {
    super(`The operation '${operation}' timed out after ${ms} ms.`)
  }
}
