import {
  CheckCircleSolid,
  LoadingSolid,
  type SvgIcon,
  XCircleSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'

import * as RadixToast from '@radix-ui/react-toast'
import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { BaseToast, type BaseToastProps, ToastClose } from './base-toast'
import { type BaseMessage, type BaseToastType } from './types'

export type ToastProps = Omit<BaseToastProps, 'children'> & {
  visible: boolean
  title: BaseMessage
  /**
   * @deprecated the description is deprecated and only present for backward compatibility with legacy notifications
   */
  description?: ReactNode
  type?: BaseToastType
}

/**
 * Renders a toast component.
 *
 * @param {ToastProps} props - The props object containing the following properties:
 *   - title: The title of the toast.
 *   - description: The description of the toast.
 *   - type: The type of the toast (success, error, loading).
 *   - timestamp: The timestamp of the toast.
 *   - duration: The duration of the toast.
 * @return {JSX.Element} The rendered text toast component.
 */
export const Toast = ({
  visible,
  title,
  description,
  type = 'neutral',
  onClose,
  duration,
}: ToastProps) => {
  const hasIcon = type !== 'neutral'

  return (
    <BaseToast visible={visible} onClose={onClose} duration={duration}>
      <ToastWrapper withIcon={hasIcon}>
        {hasIcon && <ToastIcon type={type} />}
        <RadixToast.Title asChild>
          <div className='grow'>
            <Title>{title}</Title>
            {description && <Description>{description}</Description>}
          </div>
        </RadixToast.Title>
        <ToastClose />
      </ToastWrapper>
    </BaseToast>
  )
}

const ToastWrapper = classed('div', {
  base: `
    bg-toast-bg
    px-3 py-2
    grid gap-2
    grid-cols-[min-content_minmax(0,1fr)_min-content]
    w-full
    items-start
    rounded
  `,
  variants: {
    withIcon: {
      true: 'grid-cols-[min-content_minmax(0,1fr)_min-content]',
      false: 'grid-cols-[minmax(0,1fr)_min-content]',
    },
  },
  defaultVariants: {
    withIcon: true,
  },
})

const Title = classed('div', {
  base: `text-toast-text text-sm line-clamp-3`,
})

const Description = classed('div', {
  base: 'text-toast-text mt-1 whitespace-pre-wrap break-words text-xs',
})

const iconCssMap: Record<
  Exclude<ToastIconProps['type'], 'neutral'>,
  { icon: SvgIcon; className: string }
> = {
  success: {
    icon: CheckCircleSolid,
    className: 'text-semantic-success-bg-strong-default',
  },
  error: {
    icon: XCircleSolid,
    className: 'text-semantic-error-bg-strong-default',
  },
  loading: {
    icon: LoadingSolid,
    className: 'text-toast-text animate-spin',
  },
}

interface ToastIconProps {
  type: NonNullable<Exclude<ToastProps['type'], 'neutral'>>
}
const ToastIcon = ({ type }: ToastIconProps) => {
  const { icon: Icon, className } = iconCssMap[type]

  return (
    <Icon
      width={18}
      height={18}
      className={twMerge('shrink-0 mt-px', className)}
    />
  )
}
