export const FieldTypes = [
  'text',
  'url',
  'date',
  'person',
  'multiPerson',
  'phone',
  'select',
  'multiSelect',
  'number',
  'email',
  'checkbox',
  'relatedTo',
] as const
