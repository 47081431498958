import { type SetupProjectFormFields } from '../../form-fields'

export function getSetupProjectStageDueDateChangedFields(
  _fields: SetupProjectFormFields,
  _prevFields: SetupProjectFormFields
): Partial<SetupProjectFormFields> {
  const updates: Partial<SetupProjectFormFields> = {}

  return updates
}
