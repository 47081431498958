import { classed, type VariantProps } from '@motion/theme'
import { variantColors } from '@motion/ui-logic'

import {
  type ForwardRefExoticComponent,
  type ReactNode,
  type RefAttributes,
} from 'react'

const variantStyle = {
  strikethrough: 'line-through',
  none: '',
}

const variantSizes = {
  xsmall: 'text-2xs',
  small: 'text-xs',
}

const StyledLabel = classed('span', {
  base: `
    inline-block
    px-1
    rounded
    whitespace-nowrap
    align-text-top
  `,
  variants: {
    color: variantColors,
    style: variantStyle,
    size: variantSizes,
    noTruncate: {
      true: '',
      false: 'truncate',
    },
  },
  defaultVariants: {
    color: 'grey',
    style: 'none',
    size: 'small',
    noTruncate: false,
  },
})

export const Label = StyledLabel as ForwardRefExoticComponent<
  VariantProps<typeof StyledLabel> & {
    children: ReactNode
  } & RefAttributes<HTMLSpanElement>
>

export type LabelProps = React.ComponentProps<typeof Label>
