import { omit } from '@motion/utils/core'

import { createKey, defineApi, defineMutation } from '../../core'
import { type RouteTypes } from '../types'

type GetProjectDefinitionByIdContext =
  RouteTypes<'DefinitionsController_getProjectDefinitionById'>
export const queryKeys = {
  root: createKey(['v2', 'projectDefinitions']),
  query: (args: Pick<GetProjectDefinitionByIdContext['request'], 'id'>) =>
    createKey(queryKeys.root, args.id),
}

export const getById = defineApi<
  GetProjectDefinitionByIdContext['request'],
  GetProjectDefinitionByIdContext['response']
>().using({
  method: 'GET',
  key: (args) => queryKeys.query(args),
  uri: (args) =>
    `/v2/workspaces/${args.workspaceId}/templates/projects/${args.id}`,
})

type CreateProjectDefinitionContext =
  RouteTypes<'DefinitionsController_createProjectDefinition'>
export const create = defineMutation<
  CreateProjectDefinitionContext['request'],
  CreateProjectDefinitionContext['response']
>().using({
  method: 'POST',
  uri: (args) => `/v2/workspaces/${args.workspaceId}/templates/projects`,
  body: (args) => omit(args, 'workspaceId'),
})

type UpdateProjectDefinitionContext =
  RouteTypes<'DefinitionsController_updateProjectDefinition'>
export const update = defineMutation<
  UpdateProjectDefinitionContext['request'],
  UpdateProjectDefinitionContext['response']
>().using({
  method: 'PUT',
  uri: (args) =>
    `/v2/workspaces/${args.workspaceId}/templates/projects/${args.id}`,
  body: (args) => omit(args, ['workspaceId', 'id']),
})

type DeleteProjectDefinitionContext =
  RouteTypes<'DefinitionsController_deleteProjectDefinition'>
export const deleteProjectDefinition = defineMutation<
  DeleteProjectDefinitionContext['request'],
  void
>().using({
  method: 'DELETE',
  uri: (args) =>
    `/v2/workspaces/${args.workspaceId}/templates/projects/${args.id}`,
})

type CopyProjectDefinitionContext =
  RouteTypes<'DefinitionsController_copyProjectDefinition'>
export const copyProjectDefinition = defineMutation<
  CopyProjectDefinitionContext['request'],
  CopyProjectDefinitionContext['response']
>().using({
  method: 'POST',
  uri: (args) =>
    `/v2/workspaces/${args.workspaceId}/templates/projects/copy/${args.id}`,
})
