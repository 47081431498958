import { type VirtualizedTreeNode } from '../types'

export const PrettyPath = (props: { node: VirtualizedTreeNode }) => {
  const { node } = props
  if (!node.prettyPath || !node.prettyPath.length) return null
  return (
    <div className='text-semantic-neutral-text-disabled text-xs px-1 mt-0.5'>
      {node.prettyPath.length < 3
        ? node.prettyPath.join(' / ')
        : [
            node.prettyPath[0],
            '...',
            node.prettyPath[node.prettyPath.length - 1],
          ].join(' / ')}
    </div>
  )
}
