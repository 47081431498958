import { type ReactNode } from 'react'

import { ActionList } from '../action-list'
import { PopoverTrigger, type PopoverTriggerProps } from '../popover'
import { SearchableList, type SearchableListProps } from '../searchable-list'

export type SearchableDropdownProps<T> = Omit<
  SearchableDropdownContentProps<T>,
  'close'
> & {
  onClose?: () => void
  placement?: PopoverTriggerProps['placement']
  children: ReactNode
}

export function SearchableDropdown<T extends { id: string } | string | number>(
  props: SearchableDropdownProps<T>
) {
  const { children, onClose, placement = 'bottom-start', ...rest } = props

  return (
    <PopoverTrigger
      onClose={onClose}
      placement={placement}
      renderPopover={({ close }) => (
        <SearchableDropdownContent close={close} {...rest} />
      )}
    >
      {children}
    </PopoverTrigger>
  )
}

export type SearchableDropdownContentProps<T> = {
  close?: () => void
  items: T[]
  selectedItem?: T
  onChange(item: T): void
  searchable?: SearchableListProps<T>['searchable']
  searchPlaceholder?: string
  computeSearchValue?: SearchableListProps<T>['computeSearchValue']
  computeDisabled?: SearchableListProps<T>['computeDisabled']
  renderItem: (item: T) => ReactNode
  renderEmptyContainer?: () => ReactNode
  renderFooter?: (close?: () => void) => ReactNode
}

export const SearchableDropdownContent = <
  T extends { id: string } | string | number,
>({
  close,
  items,
  selectedItem,
  onChange,
  searchable = false,
  searchPlaceholder = 'Filter...',
  computeSearchValue,
  computeDisabled,
  renderEmptyContainer,
  renderItem,
  renderFooter,
}: SearchableDropdownContentProps<T>) => {
  function getId(item?: T) {
    if (typeof item === 'string') return item
    if (typeof item === 'number') return item.toString()
    return item?.id ?? ''
  }

  return (
    <>
      {items.length === 0 ? (
        (renderEmptyContainer?.() ?? (
          <ActionList
            items={[
              {
                content: 'No items found',
                onAction: close,
                disabled: true,
              },
            ]}
          />
        ))
      ) : (
        <SearchableList
          searchable={searchable}
          items={items}
          computeDisabled={computeDisabled}
          computeKey={(item) => getId(item)}
          computeSearchValue={computeSearchValue}
          computeSelected={(item) => getId(item) === getId(selectedItem)}
          onSelect={(item) => {
            close?.()
            onChange(item)
          }}
          renderItem={renderItem}
          inputProps={{ placeholder: searchPlaceholder }}
        />
      )}
      {renderFooter != null && (
        <div className='flex flex-col border-t border-dropdown-border mt-1'>
          {renderFooter?.(close)}
        </div>
      )}
    </>
  )
}
