import { ChevronLeftOutline, ChevronRightOutline } from '@motion/icons'
import { classed } from '@motion/theme'

import { FilePreviewFallback } from './file-preview-fallback'
import { FilePreviewImage } from './file-preview-image'

import { type FilePreviewModalProps } from '../file-preview-modal'
import { fileIsImage } from '../utils'

export type FilePreviewModalContentProps = Pick<
  FilePreviewModalProps,
  | 'fileUrl'
  | 'fileName'
  | 'mimeType'
  | 'hasPrevFile'
  | 'hasNextFile'
  | 'onDownload'
  | 'onPrevFileButtonClick'
  | 'onNextFileButtonClick'
>

export function FilePreviewModalContent({
  fileUrl,
  fileName,
  mimeType,
  hasPrevFile,
  hasNextFile,
  onDownload,
  onPrevFileButtonClick,
  onNextFileButtonClick,
}: FilePreviewModalContentProps) {
  return (
    <div className='overflow-hidden relative'>
      {fileIsImage(mimeType) ? (
        <FilePreviewImage
          fileUrl={fileUrl}
          fileName={fileName}
          onDownload={onDownload}
        />
      ) : (
        <FilePreviewFallback fileName={fileName} onDownload={onDownload} />
      )}
      {hasPrevFile && (
        <ArrowButton className='left-6' onClick={onPrevFileButtonClick}>
          <ChevronLeftOutline />
        </ArrowButton>
      )}
      {hasNextFile && (
        <ArrowButton className='right-6' onClick={onNextFileButtonClick}>
          <ChevronRightOutline />
        </ArrowButton>
      )}
    </div>
  )
}

const ArrowButton = classed('button', {
  base: `
      flex items-center justify-center 
      absolute top-[50%] -translate-y-1/2
      size-[48px] rounded text-semantic-neutral-icon-onDark
      
      hover:bg-white
      hover:bg-opacity-10
      
      active:bg-white
      active:bg-opacity-15
    `,
})
