import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { type DndTabProps, Tab, type TabProps } from './tab'

export type SortableTabProps = Omit<TabProps, keyof DndTabProps> & {
  id: string
}
export const SortableTab = ({ id, ...rest }: SortableTabProps) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 1 : undefined,
  }

  return (
    <Tab
      ref={setNodeRef}
      dndStyle={style}
      dndAttributes={attributes}
      dndListeners={listeners}
      isDragging={isDragging}
      {...rest}
    />
  )
}
