import { usePersistedOnboardingState } from './use-persisted-onboarding-state'

import { log } from '../../log'
import { shouldOnboard } from '../onboarding'

export const useRequiresOnboarding = () => {
  const [data] = usePersistedOnboardingState()

  log('requires-onboarding', { ...data })
  if (data == null) return null

  if (data.isOnboardingComplete) return false

  return shouldOnboard(data.onboardingProgress, false, data.didSkipOnboarding)
}
