import { classed } from '@motion/theme'

import { Link } from '../../../internal'

export const StyledTabList = classed('ul', {
  base: 'flex flex-wrap',
  variants: {
    sortable: { true: '', false: '' },
    variant: {
      minimal: '',
      default: 'border-b border-solid border-tab-border-default',
    },
    outerPadding: {
      none: '',
      small: 'px-3',
      medium: 'px-6',
      large: 'px-12',
    },
  },
  defaultVariants: {
    sortable: false,
    variant: 'default',
    outerPadding: 'none',
  },
  dataAttributes: ['sortable'],
})

export const TabButton = classed('button', {
  base: `
    relative
    before:absolute 
    before:bottom-0 
    before:left-0 
    before:w-full 
    before:h-0 
    before:border-b-2 
    before:border-solid

    whitespace-nowrap

    font-semibold

    focus-visible:border-tab-border-focus

    disabled:opacity-40
  `,
  variants: {
    active: {
      true: `
        before:border-tab-border-selected
        text-tab-text-selected
        enabled:hover:text-tab-text-selected
      `,
      false: `
        before:border-transparent
        text-tab-text-default
        enabled:hover:text-tab-text-default
        enabled:hover:border-tab-border-hover
      `,
    },
    variant: {
      minimal: '',
      default: '',
    },
    size: {
      xsmall: 'text-[10px]',
      small: 'text-sm',
      default: 'text-sm',
    },
    isDragging: {
      true: `
        cursor-grabbing
      `,
      false: `
        enabled:cursor-pointer
        [[data-sortable="true"]_&]:disabled:cursor-grab
      `,
    },
  },
  compoundVariants: [
    {
      variant: 'default',
      size: 'default',
      className: 'pt-2.5 pb-2',
    },
    {
      variant: 'default',
      size: 'small',
      className: 'pt-1.5 pb-1',
    },
    {
      variant: 'default',
      size: 'xsmall',
      className: 'pt-1.5 pb-1',
    },
  ],
  defaultVariants: {
    active: false,
    variant: 'default',
    size: 'default',
    isDragging: false,
  },
})

export const TabLink = classed(Link, 'inline-block', TabButton)

export const TabListItem = classed('li', {
  base: `
    first:pl-0
  `,
  variants: {
    // We're using padding because the ItemMeasurer uses `getBoundingClientRect().width` which includes padding
    spacing: {
      small: 'px-1.5',
      medium: 'px-2',
    },
  },
  defaultVariants: {
    spacing: 'medium',
  },
})

export const TabListCreateNewItem = classed('li', {
  base: `
    whitespace-nowrap
    flex items-center

    before:ml-1
    before:mr-3
    before:h-5
    before:border-l
    before:border-semantic-neutral-border-subtle
  `,
})
