import {
  ProjectCubeCanceledSolid,
  ProjectCubeCompletedSolid,
  ProjectCubeSolid,
  type SvgIcon,
  type SvgIconProps,
} from '@motion/icons'
import { type COLOR } from '@motion/shared/common'

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { ProjectPalette } from '../palette'

export type ProjectColoredIconVariant = 'ACTIVE' | 'COMPLETED' | 'CANCELED'

type ProjectColoredIconProps = SvgIconProps & {
  color?: COLOR
  noProject?: boolean
  variant?: ProjectColoredIconVariant
}
export const ProjectColoredIcon = forwardRef<
  SVGSVGElement,
  ProjectColoredIconProps
>(function ProjectIcon(
  { color = 'gray', noProject = false, variant = 'ACTIVE', className, ...etc },
  ref
) {
  const isMuted = noProject || variant !== 'ACTIVE'

  const classNames = twMerge(
    '!text-palette-highlight-default shrink-0',
    isMuted && 'opacity-40',
    className
  )

  const Icon = (
    {
      ACTIVE: ProjectCubeSolid,
      CANCELED: ProjectCubeCanceledSolid,
      COMPLETED: ProjectCubeCompletedSolid,
    } satisfies Record<ProjectColoredIconVariant, SvgIcon>
  )[variant]

  return (
    <ProjectPalette color={color}>
      <Icon ref={ref} {...etc} className={classNames} />
    </ProjectPalette>
  )
})
