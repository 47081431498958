// this is defined this way so that we can utilize the typed array in zod schemas
export const COLORS = [
  'red',
  'yellow',
  'mint',
  'emerald',
  'sky',
  'lavender',
  'grape',
  'rose',
  'orange',
  'highlighter-yellow',
  'green',
  'teal',
  'blue',
  'purple',
  'pink',
  'gray',
  'tangerine',
  'lime',
  'sage',
  'cyan',
  'cobalt',
  'violet',
  'raspberry',
] as const

export type COLOR = (typeof COLORS)[number]

export const COLOR_HUES: Record<COLOR, number> = {
  red: 0,
  orange: 15,
  tangerine: 30,
  yellow: 45,
  'highlighter-yellow': 60,
  lime: 75,
  mint: 90,
  green: 105,
  sage: 135,
  emerald: 150,
  cyan: 165,
  teal: 180,
  sky: 195,
  blue: 210,
  cobalt: 225,
  lavender: 240,
  purple: 255,
  violet: 270,
  grape: 285,
  pink: 315,
  raspberry: 330,
  rose: 345,
  // Any hue not in the range 0-360 is considered gray
  // Intentionally large to avoid confusion
  gray: 0,
}
