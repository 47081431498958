import { type ReactNode, useMemo, useRef } from 'react'
import React from 'react'

import { ApplyTheme } from './apply-theme'
import { ThemeContext, type ThemeContextApi } from './context'
import {
  type MotionDesignTokenNames,
  type MotionTheme,
  type TailwindColorToken,
} from './types'
import { cssVar } from './utils'

export type ThemeResolverProps = {
  children: ReactNode
  theme?: MotionTheme
}

export const ThemeResolver = (props: ThemeResolverProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const themeRef = useRef(props.theme)

  const api = useMemo(() => {
    function resolveToken(token: MotionDesignTokenNames) {
      const el = ref.current
      if (!el) return ''

      return getComputedStyle(el).getPropertyValue(`--motion-${token}`)
    }

    function resolveTheme(): MotionTheme {
      const el = ref.current
      if (el == null) {
        return (themeRef.current ?? document.body.classList.contains('dark'))
          ? 'dark'
          : 'light'
      }

      return getComputedStyle(el).getPropertyValue(
        `--motion-theme`
      ) as MotionTheme
    }

    return {
      get theme(): MotionTheme {
        return resolveTheme()
      },
      resolve(token: MotionDesignTokenNames): string {
        return resolveToken(token)
      },
      resolveTailwindColor(token: TailwindColorToken): string {
        return resolveToken(extractDesignToken(token))
      },
      cssVar,
    } satisfies ThemeContextApi
  }, [])

  if (props.theme) {
    return (
      <ApplyTheme theme={props.theme} ref={ref}>
        <ThemeContext.Provider value={api}>
          {props.children}
        </ThemeContext.Provider>
      </ApplyTheme>
    )
  }
  return (
    <ThemeContext.Provider value={api}>{props.children}</ThemeContext.Provider>
  )
}

const TOKEN_EXTRACT_NAME = /^(bg|text|border)-(.+)$/
function extractDesignToken(
  tailwind: TailwindColorToken
): MotionDesignTokenNames {
  const match = TOKEN_EXTRACT_NAME.exec(tailwind)
  if (!match) {
    throw new Error('The token is not valid')
  }
  return match[1] as MotionDesignTokenNames
}
