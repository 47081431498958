import { DownloadOutline, DuplicateOutline, TrashOutline } from '@motion/icons'

import { Button, IconButton } from '../../../button'
import { type FilePreviewModalProps } from '../file-preview-modal'

type FilePreviewModalActionsProps = Pick<
  FilePreviewModalProps,
  'fileName' | 'onDownload' | 'onCopyLink' | 'onDelete' | 'onClose'
>

export function FilePreviewModalActions({
  onDelete,
  onCopyLink,
  onDownload,
}: FilePreviewModalActionsProps) {
  return (
    <>
      {onDownload != null && (
        <Button
          variant='muted'
          sentiment='onDark'
          size='small'
          onClick={onDownload}
          aria-label='Download file'
        >
          <DownloadOutline className='size-16' />
          Download
        </Button>
      )}
      {onCopyLink != null && (
        <IconButton
          icon={DuplicateOutline}
          size='small'
          sentiment='onDark'
          variant='muted'
          aria-label='Copy link'
          onClick={onCopyLink}
        />
      )}
      {onDelete != null && (
        <IconButton
          icon={TrashOutline}
          size='small'
          sentiment='onDark'
          variant='muted'
          aria-label='Delete file'
          onClick={onDelete}
        />
      )}
    </>
  )
}
