// Note: This module is shared across both BG and CS, so do not import any
// chromeApi modules

import { type OnboardingPage, type OnboardingProgress } from '../types'

export const shouldOnboard = (
  onboardingProgress: Partial<OnboardingProgress> | undefined,
  hasTasks: boolean,
  didSkipOnboarding: boolean
) => {
  if (didSkipOnboarding) {
    return false
  }

  if (!onboardingProgress) {
    return true
  }

  // Force onboarding if user hasn't completed calendar onboarding
  if (
    Object.keys(onboardingProgress).length === 0 ||
    !onboardingProgress.calendar
  ) {
    return true
  }

  if (!onboardingProgress.tasks && !hasTasks) {
    return true
  }

  return false
}

export const resolveOnboardingPage = async (
  onboardingProgress: OnboardingProgress
): Promise<OnboardingPage> => {
  if (!onboardingProgress || Object.keys(onboardingProgress).length === 0) {
    return 'personalize'
  }

  switch (true) {
    case !onboardingProgress.calendar:
      return 'calendar-setup'
    case !onboardingProgress.tasks:
      return 'task-tutorial'
    default:
      return 'personalize'
  }
}
