import { filterAndRankMatches } from '@motion/ui-logic'

import { type ReactNode, useCallback, useMemo, useState } from 'react'

import {
  ExpandableTreeNode,
  getSearchableNodesWithPath,
  SearchResultTreeNode,
  TreeKeyboardProvider,
  TreeNodeList,
  TreeProvider,
  useTreeKeyboardContext,
  type VirtualizedTreeNode,
  type VirtualizedTreeRootNode,
} from '../../virtualized-tree'
import {
  SearchableListInput,
  type SearchableListInputProps,
} from '../components'

export type SearchableTreeProps = {
  rootNode: VirtualizedTreeRootNode
  onSelect: (node: VirtualizedTreeNode) => void
  selectedId: VirtualizedTreeNode['id'] | null
  renderLabel: (node: VirtualizedTreeNode) => ReactNode
  renderIcon: (node: VirtualizedTreeNode) => ReactNode
  inputProps?: { placeholder: string }
  computeSelectable?: (node: VirtualizedTreeNode) => boolean
}

export const SearchableTree = (props: SearchableTreeProps) => {
  const {
    inputProps = { placeholder: 'Filter...' },
    rootNode,
    onSelect,
    selectedId,
    renderLabel,
    renderIcon,
    computeSelectable,
  } = props

  const [search, setSearch] = useState<string>('')

  const handleInputValueChange = useCallback((search: string) => {
    setSearch(search)
  }, [])

  const leafNodes = useMemo(
    () => getSearchableNodesWithPath(rootNode, computeSelectable),
    [computeSelectable, rootNode]
  )

  const filteredTree: VirtualizedTreeRootNode = useMemo(() => {
    if (!search) return rootNode
    return {
      id: '[ROOT]',
      label: '[ROOT]',
      children: filterAndRankMatches(
        search.trim(),
        leafNodes,
        (item) => item.label
      ),
    }
  }, [leafNodes, rootNode, search])

  return (
    <TreeProvider
      rootNode={filteredTree}
      onSelect={onSelect}
      selectedId={selectedId}
      computeSelectable={computeSelectable}
      renderNode={(row) =>
        search.trim() ? (
          <SearchResultTreeNode row={row}>
            {renderIcon(row.original)}
            {renderLabel(row.original)}
          </SearchResultTreeNode>
        ) : (
          <ExpandableTreeNode row={row} renderIcon={renderIcon}>
            {renderLabel(row.original)}
          </ExpandableTreeNode>
        )
      }
    >
      <TreeKeyboardProvider>
        <ConnectedSearchabelListInput
          {...inputProps}
          onValueChange={handleInputValueChange}
          search={search}
        />
        <div className='w-80 flex flex-col max-h-60'>
          {filteredTree.children.length === 0 && (
            <div className='w-full pt-4 pb-2 text-center text-sm text-semantic-neutral-text-subtle'>
              No results
            </div>
          )}

          <TreeNodeList estimateSize={() => (search ? 48 : 32)} />
        </div>
      </TreeKeyboardProvider>
    </TreeProvider>
  )
}

const ConnectedSearchabelListInput = (props: SearchableListInputProps) => {
  const { search, onValueChange, ...inputProps } = props
  const { resetFocusedIndex } = useTreeKeyboardContext()

  const handleInputValueChange = useCallback(
    (search: string) => {
      onValueChange?.(search)
      resetFocusedIndex()
    },
    [onValueChange, resetFocusedIndex]
  )

  return (
    <SearchableListInput
      {...inputProps}
      onValueChange={handleInputValueChange}
      search={search}
    />
  )
}
