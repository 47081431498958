import { type TasksV2GetByIdParamsSchema } from '@motion/zod/client'

import { queryKeys } from './keys'

import { defineApi } from '../../core'
import { type RouteTypes } from '../types'

const FIFTEEN_MINUTES = 15 * 60 * 1000
const TWO_MINUTES = 2 * 60 * 1000

export const queryTasks = defineApi<
  RouteTypes<'TasksController_queryTasks'>['request'],
  RouteTypes<'TasksController_queryTasks'>['response']
>().using({
  uri: 'v2/tasks/query',
  method: 'POST',
  key: (args) => [...queryKeys.query, args],
  queryOptions: {
    keepPreviousData: true,
  },
})

type GetTaskById = RouteTypes<'TasksController_getTaskById'>
export const getTaskById = defineApi<
  { id: string } & TasksV2GetByIdParamsSchema,
  GetTaskById['response']
>().using({
  uri: (args) => {
    const params =
      args.include.length > 0 ? `?include=${args.include.join(',')}` : ''
    return `v2/tasks/${args.id}${params}`
  },
  key: (args) => queryKeys.taskById(args.id),
  queryOptions: {
    staleTime: TWO_MINUTES,
    keepPreviousData: true,
  },
})

/**
 * Define a specific lazy task for lazy call implementation using a different key
 * because React Query use a shared signal per key when aborting queries
 * Additionally, we make sure to never cache that data since it's a lazy fetch
 * and we have internals to check the cache data
 */
export const getLazyTaskById = defineApi<
  { id: string } & TasksV2GetByIdParamsSchema,
  GetTaskById['response']
>().using({
  uri: getTaskById.uri,
  key: (args) => ['lazy', ...queryKeys.taskById(args.id)],
  queryOptions: {
    staleTime: 0,
    cacheTime: 0,
  },
})

type GetPastDeadlineTasks = RouteTypes<'TasksController_getPastDueTasks'>
export const getPastDeadlineTasks = defineApi<
  GetPastDeadlineTasks['request'],
  GetPastDeadlineTasks['response']
>().using({
  key: () => queryKeys.pastDue(),
  uri: (args) => {
    const params =
      args.include.length > 0 ? `?include=${args.include.join(',')}` : ''
    return `v2/tasks/past_due${params}`
  },
  queryOptions: {
    cacheTime: FIFTEEN_MINUTES,
    staleTime: FIFTEEN_MINUTES,
  },
})
