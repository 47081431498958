import { type QueryKey } from '@tanstack/react-query'

import {
  type Effect,
  type OptimisticEffectRemove,
  type OptimisticEffectUpdate,
  type SuccessEffectInvalidate,
  type SuccessEffectRemove,
  type SuccessEffectUpdate,
} from './types'

export function updateOnSuccess<TArgs, TData, TKey extends QueryKey>(
  args: Omit<SuccessEffectUpdate<TArgs, TData, TKey>, 'action'>
): Effect<TArgs, TData> {
  return {
    on: 'success',
    action: 'update',
    ...args,
  }
}

export function removeOnSuccess<TArgs, TData>(
  args: Omit<SuccessEffectRemove<TArgs>, 'action'>
): Effect<TArgs, TData> {
  return {
    on: 'success',
    action: 'remove',
    ...args,
  }
}

export function invalidateOnSuccess<TArgs, TData>(
  args: Omit<SuccessEffectInvalidate<TArgs>, 'action'>
): Effect<TArgs, TData> {
  return {
    on: 'success',
    action: 'invalidate',
    ...args,
  }
}

export function optimisticUpdate<TArgs, TData, TKey extends QueryKey>(
  args: Omit<OptimisticEffectUpdate<TArgs, TKey>, 'action'>
): Effect<TArgs, TData> {
  return {
    on: 'mutate',
    action: 'update',
    ...args,
  }
}
export function optimisticRemove<TArgs, TData>(
  args: Omit<OptimisticEffectRemove<TArgs>, 'action'>
): Effect<TArgs, TData> {
  return {
    on: 'mutate',
    action: 'remove',
    ...args,
  }
}
