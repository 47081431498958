import { classed } from '@motion/theme'
import { addComponentName } from '@motion/ui/helpers'

import { type ComponentProps, type CSSProperties } from 'react'

export type ProgressCircleProps = Omit<
  ComponentProps<typeof StyledProgressCircle>,
  'className' | 'style'
> & {
  value: number
  labelHidden?: boolean
  sentiment?: string
}

export function ProgressCircle({ value, ...rest }: ProgressCircleProps) {
  const parsedValue = Math.min(Math.max(value, 0), 100)
  return (
    <StyledProgressCircle
      {...addComponentName('ProgressCircle')}
      {...rest}
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={100}
      style={{ '--progress-bar-value': parsedValue } as CSSProperties}
    />
  )
}

const StyledProgressCircle = classed('div', {
  base: 'bg-progress-bar-circle shrink-0 text-[6px] text-semantic-neutral-text-default grid place-items-center rounded-xl',
  variants: {
    labelHidden: {
      true: '',
      false: `before:content-[counter(percentage)'%'] before:[counter-reset:percentage_var(--progress-bar-value)]`,
    },
    size: {
      normal: `size-5 text-[6px]`,
      small: `size-4 text-[5px]`,
      xsmall: `size-3 text-[5px]`,
    },
    sentiment: {
      default: 'bg-progress-bar-circle',
      neutral: 'bg-progress-bar-circle-neutral',
      purple: 'bg-progress-bar-circle-purple',
    },
  },
  defaultVariants: {
    size: 'normal',
    labelHidden: 'false',
    sentiment: 'default',
  },
})
