import { isNotNull } from '../types/nullable'

/**
 * Functions like `Promise.all` but will throw an `AggregatePromiseError` if any of the promises rejects
 * @param values a list of promises to wait for
 * @returns the result of the awaited promises.
 */
export async function waitForAll<T extends readonly unknown[] | []>(
  values: T
): Promise<{ -readonly [P in keyof T]: Awaited<T[P]> }> {
  const result = await Promise.allSettled(values)
  const anyFailed = result.some((x) => x.status === 'rejected')
  if (!anyFailed) {
    // @ts-expect-error - type magic
    return result.map((x) => x.value)
  }

  // @ts-expect-error - type Magic
  throw new AggregatePromiseError(result)
}

export class AggregatePromiseError extends Error {
  private _promises!: PromiseSettledResult<unknown>[]

  constructor(promises: PromiseSettledResult<unknown>[]) {
    super(`${formatErrors(promises)}`)

    'captureStackTrace' in Error &&
      typeof Error.captureStackTrace === 'function' &&
      Error.captureStackTrace(this, waitForAll)

    Object.defineProperties(Object.getPrototypeOf(this), {
      name: {
        value: 'AggregatePromiseError',
      },
    })
    Object.defineProperties(this, {
      _promises: {
        enumerable: false,
        writable: false,
        value: promises,
      },
    })
  }

  get promises(): PromiseSettledResult<unknown>[] {
    return this._promises
  }

  get errors() {
    return this._promises
      .filter((x) => x.status === 'rejected')
      .map((x) => x.reason)
  }
}

function formatErrors(promises: PromiseSettledResult<unknown>[]): string {
  const errors = promises
    .map((promise, index) => {
      if (promise.status === 'rejected') {
        return { promise, index }
      }
      return null
    })
    .filter(isNotNull)
    .map(({ promise, index }) => {
      const reason = promise.reason

      if (reason instanceof Error) {
        return { message: reason.message, index }
      }

      if (typeof reason === 'string') {
        return { message: reason, index }
      }

      return { message: JSON.stringify(reason), index }
    })

  const body = errors
    .map(
      ({ message, index }) =>
        `Index ${index}: ${message.replace(/\n/g, '\n  ')}`
    )
    .join('\n\n')

  return `${errors.length} / ${promises.length} promises rejected.\n${body}`
}
