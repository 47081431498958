import { DateTime } from 'luxon'

/**
 * Adjusts the start date if it falls after the end date
 * @param startDate - The current start date (Luxon DateTime)
 * @param endDate - The new end date (Luxon DateTime or null)
 * @returns The adjusted start date as an ISO string
 */
export function adjustStartDateBeforeEnd(
  startDate: DateTime,
  endDate: DateTime | null,
  { allowPast = false } = {}
): string {
  if (!endDate) return startDate.toISODate()

  if (startDate <= endDate) return startDate.toISODate()

  const today = DateTime.now().startOf('day')
  const endDateMinus1 = endDate.startOf('day').minus({ days: 1 })

  if (allowPast) {
    return endDateMinus1.toISODate()
  }

  return (endDateMinus1 < today ? today : endDateMinus1).toISODate()
}
