import { CheckOutline } from '@motion/icons'

import { type Row } from '@tanstack/react-table'
import { type PropsWithChildren } from 'react'

import { PrettyPath } from './pretty-path'
import { TreeNodeShell } from './tree-node-shell'

import { useTreeKeyboardContext } from '../tree-keyboard-context'
import { type VirtualizedTreeNode } from '../types'
import { useTreeContext } from '../virtualized-tree-context'

type SearchResultTreeNodeProps = PropsWithChildren<{
  row: Row<VirtualizedTreeNode>
}>

export const SearchResultTreeNode = (props: SearchResultTreeNodeProps) => {
  const { onSelect } = useTreeContext()
  const { activeRow, setActiveRow } = useTreeKeyboardContext()

  const { row, children } = props

  const node = row.original
  const selected = row.getIsSelected()

  const isActive = !!activeRow && activeRow.id === row.id

  return (
    <TreeNodeShell
      className='h-12'
      onClick={() => onSelect(row)}
      focused={isActive}
      onPointerMove={() => (!isActive ? setActiveRow(row) : undefined)}
    >
      <div className='truncate flex-grow'>
        <div className='flex items-center'>{children}</div>
        <PrettyPath node={node} />
      </div>
      {selected && (
        <CheckOutline className='text-semantic-primary-icon-default w-4 h-4 shrink-0' />
      )}
    </TreeNodeShell>
  )
}
