import { type Calendar, type EmailAccount } from '@motion/rpc/types'
import { classed } from '@motion/theme'
import { type CalendarSchema } from '@motion/zod/client'

import { type ReactNode } from 'react'

import { CompanyLogo, Tooltip } from '../../base'

export type CalendarTitleProps = {
  calendar: Calendar | CalendarSchema
  email?: string
  emailAccountsMap?: Map<string, EmailAccount>
  hideEmail?: boolean
  renderIcons?: (calendar: Calendar | CalendarSchema) => ReactNode | null
  size?: 'xsmall' | 'small' | 'normal'
  weight?: 'medium' | 'normal'
  isMyCalendarList?: boolean
}
export const CalendarTitle = ({
  calendar,
  email,
  emailAccountsMap,
  hideEmail = false,
  renderIcons,
  size = 'normal',
  weight = 'normal',
  isMyCalendarList = false,
}: CalendarTitleProps) => {
  const emailAddress =
    emailAccountsMap?.get(calendar.emailAccountId)?.email ??
    email ??
    calendar.emailAccountId.replace('firestore|', '')
  const showEmailAddress =
    isMyCalendarList ||
    (!calendar.title.includes('@') &&
      calendar.title !== emailAddress &&
      !hideEmail)

  return (
    <div className='flex gap-2 items-center justify-between min-w-0'>
      <TitleLabel size={size} weight={weight}>
        {calendar.title}
      </TitleLabel>
      {renderIcons?.(calendar)}
      {showEmailAddress && (
        <Tooltip content={emailAddress} asChild>
          <div className='h-[13px] w-[13px] min-h-[13px] min-w-[13px] [&_[data-icon]]:h-full [&_[data-icon]]:w-full'>
            <CompanyLogo provider={calendar.providerType} />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

const TitleLabel = classed('span', {
  base: 'text-semantic-neutral-text-default truncate',
  variants: {
    size: {
      xsmall: 'text-[12px] leading-2',
      small: 'text-sm',
      normal: 'text-sm',
    },
    weight: { medium: 'font-medium', normal: 'font-normal' },
  },
})
