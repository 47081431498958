/**
 * Auto Generated
 */
import { type ClientZod } from '../../../utils/client-transform'
import type * as Server from '../../../server/v2/tasks/filters.dtos'

// Generated Types

export type IdFilterSchema = ClientZod<typeof Server.IdFilterSchema>
/**
 * The value field can contain a [null] value to indicate that the filter should
 * search for tasks that don't have any ids associated to the applicable field
 */
export type IdNullableFilterSchema = ClientZod<
  typeof Server.IdNullableFilterSchema
>
export type DefinedFilterSchema = ClientZod<typeof Server.DefinedFilterSchema>
export type EmptyFilterSchema = ClientZod<typeof Server.EmptyFilterSchema>
export type InStringFilterSchema = ClientZod<typeof Server.InStringFilterSchema>
export type MatchStringFilterSchema = ClientZod<
  typeof Server.MatchStringFilterSchema
>
export type EqualsStringFilterSchema = ClientZod<
  typeof Server.EqualsStringFilterSchema
>
export type StringFilterSchema = ClientZod<typeof Server.StringFilterSchema>
export type RangeNumberFilterSchema = ClientZod<
  typeof Server.RangeNumberFilterSchema
>
export type LogicalNumberFilterSchema = ClientZod<
  typeof Server.LogicalNumberFilterSchema
>
export type NumberFilterSchema = ClientZod<typeof Server.NumberFilterSchema>
export type BooleanFilterSchema = ClientZod<typeof Server.BooleanFilterSchema>
export type RangeDateFilterSchema = ClientZod<
  typeof Server.RangeDateFilterSchema
>
export type LogicalDateFilterSchema = ClientZod<
  typeof Server.LogicalDateFilterSchema
>
export type DateFilterSchema = ClientZod<typeof Server.DateFilterSchema>
export type PriorityFilterSchema = ClientZod<typeof Server.PriorityFilterSchema>
export type ScheduledStatusFilterSchema = ClientZod<
  typeof Server.ScheduledStatusFilterSchema
>
export type DeadlineStatusAllowedFilterValues = ClientZod<
  typeof Server.DeadlineStatusAllowedFilterValues
>
export const DeadlineStatusAllowedFilterValues = [
  'none',
  'on-track',
  'missed-deadline',
  'scheduled-past-deadline',
] as const
export type DeadlineStatusFilterSchema = ClientZod<
  typeof Server.DeadlineStatusFilterSchema
>
export type CustomFieldValueFilterSchema = ClientZod<
  typeof Server.CustomFieldValueFilterSchema
>
export type RecurringFilterSchema = ClientZod<
  typeof Server.RecurringFilterSchema
>
export const RecurringFilterSchema = ['ALL', 'CURRENT', 'FUTURE'] as const
export type TaskTypeFilterSchema = ClientZod<typeof Server.TaskTypeFilterSchema>
/**
 * Note that this should be backwards compatible with older clients that
 * provide an array of strings for applicable fields. This can be removed once
 * all clients have been updated to use the new PM V3 FE.
 */
export type GetTasksV2FilterWithOperatorsSchema = ClientZod<
  typeof Server.GetTasksV2FilterWithOperatorsSchema
>
export type GetTasksV2FilterWithOperatorsRefineSchema = ClientZod<
  typeof Server.GetTasksV2FilterWithOperatorsRefineSchema
>
