import { classed, type VariantProps } from '@motion/theme'
import { addComponentName } from '@motion/ui/helpers'

import React from 'react'

type IconSize = VariantProps<typeof IconStackItem>['size']

export type IconStackProps = {
  icons: React.ReactNode[]
  iconSize?: IconSize
}

export function IconStack({ icons, iconSize = 'normal' }: IconStackProps) {
  return (
    <IconStackContainer iconSize={iconSize} {...addComponentName('IconStack')}>
      {icons.map((icon, index) => (
        <IconStackItem key={`icon-stack-${index}`} size={iconSize}>
          {icon}
        </IconStackItem>
      ))}
    </IconStackContainer>
  )
}

const IconStackContainer = classed('div', {
  base: 'flex items-center justify-center',
  variants: {
    iconSize: {
      xsmall: '-mr-0.5',
      small: '-mr-1',
      normal: '-mr-2',
    },
  },
  defaultVariants: {
    iconSize: 'normal',
  },
})

const IconStackItem = classed('span', {
  base: 'w-4 h-4 -ml-2',
  variants: {
    size: {
      xsmall: 'w-2 h-2 -ml-0.5',
      small: 'w-3 h-3 -ml-1',
      normal: 'w-4 h-4 -ml-2',
    },
  },
  defaultVariants: {
    size: 'normal',
  },
})
