export type SpanMark = number | string | PerformanceMark
export type Span = {
  name: string
  start: SpanMark
  end: SpanMark
  tags?: string[]
}

export function normalizeSpanMark(mark: SpanMark) {
  if (typeof mark === 'number') return mark
  if (typeof mark === 'string') {
    const marks = performance.getEntriesByName(
      mark,
      'mark'
    ) as PerformanceMark[]

    if (marks.length === 0) return undefined
    return marks[marks.length - 1].startTime
  }

  return mark?.startTime
}
