const emailRegex =
  /^(([^<>()[\]\\.,:\s@']+(\.[^<>()[\]\\.,:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isEmailValid = (email: string) => {
  return emailRegex.test(email)
}

/**
 * Convert email string to universal format used throughout codebase.
 */
export const formatEmail = (email: string) => {
  return email.toLocaleLowerCase().trim()
}
