import {
  getCoreRowModel,
  getExpandedRowModel,
  type Row,
  useReactTable,
} from '@tanstack/react-table'
import { type PropsWithChildren, useCallback, useMemo } from 'react'

import { TreeContext } from './tree-context'

import { type VirtualizedTreeNode, type VirtualizedTreeProps } from '../types'
import { getExpandedStateToSelected } from '../utils'

type TreeProviderProps = PropsWithChildren<VirtualizedTreeProps>

export const TreeProvider = (props: TreeProviderProps) => {
  const {
    children,
    rootNode,
    onSelect,
    selectedId,
    computeSelectable = () => true,
    renderNode,
  } = props

  const table = useReactTable({
    data: rootNode.children,
    columns: [
      {
        accessorKey: 'label',
        cell: (info) => info.getValue(),
      },
    ],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowId: (row) => row.id,
    getSubRows: (row) => row.children,
    enableRowSelection: (row) => !row.original.disabled,
    enableMultiRowSelection: false,
    enableSubRowSelection: false,
    initialState: {
      rowSelection: selectedId ? { [selectedId]: true } : {},
      expanded: selectedId
        ? getExpandedStateToSelected(rootNode, selectedId)
        : {},
    },
  })

  const handleOnSelect = useCallback(
    (row: Row<VirtualizedTreeNode>) => {
      onSelect({
        ...row.original,
        path: row.original.path ?? [
          ...row.getParentRows().map((r) => r.id),
          row.id,
        ],
      })
    },
    [onSelect]
  )

  const flatTree = table.getRowModel().rows

  const value = useMemo(
    () => ({
      flatTree,
      onSelect: handleOnSelect,
      selectedId,
      computeSelectable,
      renderNode,
    }),
    [computeSelectable, selectedId, flatTree, handleOnSelect, renderNode]
  )
  return <TreeContext.Provider value={value}>{children}</TreeContext.Provider>
}
