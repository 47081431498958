import { makeTeamBullets, teamElevatorPitch } from '@motion/ui-logic/billing'

import {
  BaseBillingPlanCard,
  type BaseBillingPlanCardProps,
} from './components/base-billing-plan-card'

type TeamBillingPlanCardProps = Pick<
  BaseBillingPlanCardProps,
  'onClick' | 'buttonText' | 'hidePrice' | 'withBorder' | 'price'
> & {
  savings?: number
}

export const TeamBillingPlanCard = ({
  onClick,
  buttonText,
  hidePrice,
  savings,
  price,
  withBorder,
}: TeamBillingPlanCardProps) => {
  return (
    <BaseBillingPlanCard
      isTeam
      subtitle={teamElevatorPitch}
      sectionHeader='Everything in Individual, plus:'
      items={makeTeamBullets(savings)}
      onClick={onClick}
      price={price}
      savingsDescription={
        savings ? `${savings}% cheaper than Individual` : undefined
      }
      planType='Team'
      withBorder={withBorder}
      buttonText={!buttonText ? 'Try Motion for free' : buttonText}
      hidePrice={hidePrice}
    />
  )
}
