import { type QueryClient } from '@tanstack/react-query'

import { getCurrentUser } from '../current-user'
import { type MotionCacheContext, type V2QueryResponseShape } from '../types'

export function isQueryResponse(
  source: unknown
): source is V2QueryResponseShape {
  return source != null && typeof source === 'object' && 'ids' in source
}

export function buildMotionCacheContext({
  client,
}: {
  client: QueryClient
}): MotionCacheContext {
  const userId = getCurrentUser(client)?.id ?? null

  return { client, userId }
}
