import {
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { API, createFetch, createQueryOptions } from '@motion/rpc'
import { setCurrentUser } from '@motion/rpc-cache'
import { makeLog } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { type GetCurrentUserResponseSchema } from '@motion/zod/client'

import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

import { useOnFirebaseUserChanged } from './hooks'
import { getAuthPostProcess } from './hooks/overrides'
import { prefetchQueries, type QueryReference } from './prefetch'
import {
  AuthStateKey,
  AuthTokenKey,
  UNSET_SIGNOUT,
  UserLoginStateKey,
} from './state'

import { bus } from '../event-bus'
import {
  firebase,
  getRedirectResult,
  type IdTokenResult,
  type User,
} from '../firebase'
import { featureFlags } from '../flags'
import { stats } from '../performance'
import { STATIC_HEADERS } from '../rpc/constants'
import { SettingsQueryKey } from '../settings'
import { DB } from '../storage'
import { SubscriptionStateKey } from '../subscriptions/state'
import { buildSubscriptionState } from '../subscriptions/utils'

const log = makeLog('firebase-auth')

function shouldCallOnLogin(urlPath: string) {
  if (isDesktop()) return false
  return true
}

export const FirebaseAuthSync = () => {
  const [authState, setAuthState] = useSharedState(AuthStateKey)
  const setAuthToken = useSharedStateSendOnly(AuthTokenKey)
  const setUserLoginState = useSharedStateSendOnly(UserLoginStateKey)
  const setUserSubscription = useSharedStateSendOnly(SubscriptionStateKey)

  const client = useQueryClient()

  if (authState.signOut === UNSET_SIGNOUT) {
    authState.signOut = async () => {
      Sentry.addBreadcrumb({ message: 'logout' })
      setAuthState((prev) => ({ ...prev, auth: { state: 'signing-out' } }))
      await DB.clearAll().catch((ex) => {
        Sentry.captureException(ex, {
          tags: { position: 'IndexedDB.clearAll' },
        })
      })
      await authState.event.fireAsync({ state: 'signing-out' })
      await firebase.auth().signOut()
      setAuthState((prev) => ({ ...prev, auth: { state: 'unauthenticated' } }))
      client.clear()

      const postLogout = createFetch(API.users.postLogoutApi, {
        token: null,
        baseUri: __BACKEND_HOST__,
        headers: STATIC_HEADERS,
      })
      await postLogout()
    }
  }

  function onLoginFailed(ex: Error) {
    log('on-login failed', ex)
    Sentry.captureException(new Error('on-login failed', { cause: ex }), {
      tags: { position: 'on-login' },
    })
    setAuthState((prev) => ({
      ...prev,
      auth: { state: 'error', error: ex },
    }))
    bus.emit('auth:user-changed', { user: null })
  }

  useOnFirebaseUserChanged(async ({ user }) => {
    log('changed')
    const shouldContinue = await getAuthPostProcess(user)
    log('shouldContinue', shouldContinue)

    if (!shouldContinue) {
      if (!user) return
      return firebase.auth().signOut()
    }

    log('user-changed', { user })
    if (user) {
      // don't call post-login for opt-space
      // TODO: this will need to be changes when we clean up startup
      if (!shouldCallOnLogin(window.location.pathname)) {
        setAuthState((prev) => ({
          ...prev,
          auth: { state: 'authenticated', user: user as User },
        }))
        bus.emit('auth:user-changed', { user })
        return
      }

      setAuthState((prev) => ({
        ...prev,
        auth: { state: 'authenticating' },
      }))

      const { status, token } = await stats.time('initialize-session', () =>
        initializeSession(user)
      )

      if (status === 'server_error') {
        return onLoginFailed(new Error('Internal Server Error'))
      }

      if (status === 'invalid_token') {
        return onLoginFailed(new Error('Invalid token'))
      }

      if (status === 'no_user') {
        // Currently 'on-login' creates a new user, so we don't need to handle this case
      }

      const queryContext = {
        token: token.token,
        baseUri: __BACKEND_HOST__,
        headers: STATIC_HEADERS,
        client,
      }

      function prefetch(query: QueryReference) {
        const queryOptions = createQueryOptions(query.query, queryContext)(
          query.args,
          {
            staleTime: query.staleTime,
            meta: { source: 'prefetch' },
          }
        )
        return client.prefetchQuery(queryOptions)
      }

      type OnLoginResponse = typeof API.users.postLoginApi.$response
      const postLoginQuery = createQueryOptions(
        API.users.postLoginApi,
        queryContext
      )

      const prefetchStart = stats.mark('prefetch.start')

      await stats.time('prefetch', () =>
        Promise.all([
          featureFlags.identify(user.email),
          ...prefetchQueries.duringAuth.map(prefetch),
          withRetry(
            () =>
              client.fetchQuery(
                postLoginQuery(undefined, { staleTime: 10 * 60 * 1000 })
              ) as Promise<OnLoginResponse>
          )
            .then((data) => {
              log('on-login complete')
              setUserLoginState(
                data.state ?? { hasOldAutoScheduledTasks: false }
              )

              setUserSubscription(buildSubscriptionState(data.subscription))
              client.setQueryData(
                API.subscriptions.getIndividualAndTeamSubscription.key(),
                data.subscription
              )

              client.setQueryData(SettingsQueryKey, data.firestore)

              setAuthState((prev) => ({
                ...prev,
                auth: { state: 'authenticated', user: user as User },
              }))

              bus.emit('auth:user-changed', { user })
              return data
            })
            .then(async (loginData) => {
              stats.measure('prefetch.login', prefetchStart.name)

              await stats.time('prefetch.post-login', () =>
                Promise.all(prefetchQueries.postAuth.map(prefetch))
              )

              return loginData
            })
            .catch(onLoginFailed),
        ])
      )

      // Set current user in cache that we just prefetched
      const currentUser = client.getQueryData<GetCurrentUserResponseSchema>(
        API.usersV2.getCurrentUser.key()
      )
      if (currentUser) {
        setCurrentUser(client, currentUser.models.users[currentUser.id])
      } else {
        Sentry.captureException(
          new Error('No current user data found in prefetch'),
          {
            extra: {
              user,
            },
            tags: { position: 'on-login' },
          }
        )
      }
    } else {
      setAuthState((prev) => {
        if (prev.auth.state === 'signing-out') return prev
        prev.signOut().catch((ex) => void 0)
        return prev
      })
      await featureFlags.identify(null)
      bus.emit('auth:user-changed', { user })
    }
  })

  useEffect(function checkRedirectLogin() {
    void getRedirectResult(firebase.auth()).then((result) => {
      if (result) {
        log('redirect-result', result)
        bus.emit('auth:redirect-result', { result })
      }
      return result
    })
  }, [])

  useEffect(
    function refreshToken() {
      return firebase.auth().onIdTokenChanged(async (user) => {
        if (user == null) {
          log('token-changed', { token: null })
          return
        }
        const token = await user.getIdToken()
        log('token-changed', { token })

        bus.emit('auth:token-changed', { token })

        setAuthToken(token)
      })
    },
    [setAuthToken]
  )

  return null
}

function isDesktop() {
  return window.location.pathname === '/web/desktop'
}

type RetryOptions = {
  retries: number
  delay: number[]
}

async function withRetry<T>(
  fn: () => Promise<T>,
  opts: RetryOptions = { retries: 3, delay: [100, 500, 1000] }
) {
  let retries = 0
  while (true) {
    try {
      // eslint-disable-next-line no-await-in-loop
      return await fn()
    } catch (ex) {
      if (retries >= opts.retries) {
        log('retries exceeded')
        throw ex
      }

      const wait = opts.delay[Math.min(retries, opts.delay.length - 1)]
      log(`on-login failed. retrying in ${wait}ms`, { ex })
      // eslint-disable-next-line no-await-in-loop
      await delay(wait)
    } finally {
      retries++
    }
  }
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

type InitializeSessionResult = {
  status: PingStatus
  token: IdTokenResult
}
async function initializeSession(user: User): Promise<InitializeSessionResult> {
  let token = await log.time(
    'get-token',
    () => user.getIdTokenResult(true),
    (value) => value
  )

  // const result = await pingUserWithToken(token)
  // if (result === 'invalid_token') {
  //   token = await log.time(
  //     'get-token',
  //     () => user.getIdTokenResult(true),
  //     (value) => value
  //   )
  //   const attempt2 = await pingUserWithToken(token)
  //   return { status: attempt2, token }
  // }

  return { status: 'success', token }
}

type PingStatus = 'success' | 'invalid_token' | 'no_user' | 'server_error'

// eslint-disable-next-line unused-imports/no-unused-vars
async function pingUserWithToken(token: IdTokenResult): Promise<PingStatus> {
  return fetch(`${__BACKEND_HOST__}/users/session`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token.token}`,
      ...STATIC_HEADERS,
    },
  })
    .then((response) => {
      if (response.status === 200 || response.status === 204) return 'success'
      if (response.status === 401 || response.status === 403)
        return 'invalid_token'
      if (response.status === 404) return 'no_user'
      return 'server_error'
    })
    .catch((ex) => {
      return 'server_error'
    })
}
