import { FilePreviewModalActions } from './components/file-preview-modal-actions'
import { FilePreviewModalContent } from './components/file-preview-modal-content'

import { useShortcut } from '../../shortcut'
import { FullscreenModal } from '../fullscreen-modal'
import { type UnstyledModalProps } from '../unstyled-modal'

export type FilePreviewModalProps = Pick<
  UnstyledModalProps,
  'visible' | 'onClose'
> & {
  fileUrl: string
  fileName: string
  mimeType: string
  hasPrevFile?: boolean
  hasNextFile?: boolean
  onPrevFileButtonClick?: () => void
  onNextFileButtonClick?: () => void
  onDelete?: () => void
  onCopyLink?: () => void
  onDownload?: () => void
}

export function FilePreviewModal({
  fileUrl,
  fileName,
  mimeType,
  hasPrevFile,
  hasNextFile,
  onPrevFileButtonClick,
  onNextFileButtonClick,
  onDelete,
  onCopyLink,
  onDownload,
  ...rest
}: FilePreviewModalProps) {
  const handleArrowLeftKeyDown = (e: KeyboardEvent) => {
    e.stopPropagation()
    onPrevFileButtonClick?.()
  }

  const handleArrowRightKeyDown = (e: KeyboardEvent) => {
    e.stopPropagation()
    onNextFileButtonClick?.()
  }

  useShortcut('ArrowLeft', handleArrowLeftKeyDown)
  useShortcut('ArrowRight', handleArrowRightKeyDown)

  return (
    <FullscreenModal
      headerTitle={fileName}
      headerActions={
        <FilePreviewModalActions
          fileName={fileName}
          onClose={rest.onClose}
          onDelete={onDelete}
          onCopyLink={onCopyLink}
          onDownload={onDownload}
        />
      }
      {...rest}
    >
      <FilePreviewModalContent
        fileUrl={fileUrl}
        fileName={fileName}
        mimeType={mimeType}
        hasPrevFile={hasPrevFile}
        hasNextFile={hasNextFile}
        onPrevFileButtonClick={onPrevFileButtonClick}
        onNextFileButtonClick={onNextFileButtonClick}
        onDownload={onDownload}
      />
    </FullscreenModal>
  )
}
