import { DateTime } from 'luxon'

import { daysBetweenDates } from './dates'

// This controls how we handle weekends. If
// Bias is BEFORE, then weekends (Saturday, Sunday) are treated as the last business day
// before the weekend (Friday). If Bias is AFTER, then weekends (Saturday, Sunday) are treated as the
// first business day after the weekend (Monday).
export enum Bias {
  BEFORE = -1,
  AFTER = 1,
}

// When we have better localization support, we'll need to
// take into account regional weekends.
const WEEKDAYS = [1, 2, 3, 4, 5] as const
const WEEKENDS = [6, 7] as const
export const isWeekend = (date: DateTime) => {
  return (WEEKENDS as readonly number[]).includes(date.weekday)
}

/**
 * Calculate the difference in business days.
 * @param from the starting date
 * @param to the ending date
 * @param bias If unspecified, from is biased AFTER and to is biased BEFORE
 * @returns the differnce in days. This can be negative.
 */
export const diffBusinessDays = (
  from: DateTime,
  to: DateTime,
  bias?: Bias
): number => {
  // If from -> to is flipped, flip them back and remember to return a negative.
  // Shift from and to to their closest business days
  const direction = from > to ? -1 : 1
  if (from > to) {
    ;[to, from] = [from, to]
  }
  while (isWeekend(from)) {
    from =
      bias === Bias.BEFORE ? from.minus({ days: 1 }) : from.plus({ days: 1 })
  }
  while (isWeekend(to)) {
    to = bias === Bias.AFTER ? to.plus({ days: 1 }) : to.minus({ days: 1 })
  }
  // It's possible after finding the next/last business day,
  // our dates are once again flipped. This only happens if both days were on the same weekend.
  if (from > to) return 0
  const days = daysBetweenDates(to, from)
  const weeks = Math.trunc(days / 7)
  const remainder = days % 7
  let businessDays = WEEKDAYS.length * Math.abs(weeks)
  for (let d = 0; d < Math.abs(remainder); d++) {
    if (!isWeekend(from.plus({ days: d }))) {
      businessDays += 1
    }
  }
  // Set -0 and +0 to 0
  if (businessDays === 0) return 0
  return businessDays * direction
}

/**
 * Adds a specified number of business days to a given date.
 * This function handles both positive and negative day values.
 * It also accounts for weekends and ensures that the result is always a business day.
 *
 * @param date - The starting date (DateTime object)
 * @param days - The number of business days to add (can be positive or negative)
 * @param bias - If unspecified, bias is set to match the direction we're adding days.
 *               At first glance, this causes some strange behavior. For instance, adding
 *               a business day to a weekend, and then subtracting a business day for the same weekend
 *               leaves you with two dates that are actually 3 business days apart. This is because
 *               when we think about weekends and business days, we intuitively "round" it in the direction
 *               we care about.
 *               For instance, if a task needs a whole business day and is due on Saturday,
 *               then you would want to work on it Thursday -> Friday.
 *               If a task can be started on Saturday and it takes one business day, you would probably have it done by Tuesday
 *               (Monday -> Tuesday)
 * @returns A new DateTime object representing the date after adding the specified business days
 *
 * @example
 * // Add 5 business days to a Monday
 * const result = addBusinessDays(DateTime.fromISO('2023-05-01'), 5);
 * // result will be 2023-05-08 (Monday)
 *
 * @example
 * // Subtract 2 business days from a Wednesday
 * const result = addBusinessDays(DateTime.fromISO('2023-05-03'), -2);
 * // result will be 2023-05-01 (Monday)
 */
export const addBusinessDays = (
  date: DateTime,
  days: number,
  bias?: Bias
): DateTime => {
  if (days === 0 && !bias) return date
  const direction = days >= 0 ? 1 : -1
  // Shift the starting date to the next business day
  while (isWeekend(date)) {
    date =
      bias === Bias.BEFORE
        ? date.minus({ days: 1 })
        : bias === Bias.AFTER
          ? date.plus({ days: 1 })
          : date.plus({ days: direction })
  }

  const weeks = Math.trunc(days / WEEKDAYS.length)
  let remainder = days % WEEKDAYS.length

  date = date.plus({ weeks })
  while (remainder) {
    date = date.plus({ days: direction })
    if (!isWeekend(date)) {
      remainder -= direction
    }
  }

  return date
}

/**
 * This function adds business days to a date, considering calendar days
 * @example
 * Input: 2023-05-01T00:00:00.000Z (Monday), (7 | 6 | 5) calendar days
 * Output: 2023-05-08T00:00:00.000Z (Monday)
 */
export const addBusinessDaysFromCalendarDays = (
  startDate: DateTime,
  calendarDays: number
): DateTime => {
  const businessDaysToAdd = getBusinessDaysFromCalendarDays(calendarDays)
  return addBusinessDays(startDate, businessDaysToAdd)
}

export const getBusinessDaysFromCalendarDays = (
  calendarDays: number
): number => {
  const weeks = Math.floor(Math.abs(calendarDays) / 7)
  const remainingDays = Math.abs(calendarDays) % 7
  const businessDays = weeks * 5 + Math.min(remainingDays, 5) // 5 business days per week, cap at 5 for remaining days

  return businessDays
}
