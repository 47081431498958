/* c8 ignore start */

import { type StoreNames } from 'idb'

import { DbConnection } from './connection'
import { recordToSentry } from './error-reporting'
import { type MotionDBConfig, type SimpleIDBStore, type ValueOf } from './types'

import { type MotionDB } from '../types'

/* 
  This will show whether there are pending requests for the indexedDB
  chrome://indexeddb-internals/#localhost
*/

export const createDb = (name: string): MotionDB => {
  const connection = new DbConnection(name)

  function createSimpleStore<TName extends StoreNames<MotionDBConfig>>(
    store: TName
  ): SimpleIDBStore<TName> {
    return {
      async clear() {
        return connection.exec((db) =>
          db.clear(store).catch(recordToSentry('clear'))
        )
      },
      async get(key: string) {
        return connection.exec((db) =>
          db.get(store, key).catch(recordToSentry('get'))
        )
      },
      async getMany(keys: string[]) {
        return connection.exec(async (db) => {
          const tx = db.transaction(store, 'readonly')
          const results = await Promise.all([
            ...keys.map((key) => tx.store.get(key)),
            tx.done,
          ]).catch(recordToSentry('getMany'))
          return results.slice(0, -1).reduce(
            (acc, cur, i) => {
              acc[keys[i]] = cur as ValueOf<TName> | undefined
              return acc
            },
            {} as Record<string, ValueOf<TName> | undefined>
          )
        })
      },
      async delete(key) {
        return connection.exec(async (db) => {
          await db.delete(store, key).catch(recordToSentry('delete'))
        })
      },
      async set(key, value: string) {
        return connection.exec(async (db) => {
          await db.put(store, value, key).catch(recordToSentry('put'))
        })
      },
    }
  }

  return {
    get version() {
      return connection.version
    },
    get name() {
      return name
    },
    provider: 'indexedDB',
    async clearAll() {
      await connection.exec(async (db) => {
        const names = ['state'] as const
        const tx = db.transaction(names, 'readwrite')
        await Promise.all(names.map((x) => tx.objectStore(x).clear())).catch(
          recordToSentry('clearAll')
        )
      })
    },

    async open() {
      await connection.open()
    },
    async close() {
      await connection.close()
    },

    get initialized() {
      return true
    },

    state: createSimpleStore('state'),
    queryCache: createSimpleStore('react-query'),
  } satisfies MotionDB
}
