import { type ReactNode, useEffect } from 'react'

import { useFirestoreSettings } from './hooks'

export const EnsureFirestoreSettingsFetched = ({
  children,
}: {
  children: ReactNode
}) => {
  const { isFetching, isFetched, isError, isSuccess, refetch } =
    useFirestoreSettings()
  useEffect(() => {
    if (!isFetching && !isFetched && !isError) {
      refetch()
    }
  }, [isFetching, isFetched, isError, refetch])

  if (isSuccess) {
    return children
  }

  return null
}
