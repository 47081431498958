import { throttle } from 'es-toolkit/compat'
// import { throttle } from 'lodash'
import { useCallback, useRef } from 'react'

type ThrottleSettings = Parameters<typeof throttle>[2]

export function useThrottledCallback<T extends (...args: any[]) => any>(
  callback: T,
  deps: React.DependencyList,
  delayMs: number,
  options?: ThrottleSettings
) {
  const callbackRef = useRef<T>()
  callbackRef.current = callback

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    throttle((...args) => callbackRef.current?.(...args), delayMs, options),
    deps
  )
}
