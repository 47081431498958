import {
  type InternalStateKey,
  type StateKey,
} from '@motion/react-core/shared-state'
import { cloneDeep } from '@motion/utils/core'

import { type DataStore } from '../types'

type TypedMap = Map<StateKey<any>, any>

type ReadPersistedStateManyOptions = {
  prefix?: string
  notFound?: 'default-value' | 'null'
}

export async function readPersistedStateMany(
  store: DataStore<string>,
  keys: StateKey<any>[],
  opts: ReadPersistedStateManyOptions = {}
): Promise<TypedMap> {
  const { prefix, notFound = 'default-value' } = opts

  function getKey(key: StateKey<any>) {
    if (!prefix) return key.name
    return `${prefix}:${key.name}`
  }

  function getStateKey(
    qualifiedKey: string
  ): InternalStateKey<any> | undefined {
    const name = last(qualifiedKey.split(':'))
    return keys.find((x) => x.name === name)
  }

  const dbKeys = keys.map(getKey)
  const values = await store.getMany(dbKeys)

  const map = Object.entries(values).reduce((acc, cur) => {
    const state = getStateKey(cur[0])
    if (state == null) return acc

    const hydrated = parse(state, cur[1], notFound)
    acc.set(state, hydrated)
    return acc
  }, new Map<StateKey<any>, any | undefined>())

  return map
}

function parse(
  key: InternalStateKey<any>,
  text: string | undefined | null,
  onNotFound: 'default-value' | 'null'
) {
  const clonedDefault =
    onNotFound === 'null' ? null : cloneDeep(key.__defaultValue)

  if (text == null) return clonedDefault
  if (key.deserialize == null) return clonedDefault

  try {
    const value = key.deserialize(text)
    return value ?? clonedDefault
  } catch (ex) {
    return clonedDefault
  }
}

function last<T>(arr: T[]) {
  return arr[arr.length - 1]
}
