import { markExposure, useExperiment } from '@motion/web-common/flags'

import { useEffect } from 'react'

export function useShouldShowSalesTaxMessage() {
  let result = false
  const locale = Intl.DateTimeFormat().resolvedOptions().locale
  const countryCode = locale.split('-')[1]
  const intlTaxCountryCodes =
    useExperiment('international-sales-tax-countries').payload?.countryCodes ??
    []

  useEffect(() => {
    if (result) {
      markExposure('international-sales-tax-countries')
    }
  }, [result])

  if (!countryCode) {
    return false
  }

  result = intlTaxCountryCodes.includes(countryCode)
  return result
}
