import { XSolid } from '@motion/icons'
import { type ComponentProps } from '@motion/theme'

import { type ReactNode, useRef } from 'react'

import { Modal, type ModalProps } from './modal'
import { Body, Footer, Header, HeaderTitle } from './shared'

import { Button } from '../button'
import { Focus } from '../focus'

export type TitleModalProps = Omit<ModalProps, 'expanded'> & {
  nonDismissable?: boolean
  cancelAction?: {
    text: ReactNode
  }
  title: ReactNode
  bodyProps?: ComponentProps<typeof Body>
}
export const TitleModal = ({
  cancelAction,
  children,
  onClose,
  nonDismissable = false,
  title,
  bodyProps = {},
  ...rest
}: TitleModalProps) => {
  const bodyRef = useRef<HTMLDivElement>(null)
  const showFooter = Boolean(cancelAction?.text)

  return (
    <Modal {...rest} onClose={onClose}>
      <Header>
        <HeaderTitle>{title}</HeaderTitle>
        {!nonDismissable && (
          <Button
            iconOnly
            onClick={onClose}
            sentiment='neutral'
            size='small'
            variant='muted'
          >
            <XSolid />
          </Button>
        )}
      </Header>
      <Focus contentRef={bodyRef}>
        <Body {...bodyProps} ref={bodyRef}>
          {children}
        </Body>
      </Focus>
      {showFooter && (
        <Footer>
          <Button onClick={onClose} sentiment='neutral' variant='outlined'>
            {cancelAction?.text}
          </Button>
        </Footer>
      )}
    </Modal>
  )
}
