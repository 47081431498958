import { useMemoDeep } from '@motion/react-core/hooks'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'

import { useQueryOptionsFactory } from './use-query-options-factory'

import { type ApiTypes, type LooseApiDefinition } from '../../core'

const UNSET_ARGS = Symbol('unset-args')

/**
 * Similar to `createUseQuery` however it does not execute the api until the `execute` method is called.
 * After initiated, this return status, error, and the result of the results like a normal `useQuery`
 *
 * Use Case:
 * For when you want access to status, data, or error within the render body,
 * however you don't have enough information up front to start the call during the render phase.
 *
 * @param api the api to call
 * @param localOptions the options to initialize with
 * @returns the same results as `useQuery` with an `execute`
 */
export function createUseQueryLazy<TApi extends LooseApiDefinition>(
  api: TApi,
  localOptions?: Partial<ApiTypes<TApi>['UseQueryOptions']>
) {
  type t = ApiTypes<TApi>

  return (
    opts?: t['UseQueryOptions']
  ): t['UseQueryResult'] & { execute(args: t['args']): Promise<t['data']> } => {
    const queryOptionsOf = useQueryOptionsFactory(api)

    const [args, setArgs] = useState<t['args'] | typeof UNSET_ARGS>(UNSET_ARGS)

    const combinedOpts = useMemoDeep({ ...localOptions, ...opts })

    const client = useQueryClient()
    const queryResults = useQuery(
      queryOptionsOf(args, { ...combinedOpts, enabled: args !== UNSET_ARGS })
    )

    const execute = useCallback(
      (args: t['args']): Promise<t['data']> => {
        setArgs(args)

        return client.fetchQuery(queryOptionsOf(args, combinedOpts))
      },
      [client, combinedOpts, queryOptionsOf]
    )

    return {
      ...queryResults,
      execute,
    }
  }
}
