import {
  ClockSolid,
  ExclamationCircleSolid,
  LockClosedSolid,
  MoonSolid,
  RefreshSolid,
} from '@motion/icons'
import { type COLOR } from '@motion/shared/common'
import { classed } from '@motion/theme'
import {
  formatTimeRange,
  SHOW_TIME_ABOVE_DURATION_MINUTES,
} from '@motion/ui-logic'
import { type DateLike } from '@motion/utils/dates'

import { type ComponentProps, forwardRef, type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { ChunkBubbleText } from './chunk-bubble-text'

import { Tooltip, type TooltipProps } from '../../base'
import { ProjectPalette } from '../../project'
import { getNameLineCount, SHORT_DURATION_THRESHOLD } from '../calendar-event'

export interface CalendarTaskProps {
  colorOption?: COLOR
  startTime: DateLike
  endTime: DateLike
  duration: number
  name: string
  locked?: boolean
  past?: boolean
  completed?: boolean
  unfit?: boolean
  pastDue?: boolean
  scheduleOverridden?: boolean
  snoozeUntil?: DateLike
  recurring?: boolean
  chunkNumber?: number
  chunkTotal?: number
  isUnvisitedStage?: boolean
  hideTime?: boolean
  renderNameTooltipContent: TooltipProps['renderContent']
  renderIconsTooltipContent?: TooltipProps['renderContent']
  renderStatus?: () => ReactNode
  onClick?: () => void
  onUnlockTask?: () => void
  onContextMenu?: ComponentProps<'div'>['onContextMenu']
  onUnsnoozeTask?: () => void
}

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const CalendarTask = forwardRef<HTMLDivElement, CalendarTaskProps>(
  function CalendarTask(props, ref) {
    const {
      colorOption = 'gray',
      startTime,
      endTime,
      duration,
      name,
      locked,
      past = false,
      completed = false,
      unfit = false,
      pastDue = false,
      scheduleOverridden = false,
      snoozeUntil,
      recurring,
      chunkNumber = 0,
      chunkTotal = 0,
      isUnvisitedStage = false,
      renderNameTooltipContent,
      renderIconsTooltipContent,
      renderStatus,
      onClick,
      onUnlockTask,
      onUnsnoozeTask,
      hideTime,
      ...rest
    } = props

    const showTime = !hideTime && duration >= SHOW_TIME_ABOVE_DURATION_MINUTES
    const isShort = duration < SHORT_DURATION_THRESHOLD

    const nameLineCount = getNameLineCount(duration)

    const showTaskIcons =
      locked ||
      recurring ||
      pastDue ||
      !!chunkTotal ||
      scheduleOverridden ||
      snoozeUntil

    const taskIcons = showTaskIcons && (
      <Tooltip asChild renderContent={renderIconsTooltipContent}>
        <Bubble className={twMerge(!isShort && 'pt-1')}>
          {!completed && scheduleOverridden && (
            <BubbleIcon
              as={ClockSolid}
              sentiment={pastDue ? 'error' : 'default'}
            />
          )}
          {!completed && !scheduleOverridden && pastDue && (
            <BubbleIcon as={ExclamationCircleSolid} sentiment='error' />
          )}
          {!completed && snoozeUntil && (
            <button
              className='w-2.5 h-2.5 text-semantic-neutral-icon-default'
              onClick={(e) => {
                e.stopPropagation()
                onUnsnoozeTask?.()
              }}
            >
              <MoonSolid className='w-full h-full' />
            </button>
          )}
          {recurring && (
            <BubbleIcon
              as={RefreshSolid}
              sentiment={unfit && !completed ? 'error' : 'default'}
            />
          )}
          {!completed && locked && (
            <button
              className='w-2.5 h-2.5 text-semantic-neutral-icon-default'
              onClick={(e) => {
                e.stopPropagation()
                onUnlockTask?.()
              }}
            >
              <LockClosedSolid className='w-full h-full' />
            </button>
          )}
          {chunkTotal > 1 && (
            <ChunkBubbleText
              chunkTotal={chunkTotal}
              chunkNumber={chunkNumber}
            />
          )}
        </Bubble>
      </Tooltip>
    )

    return (
      <ProjectPalette color={colorOption}>
        <TaskEvent
          ref={ref}
          role='button'
          {...rest}
          isUnvisitedStage={isUnvisitedStage}
          style={{ height: '100%' }}
          onClick={() => onClick?.()}
        >
          <Content short={isShort} isUnvisitedStage={isUnvisitedStage}>
            {renderStatus != null && (
              <div className={twMerge(isShort ? 'pt-[0.125px]' : 'pt-[1.5px]')}>
                {renderStatus?.()}
              </div>
            )}
            <TextContainer>
              <div data-line-count={nameLineCount}>
                {isSafari && taskIcons}

                <NameContainer
                  /* @ts-expect-error React typing don't allow css variables in styles */
                  style={{ '--line-count': nameLineCount }}
                  isSingleLine={nameLineCount === 1}
                >
                  {!isSafari && taskIcons}
                  <Tooltip asChild renderContent={renderNameTooltipContent}>
                    <Name>{name}</Name>
                  </Tooltip>
                </NameContainer>
              </div>
              {showTime && <Time>{formatTimeRange(startTime, endTime)}</Time>}
            </TextContainer>
          </Content>
          {/* Force the overlay to use a gray palette rather than the specified colorId/colorHue */}
          <EventOverlays
            className='motion-palette gray'
            active={past || completed}
          />
        </TaskEvent>
      </ProjectPalette>
    )
  }
)

const TaskEvent = classed('div', {
  base: `
  group/event-item
  min-w-full
  rounded
  relative
  border
  border-semantic-neutral-surface-overlay-bg-subtle
  bg-semantic-neutral-surface-overlay-bg-subtlest

  cursor-pointer

  before:block
  before:absolute
  before:rounded-l
  before:-left-px
  before:-top-px
  before:-bottom-px
  before:w-[4px]
  before:bg-calendar-palette-highlight-default
  `,
  variants: {
    isUnvisitedStage: {
      true: `
        border-dashed
        border-semantic-neutral-border-strong
        bg-semantic-neutral-surface-bg-default
      `,
    },
  },
})

const EventOverlays = classed(
  'div',
  'relative absolute -inset-px',
  'pointer-events-none rounded',
  '[[data-selected]_&]:invisible [[data-selected]:hover_&]:invisible',
  {
    variants: {
      active: {
        true: `
          opacity-50 bg-calendar-bg-default
          group-hover/event-item:opacity-15 group-hover/event-item:bg-calendar-palette-highlight-default
        `,
        false:
          'invisible group-hover/event-item:visible opacity-15 bg-calendar-palette-highlight-default',
      },
    },
  }
)

const Content = classed(
  'div',
  'grid grid-cols-[auto_1fr] items-start gap-[3px] pl-[5px] pr-1 text-[11px] text-semantic-neutral-text-default overflow-hidden',
  {
    variants: {
      short: {
        true: 'leading-none py-0',
        false: 'leading-4 py-px',
      },
      isUnvisitedStage: {
        true: `
          text-semantic-neutral-text-subtle
        `,
      },
    },
  }
)

const TextContainer = classed('div', 'flex flex-col gap-0.5 min-w-0')

/**
 * Why do need to use `isSingleLine`?
 * When there's only a single line, we need to use flex to ensure that the `Name` will have a fixed width such that overflow ellipsis will work.
 * Otherwise, we can use `line-clamp` to ensure that the text will be truncated at `--line-count`.
 */
const NameContainer = classed('div', 'font-medium break-words', {
  variants: {
    isSingleLine: {
      true: 'flex flex-row-reverse justify-end whitespace-nowrap',
      false: 'line-clamp-[--line-count]',
    },
  },
})

const Name = classed('span', 'overflow-hidden text-ellipsis')

const Time = classed('span', `text-[9px] leading-none truncate font-thin`)
const Bubble = classed(
  'div',
  'float-right inline-flex flex-row ml-auto gap-0.5 align-items-center py-px px-0.5 max-w-[calc(100%-4px)]',
  `
    [[data-line-count="1"]_&]:pl-3
  `
)
const BubbleIcon = classed('span', 'w-2.5 h-2.5 shrink-0', {
  variants: {
    sentiment: {
      error: 'text-semantic-error-bg-strong-default',
      default: 'text-semantic-neutral-icon-default',
    },
  },
  defaultVariants: {
    sentiment: 'default',
  },
})
