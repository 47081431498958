import { createClassed } from '@tw-classed/react'
import { twMerge } from 'tailwind-merge'

const created = createClassed({ merger: twMerge })

export const classed = created.classed

export { makeStrict } from '@tw-classed/react'
export { deriveClassed } from '@tw-classed/react'
export type * from '@tw-classed/react'
