import { type FocusEvent, forwardRef, useEffect, useRef } from 'react'

import { FieldLabel, type FieldLabelProps } from './field-label'
import { NumberInput, type NumberInputProps } from './number-input'

type BaseProps = {
  label?: FieldLabelProps['label'] // TODO: Make it required
  labelHidden?: FieldLabelProps['labelHidden']

  selectTextOnFocus?: boolean
  fullWidth?: FieldLabelProps['fullWidth']
}

export type NumberFieldProps = NumberInputProps &
  BaseProps & {
    onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  }

export const NumberField = forwardRef<HTMLLabelElement, NumberFieldProps>(
  function NumberField(props, ref) {
    const {
      label,
      labelHidden,
      selectTextOnFocus = false,
      onFocus,
      fullWidth,
      ...rest
    } = props

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      const input = inputRef.current
      if (!input || !rest.autoFocus || selectTextOnFocus) return

      try {
        const end = input.value.length
        input.setSelectionRange(end, end)
      } catch (ex) {
        // suppress errors while attempting to select text
      }
    }, [rest.autoFocus, selectTextOnFocus])

    const handleOnFocus = (
      event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (selectTextOnFocus) {
        event.currentTarget.select()
      }
      onFocus?.(event)
    }

    return (
      <FieldLabel
        ref={ref}
        label={label}
        labelHidden={labelHidden}
        fullWidth={fullWidth}
      >
        <NumberInput
          ref={inputRef}
          onFocus={handleOnFocus}
          {...(rest as NumberInputProps)}
        />
      </FieldLabel>
    )
  }
)
