import { Sentry } from '@motion/web-base/sentry'

export function recordToSentry(key: string) {
  return (ex: any) => {
    Sentry.captureException(ex, {
      tags: {
        position: 'indexeddb',
      },
      extra: {
        method: key,
      },
    })
    throw ex
  }
}
