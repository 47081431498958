import { useEffect, useRef } from 'react'

const identity = <T>(x: T) => x
const referenceEquality = (x: unknown, y: unknown) => x === y

/**
 * @param value - The value to track
 * @param transform - Optional function to transform the value before storing it
 * @param isEqual - Optional equality function to determine if the value has changed
 * @returns The previous value
 */
export function usePrevious<T>(
  value: T,
  transform: (value: T) => T = identity,
  isEqual: (a: T, b: T) => boolean = referenceEquality
) {
  const ref = useRef<T>()
  const previousValueRef = useRef<T>()

  useEffect(() => {
    // Only transform and update if the value has actually changed
    if (!isEqual(value, previousValueRef.current as T)) {
      previousValueRef.current = value
      ref.current = transform(value)
    }
  }, [value, transform, isEqual])

  return ref.current
}
