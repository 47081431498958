import { isEnterpriseEmail, templateStr } from '@motion/ui-logic'
import {
  enterpriseBullets,
  enterpriseElevatorPitch,
  PlanNames,
} from '@motion/ui-logic/billing'

import { useState } from 'react'

import {
  BaseBillingPlanCard,
  type BaseBillingPlanCardProps,
  EnterpriseEmailModal,
} from './components'

import { useEnterpriseLink } from '../hooks'

type EnterpriseBillingPlanCardProps = Pick<
  BaseBillingPlanCardProps,
  'hidePrice' | 'withBorder'
> & { location: string; email: string; displayName?: string }

export const EnterpriseBillingPlanCard = ({
  hidePrice,
  withBorder,
  location,
  email,
  displayName,
}: EnterpriseBillingPlanCardProps) => {
  const openEnterpriseLink = useEnterpriseLink()

  const [showEmailModal, setShowEmailModal] = useState(false)

  return (
    <>
      <BaseBillingPlanCard
        isTeam
        subtitle={enterpriseElevatorPitch}
        sectionHeader={templateStr('Everything in {{plan}}, plus:', {
          plan: PlanNames['Team'],
        })}
        items={enterpriseBullets}
        onClick={() => {
          if (email && isEnterpriseEmail(email)) {
            openEnterpriseLink(location, email, email, displayName)
          } else {
            setShowEmailModal(true)
          }
        }}
        savingsDescription='Dedicated expert ($10k value) for free'
        buttonText='Book Demo'
        planType='Enterprise'
        withBorder={withBorder}
        hidePrice={hidePrice}
      />
      {showEmailModal && (
        <EnterpriseEmailModal
          onClose={() => setShowEmailModal(false)}
          onAction={(workEmail) => {
            setShowEmailModal(false)
            openEnterpriseLink(location, email, workEmail, displayName)
          }}
        />
      )}
    </>
  )
}
