import type { AllModelsSchema } from '@motion/rpc-types'

import type { QueryFilters } from '@tanstack/react-query'

import { type QueryCacheDelete, type QueryCacheMatches } from './types'
import { buildDeletedInverse, matchQueries, updateQueryData } from './utils'

import { removeIndexForModel } from '../indexes/index-manager'
import { type ModelId } from '../model-cache'
import { type MotionCacheContext } from '../types'
import { isQueryResponse, log } from '../utils'

/**
 * Delete a set of models from the cache in place.
 * Only delete models matching the given types and ids
 *
 * @param ctx - The MotionCacheContext with the client and userId.
 * @param filter - The QueryFilters to determine which queries to update.
 * @param types - An array of model types to delete.
 * @param ids - An array of model IDs to delete.
 *
 * @returns An array of QueryCacheDelete objects representing the changes made.
 */
export function deleteFromCaches<TType extends keyof AllModelsSchema>(
  ctx: MotionCacheContext,
  filter: QueryFilters,
  typeOrTypes: TType | TType[],
  idOrIds: ModelId | ModelId[]
): QueryCacheDelete<TType>[] {
  const queries = matchQueries(ctx, filter)

  const cacheUpdates: QueryCacheDelete<TType>[] = []

  log.time('delete.all', () => {
    queries.forEach(([key, cacheStore]) => {
      // Not all queries have an operable cache store
      // can be any arbitrary shape, but only do updates on V2ResponseStoreShape
      if (cacheStore == null || cacheStore.models == null) return

      const types = Array.isArray(typeOrTypes) ? typeOrTypes : [typeOrTypes]

      types.forEach((type) => {
        if (cacheStore.models[type] == null) return

        const ids = Array.isArray(idOrIds) ? idOrIds : [idOrIds]
        ids.forEach((id) => {
          const inverse = buildDeletedInverse(cacheStore, type, id)
          if (inverse == null) return

          removeIndexForModel(ctx.client, type, inverse)
          const removedCount = removeModels(cacheStore, type, [id])
          if (removedCount === 0) return

          cacheUpdates.push({
            key,
            data: cacheStore,
            id,
            type,
            inverse,
          })
        })
      })
    })
  })

  if (cacheUpdates.length === 0) return []

  updateQueryData(ctx, cacheUpdates)

  return cacheUpdates
}

function removeModels<TType extends keyof AllModelsSchema>(
  target: QueryCacheMatches,
  type: TType,
  ids: ModelId[]
) {
  const model = target.models[type]
  if (model == null) return 0

  let removedCount = 0
  for (const id of ids) {
    if (model[id] == null) continue
    delete model[id]
    removedCount++
  }

  if (isQueryResponse(target) && target.meta.model === type) {
    target.ids = target.ids.filter((id) => !ids.includes(id))
  }

  return removedCount
}
