import { stripHtml } from '@motion/ui-logic'

import { type ReactNode, useContext, useEffect } from 'react'

import { HtmlContext } from './context'

export function useTitle(title: ReactNode) {
  const { pushTitle } = useContext(HtmlContext)

  useEffect(() => {
    if (!title) return

    const strippedTitle = Array.isArray(title)
      ? title.map((t) => stripHtml(t))[0]
      : stripHtml(title)

    return pushTitle(strippedTitle)
  }, [pushTitle, title])
}

export function useModalTitle(title: string | undefined) {
  const { pushModalTitle } = useContext(HtmlContext)

  useEffect(() => {
    if (!title) return

    return pushModalTitle(title)
  }, [pushModalTitle, title])
}
