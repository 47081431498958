import { type ReactNode, type ReactPortal } from 'react'
import { createPortal } from 'react-dom'

export type PortalProps = {
  children: ReactNode
  container?: Element | null | undefined
}

export const Portal = ({ children, container }: PortalProps): ReactPortal => {
  return createPortal(children, container ?? document.body)
}
