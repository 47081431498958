import { XSolid } from '@motion/icons'

import { type ReactNode, useRef, useState } from 'react'

import { Modal, type ModalProps } from './modal'
import { Body, Footer, Header, HeaderTitle } from './shared'

import { Button } from '../button'
import { ButtonGroup } from '../button-group'
import { Focus } from '../focus'

export type FormModalProps = Omit<
  ModalProps,
  'disableEscapeKey' | 'disableOverlayClick' | 'expanded'
> & {
  nonDismissable?: boolean
  submitAction: {
    text?: ReactNode
    onAction(): Promise<void> | void
    disabled?: boolean
    destructive?: boolean
    shortcut?: string
  }
  cancelAction?: {
    hidden?: boolean
    text?: ReactNode
    shortcut?: string
  }
  bodyPadded?: boolean
  title: ReactNode
}
export const FormModal = ({
  children,
  onClose,
  nonDismissable = false,
  submitAction,
  cancelAction,
  bodyPadded = true,
  title,
  ...rest
}: FormModalProps) => {
  const [busy, setBusy] = useState(false)
  const bodyRef = useRef<HTMLDivElement>(null)

  async function handleSubmit() {
    setBusy(true)
    try {
      await submitAction.onAction()
    } finally {
      setBusy(false)
    }
  }

  return (
    <Modal
      {...rest}
      onClose={onClose}
      disableEscapeKey={nonDismissable}
      disableOverlayClick={nonDismissable}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()

          if (submitAction.disabled) {
            return
          }
          void handleSubmit()
        }}
      >
        <Header>
          <HeaderTitle>{title}</HeaderTitle>
          {!nonDismissable && (
            <Button
              iconOnly
              onClick={() => onClose()}
              sentiment='neutral'
              size='small'
              variant='muted'
            >
              <XSolid />
            </Button>
          )}
        </Header>

        <Focus contentRef={bodyRef}>
          <Body ref={bodyRef} padded={bodyPadded}>
            {children}
          </Body>
          <input hidden type='submit' />
        </Focus>
        <Footer>
          <ButtonGroup>
            {!cancelAction?.hidden && (
              <Button
                onClick={() => onClose()}
                sentiment='neutral'
                variant='outlined'
                shortcut={cancelAction?.shortcut}
                data-testid='form-modal-cancel'
              >
                {cancelAction?.text ?? 'Cancel'}
              </Button>
            )}
            <Button
              type='submit'
              disabled={!!submitAction.disabled}
              sentiment={submitAction.destructive ? 'error' : 'primary'}
              loading={busy}
              shortcut={submitAction.shortcut}
              data-testid='form-modal-submit'
            >
              {submitAction.text ?? 'Save'}
            </Button>
          </ButtonGroup>
        </Footer>
      </form>
    </Modal>
  )
}
