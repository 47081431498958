import React, { forwardRef, type ReactNode } from 'react'

import { type MotionTheme } from './types'

export type ApplyThemeProps = {
  children: ReactNode
  theme: MotionTheme
}
export const ApplyTheme = forwardRef<HTMLDivElement, ApplyThemeProps>(
  (props, ref) => {
    return (
      <div className='contents' ref={ref} data-theme={props.theme}>
        {props.children}
      </div>
    )
  }
)
ApplyTheme.displayName = 'ApplyTheme'
