import { typedKey } from '../../core'
import type { RouteTypes } from '../types'

type FeedResponse =
  | RouteTypes<'TasksController_getFeedById'>['response']
  | RouteTypes<'ProjectsController_getFeedById'>['response']

const factory = typedKey<FeedResponse>().define

export const queryKeys = {
  feedById: (id: string) => factory('v2', 'feed', id),
}
