import { type Row } from '@tanstack/react-table'
import { createContext, type ReactNode } from 'react'

import { type VirtualizedTreeNode } from '../types'

export type TreeContextValue = {
  flatTree: Row<VirtualizedTreeNode>[]
  onSelect: (row: Row<VirtualizedTreeNode>) => void
  selectedId: VirtualizedTreeNode['id'] | null
  computeSelectable: (node: VirtualizedTreeNode) => boolean
  renderNode: (row: Row<VirtualizedTreeNode>) => ReactNode
}
export const TreeContext = createContext<TreeContextValue | undefined>(
  undefined
)
