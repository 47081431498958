import { useClosure } from '@motion/react-core/hooks'

import { useEffect } from 'react'
import { createKeybindingsHandler } from 'tinykeys'

import { useShortcutScope } from './use-shortcut-scope'
import { convertShortcutKeyToTinyKeys, isFocusedInput } from './utils'

const defaultOptions: Options = {
  enabled: true,
  ignoreInput: false,
}

type Handler = (evt: KeyboardEvent) => void
type Options = {
  enabled: boolean
  ignoreInput: boolean
}

/**
 * Allows defining a shortcut to execute a specific handler
 * The implementation is based on TinyKeys
 * https://jamiebuilds.github.io/tinykeys/
 *
 * @param key - shortcut key (eg. 'T' or 'mod+s' or 'Alt+j' or 'G C')
 * @param handler - handler to call when the shortcut hits
 * @param options {enabled: boolean, ignoreInput: boolean}
 */
export function useShortcut(
  key: string,
  handler: Handler,
  options: Partial<Options> = {}
) {
  const { enabled, ignoreInput }: Options = { ...defaultOptions, ...options }

  const { isInScope } = useShortcutScope()

  const shortcutHandlerClosure = useClosure((event: KeyboardEvent) => {
    // Doesn't trigger shortcuts for things that aren't in scope
    if (!isInScope()) return

    // Discard repeating keydown (basically keeping the key being pressed for a long time)
    if (event.repeat) return

    // Discard if focusing input and should ignore
    if (ignoreInput && isFocusedInput()) return

    // Prevent default behavior of the key when the shortcut hits.
    // For example, we'll prevent rendering 'ß' in a textfield when hitting `alt+s`
    // or submitting a form twice when hitting enter
    event.stopPropagation()
    event.preventDefault()
    event.stopImmediatePropagation()

    handler(event)
  })

  useEffect(() => {
    if (!enabled) return

    const convertedShortcut = convertShortcutKeyToTinyKeys(key)

    let bindingsHandler = createKeybindingsHandler({
      [convertedShortcut]: shortcutHandlerClosure,
    })

    document.addEventListener('keydown', bindingsHandler)

    return () => {
      document.removeEventListener('keydown', bindingsHandler)
    }
  }, [enabled, key, shortcutHandlerClosure])
}
