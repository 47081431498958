import { type StageSchema } from '@motion/rpc-types'
import { daysBetweenDates } from '@motion/utils/dates'

import { DateTime } from 'luxon'

import { type ExtendedDeadlineStatus, getEtaLabel } from './general'

import { formatToReadableWeekDayMonth, templateStr } from '../../utils'

type ConfigOptions = {
  normalizeToAtRisk?: boolean
}

/*
 * Normalize the stage deadline status to 'at-risk' if the stage has missed the deadline
 */
export const normalizeStageDeadlineStatus = (
  stage: StageSchema,
  configObject: ConfigOptions = { normalizeToAtRisk: true }
) => {
  let deadlineStatus = stage.deadlineStatus

  if (
    configObject.normalizeToAtRisk &&
    (deadlineStatus === 'missed-deadline' ||
      deadlineStatus === 'scheduled-past-deadline')
  ) {
    deadlineStatus = 'at-risk'
  }

  return deadlineStatus
}

export const getStageEtaTitle = (stage: StageSchema) => {
  const deadlineStatus = normalizeStageDeadlineStatus(stage, {
    normalizeToAtRisk: false,
  })
  return getEtaLabel(deadlineStatus, 'stage')
}

export const getStageEtaReason = (
  stage: StageSchema,
  pluralize: (
    numDays: number,
    singular: string,
    plural: string
  ) => string | React.ReactNode
) => {
  const deadlineStatus = stage.deadlineStatus ?? 'none'

  const scheduledDate = stage.estimatedCompletionTime
    ? stage.estimatedCompletionTime
    : null

  const dateDue = formatToReadableWeekDayMonth(stage.dueDate ?? '')

  const daysDiff =
    stage.dueDate && scheduledDate
      ? Math.abs(
          daysBetweenDates(
            DateTime.fromISO(stage.dueDate),
            DateTime.fromISO(scheduledDate)
          )
        )
      : 0

  const pluralizedText = pluralize(daysDiff, 'day', 'days')

  switch (deadlineStatus) {
    case 'missed-deadline':
      return stage.dueDate
        ? templateStr('Due {{dateDue}} ({{daysAgo}})', {
            dateDue,
            daysAgo: DateTime.fromISO(stage.dueDate).toRelative(),
          })
        : ''
    case 'scheduled-past-deadline':
    case 'at-risk':
      return scheduledDate
        ? templateStr(
            'Due {{dateDue}}. Last task is scheduled {{daysDiff}} {{pluralizedText}} after',
            {
              dateDue,
              daysDiff,
              pluralizedText,
            }
          )
        : ''
    case 'on-track':
      return scheduledDate
        ? templateStr('Due {{dateDue}} {{scheduledText}}', {
            dateDue,
            scheduledText:
              daysDiff > 0
                ? templateStr(
                    '({{daysDiff}} {{pluralizedText}} after scheduled date)',
                    {
                      daysDiff,
                      pluralizedText,
                    }
                  )
                : '(Scheduled on due date)',
          })
        : ''
    default:
      return ''
  }
}

export const getExtendedStageDeadlineStatus = (
  stage: StageSchema | null,
  configObject: ConfigOptions = { normalizeToAtRisk: false }
): ExtendedDeadlineStatus => {
  if (stage === null) return 'none'

  const deadlineStatus = normalizeStageDeadlineStatus(stage, configObject)

  if (deadlineStatus !== 'none') return stage.deadlineStatus

  if (stage.completedTime) {
    return 'completed'
  } else if (stage.canceledTime) {
    return 'cancelled'
  }

  return 'none'
}

export const getStageNoEtaReason = (
  stage: StageSchema,
  configObject: ConfigOptions = { normalizeToAtRisk: false }
) => {
  const deadlineStatus = getExtendedStageDeadlineStatus(stage, configObject)

  if (
    deadlineStatus !== 'none' &&
    deadlineStatus !== 'completed' &&
    deadlineStatus !== 'cancelled'
  )
    return null

  if (deadlineStatus === 'completed') {
    return 'Stage complete'
  } else if (deadlineStatus === 'cancelled') {
    return 'Stage canceled'
  } else if (!stage.scheduledStatus) {
    return 'No ETA because there are no auto-scheduled tasks in this stage'
  }

  return 'No ETA'
}
