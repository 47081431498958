import { createContext, useContext } from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type UIOptions = {
  // myOption: boolean
}

export const UIOptionsContext = createContext<UIOptions | undefined>(undefined)

export function useUIOptions() {
  return useContext(UIOptionsContext)
}

export function useUIOption<TName extends keyof UIOptions>(
  name: TName
): UIOptions[TName] | undefined {
  const ctx = useUIOptions()
  return ctx ? ctx[name] : undefined
}
