import abbr from '@vvo/tzdb/abbreviations.json'
import { IANAZone } from 'luxon'

/**
 * @deprecated Use `isValidTimezone` below instead
 */
export const isValidTimezoneOld = (value: unknown): boolean => {
  try {
    if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
      return false
    }

    if (typeof value !== 'string') {
      return false
    }

    // throws an error if timezone is not valid
    Intl.DateTimeFormat(undefined, { timeZone: value })
    return true
  } catch (error) {
    return false
  }
}

export const isValidTimezone = (timezone: string) =>
  IANAZone.isValidZone(timezone)

const CAPITAL_LETTERS_RE = /[A-Z]/g
function accronym(text: string) {
  return Array.from(text.matchAll(CAPITAL_LETTERS_RE))
    .map((x) => x[0])
    .join('')
}

export function getTimeZoneShortName(name: string, timestamp = Date.now()) {
  const zone = IANAZone.create(name)

  const long = zone.offsetName(timestamp, { format: 'long' })
  if (long in abbr) {
    // @ts-expect-error already checked
    return abbr[long]
  }

  const short = zone.offsetName(timestamp, { format: 'short' })
  if (short.startsWith('GMT')) {
    return accronym(long)
  }
  return short
}
