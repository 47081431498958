import { Sentry } from '@motion/web-base/sentry'
import { type UserInfoSchema } from '@motion/zod/client'

import { type QueryClient } from '@tanstack/react-query'

import { setCacheInfinite } from '../utils'

export const CURRENT_USER_KEY = ['current-user'] as const

/**
 * Sets the current user in the cache.
 * Set cacheTime to infinite to prevent the query from being removed from the cache.
 */
export function setCurrentUser(client: QueryClient, user: UserInfoSchema) {
  client.setQueryData<UserInfoSchema>(CURRENT_USER_KEY, user)
  setCacheInfinite(client, CURRENT_USER_KEY)
}

export function getCurrentUser(client: QueryClient): UserInfoSchema | null {
  const currentUserData = client.getQueryData<UserInfoSchema>(CURRENT_USER_KEY)
  if (!currentUserData) {
    Sentry.captureException(new Error('No current user data in cache lookup'))
    return null
  }

  return currentUserData
}
