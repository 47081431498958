import { classed, type VariantProps } from '@motion/theme'
import { addComponentName } from '@motion/ui/helpers'

export type ProgressBarProps = {
  value: number
} & VariantProps<typeof ProgressBarContainer>

export const ProgressBar = ({ value, sentiment }: ProgressBarProps) => {
  return (
    <ProgressBarContainer
      sentiment={sentiment}
      {...addComponentName('ProgressBar')}
    >
      <ProgressBarInner
        sentiment={sentiment}
        style={{
          width: `${value}%`,
        }}
      />
    </ProgressBarContainer>
  )
}

const ProgressBarContainer = classed('div', {
  base: 'bg-opacity-10 h-3 rounded-sm',
  variants: {
    sentiment: {
      primary: 'bg-semantic-neutral-surface-overlay-bg-subtle',
      gradient: 'bg-progress-bar-purple',
    },
  },
  defaultVariants: {
    sentiment: 'primary',
  },
})

const ProgressBarInner = classed('div', {
  base: 'h-full bg-opacity-100 rounded-sm',
  variants: {
    sentiment: {
      primary: 'bg-button-neutral-outlined-border-focus',
      gradient: 'bg-progress-bar-purple',
    },
  },
  defaultVariants: {
    sentiment: 'primary',
  },
})
