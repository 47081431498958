export function stripHtml(input: unknown): string {
  if (typeof input !== 'string') {
    return ''
  }

  // Remove HTML tags, normalize spaces, and handle punctuation
  return input
    .replace(/<[^>]*>/g, '') // Strip tags
    .replace(/\s+/g, ' ') // Normalize multiple spaces to a single space
    .replace(/\s+([,.!?])/g, '$1') // Remove spaces before punctuation
    .trim() // Remove leading and trailing spaces
}
