import { XSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { addComponentName } from '@motion/ui/helpers'

import { type ReactNode } from 'react'

import { BannerContainer } from './banner-container'
import {
  type BannerSentiment,
  type BannerSize,
  type BannerVariant,
} from './constants'

import { Button, type ButtonProps, IconButton } from '../button'
import { ButtonGroup } from '../button-group'

type Action = {
  label: ButtonProps['children']
  variant?: ButtonProps['variant']
  onAction?: ButtonProps['onClick']
  sentiment?: ButtonProps['sentiment']
  url?: ButtonProps['url']
  loading?: ButtonProps['loading']
}

export type BannerProps = {
  children?: ReactNode
  isDismissed?: boolean
  onDismiss?: () => void

  sentiment?: BannerSentiment
  variant?: BannerVariant
  size?: BannerSize
  actions?: Action[]
}

export const Banner = ({
  isDismissed = false,
  onDismiss,
  children,
  sentiment = 'default',
  variant = 'default',
  actions = [],
  size,
}: BannerProps) => {
  if (isDismissed) return null

  return (
    <BannerContainer
      sentiment={sentiment}
      variant={variant}
      size={size}
      {...addComponentName('Banner')}
    >
      <Content variant={variant}>
        {children}
        {actions.length > 0 && (
          <ButtonGroup>
            {actions.map((action) => (
              <Button
                key={String(action.label)}
                size={size}
                onClick={action.onAction}
                variant={action.variant}
                sentiment={
                  action.sentiment ?? getButtonSentiment(sentiment, variant)
                }
                url={action.url}
                loading={action.loading}
              >
                {action.label}
              </Button>
            ))}
          </ButtonGroup>
        )}
      </Content>

      {onDismiss && (
        <IconButton
          icon={XSolid}
          size='small'
          variant='muted'
          onClick={onDismiss}
        />
      )}
    </BannerContainer>
  )
}

const Content = classed('div', {
  base: 'grow flex items-center gap-3',
  variants: {
    variant: {
      default: 'justify-center',
      onPage: 'justify-left',
    },
  },
})

function getButtonSentiment(
  sentiment: BannerSentiment,
  variant: BannerVariant
): ButtonProps['sentiment'] {
  if (sentiment === 'error') {
    if (variant === 'onPage') {
      return 'error'
    }

    return 'onDark'
  }
  if (sentiment === 'default') {
    return 'primary'
  }
  return sentiment
}
