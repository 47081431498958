/* eslint-disable react-refresh/only-export-components */
import {
  createContext,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

interface HtmlContextValue {
  pushTitle: (title: string) => void
  pushModalTitle: (title: string) => void
}

export const HtmlContext = createContext<HtmlContextValue>({
  pushTitle: () => undefined,
  pushModalTitle: () => undefined,
})

type HtmlProviderProps = {
  children: ReactNode
  defaultTitle: string
  titleSuffix?: string
}

export function HtmlProvider({
  children,
  defaultTitle,
  titleSuffix = '',
}: HtmlProviderProps) {
  const [pageTitleStack, setPageTitleStack] = useState<string[]>([])
  const [modalTitleStack, setModalTitleStack] = useState<string[]>([])

  useEffect(() => {
    const title =
      modalTitleStack[modalTitleStack.length - 1] ??
      pageTitleStack[pageTitleStack.length - 1]

    document.title = title ? truncate(title, 250) + titleSuffix : defaultTitle
  }, [defaultTitle, modalTitleStack, pageTitleStack, titleSuffix])

  const pushTitle = useCallback<HtmlContextValue['pushTitle']>(
    (title, { fromModal = false } = {}) => {
      setPageTitleStack((prev) => [...prev, title])
      return () => {
        setPageTitleStack((prev) => prev.slice(0, -1))
      }
    },
    []
  )

  const pushModalTitle = useCallback<HtmlContextValue['pushModalTitle']>(
    (title) => {
      setModalTitleStack((prev) => [...prev, title])
      return () => {
        setModalTitleStack((prev) => prev.slice(0, -1))
      }
    },
    []
  )

  const value = useMemo<HtmlContextValue>(() => {
    return { pushTitle, pushModalTitle }
  }, [pushTitle, pushModalTitle])

  return <HtmlContext.Provider value={value}>{children}</HtmlContext.Provider>
}

function truncate(value: string, limit: number) {
  if (value.length > limit) {
    return value.substring(0, limit) + '…'
  }

  return value
}
