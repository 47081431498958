import { API } from '@motion/rpc'
import { type ClientFirebaseSettingsDto } from '@motion/rpc-types'
import { cloneDeep } from '@motion/utils/core'
import { makeLog } from '@motion/web-base/logging'

import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

import { useAuthenticatedUser } from '../auth/hooks'
import { firebase } from '../firebase'

const log = makeLog('firestore')

export const ListenForFirestoreChanges = () => {
  const user = useAuthenticatedUser()
  const client = useQueryClient()

  useEffect(() => {
    if (!user?.uid) return

    return firebase
      .firestore()
      .collection<ClientFirebaseSettingsDto>('settings')
      .doc(user.uid)
      .onSnapshot((snapshot) => {
        log('snapshot updated', snapshot.metadata, snapshot.data())

        if (snapshot.metadata.fromCache) return

        const current = client.getQueryData<ClientFirebaseSettingsDto>(
          API.userSettings.getFirestoreSettings.key()
        )
        const data = current
          ? { ...current, ...snapshot.data() }
          : snapshot.data()

        client.setQueryData(
          API.userSettings.getFirestoreSettings.key(),
          cloneDeep(data)
        )
      })
  }, [client, user.uid])

  return null
}
