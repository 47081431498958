import React from 'react'

// Helpful to delay unmounting a component so that exit animations can finish

export const useDelayedUnmount = (isMounted: boolean, delay: number) => {
  const [shouldRender, setShouldRender] = React.useState(isMounted)

  // Delay unmount
  React.useEffect(() => {
    if (isMounted) {
      return void setShouldRender(true)
    }

    const timeout = setTimeout(() => {
      setShouldRender(false)
    }, delay)

    return () => void clearTimeout(timeout)
  }, [isMounted, delay])

  return shouldRender
}
