import { notifyManager } from '@tanstack/react-query'
import { type QueryClient } from '@tanstack/react-query'

import { hydrateFromIndex, readIndex } from './indexes'
import { type IndexName, type IndexTargets } from './indexes/types'
import {
  applyPartialToCaches,
  deleteFromCaches,
  type QueryCacheDelete,
  type QueryCachePatch,
  type QueryCacheUpsert,
  updateQueryCaches,
} from './methods'
import { DELETE_MODEL } from './methods/constants'
import { type Model, type ModelId } from './model-cache'
import { type DropFirst } from './types'
import { buildMotionCacheContext, log } from './utils'

interface IMotionCache {
  /**
   * Provide full array values when patching,
   * arrays are replaced if new value
   */
  patch: (
    client: QueryClient,
    ...args: DropFirst<Parameters<typeof applyPartialToCaches>>
  ) => {
    changes: ReturnType<typeof applyPartialToCaches>
    rollback: () => void
  }

  upsert: (
    client: QueryClient,
    ...args: DropFirst<Parameters<typeof updateQueryCaches>>
  ) => {
    changes: ReturnType<typeof updateQueryCaches>
    rollback: () => void
  }

  delete: (
    client: QueryClient,
    ...args: DropFirst<Parameters<typeof deleteFromCaches>>
  ) => {
    changes: ReturnType<typeof deleteFromCaches>
    rollback: () => void
  }

  /**
   * Read the ids of the models that are indexed by the given index.
   * Returns only what is in the cache. Not guranteed to be complete.
   */
  readIndex: {
    (client: QueryClient, indexName: IndexName, sourceId: ModelId): ModelId[]
  }

  /**
   * Read the models that are indexed by the given index.
   * Returns only what is in the cache. Not guranteed to be complete.
   */
  hydrateFromIndex: {
    (
      client: QueryClient,
      indexName: IndexName,
      sourceId: ModelId
    ): Model<IndexTargets>[]
  }
}

export const MotionCache: IMotionCache = {
  patch: (
    client: QueryClient,
    ...args: DropFirst<Parameters<typeof applyPartialToCaches>>
  ) => {
    const ctx = buildMotionCacheContext({ client })

    const changes = applyPartialToCaches(ctx, ...args)
    return {
      changes,
      rollback: createRollbackFunction(changes, (item) =>
        MotionCache.patch(
          client,
          { queryKey: item.key, exact: true },
          item.type,
          {
            [item.id]: item.inverse,
          }
        )
      ),
    }
  },

  upsert: (
    client: QueryClient,
    ...args: DropFirst<Parameters<typeof updateQueryCaches>>
  ) => {
    const ctx = buildMotionCacheContext({ client })

    const changes = updateQueryCaches(ctx, ...args)
    return {
      changes,
      rollback: createRollbackFunction(changes, (item) =>
        item.inverse === DELETE_MODEL
          ? MotionCache.delete(
              client,
              { queryKey: item.key, exact: true },
              item.type,
              item.id
            )
          : MotionCache.patch(
              client,
              { queryKey: item.key, exact: true },
              item.type,
              { [item.id]: item.inverse }
            )
      ),
    }
  },

  delete: (
    client: QueryClient,
    ...args: DropFirst<Parameters<typeof deleteFromCaches>>
  ) => {
    const ctx = buildMotionCacheContext({ client })

    const changes = deleteFromCaches(ctx, ...args)
    return {
      changes,
      rollback: createRollbackFunction(changes, (item) =>
        MotionCache.upsert(
          client,
          { queryKey: item.key, exact: true },
          { models: { [item.type]: { [item.id]: item.inverse } } }
        )
      ),
    }
  },

  readIndex: readIndex,

  hydrateFromIndex: hydrateFromIndex,
}

function createRollbackFunction(
  changes: (
    | QueryCacheUpsert<any>
    | QueryCacheDelete<any>
    | QueryCachePatch<any>
  )[],
  rollbackAction: (item: any) => void
) {
  return () => {
    log.debug('~ Rollback ~ changes:', changes)
    notifyManager.batch(() => {
      changes.flat().forEach((item) => {
        rollbackAction(item)
      })
    })
  }
}
