import { TextField, type TextFieldProps } from '../../../forms'

export type SearchableListInputProps = Pick<TextFieldProps, 'placeholder'> & {
  onValueChange: TextFieldProps['onChange']
  search: TextFieldProps['value']
}

export const SearchableListInput = ({
  onValueChange,
  search,
  placeholder,
}: SearchableListInputProps) => {
  return (
    <div className='border-b border-dropdown-border'>
      <TextField
        label='Search'
        labelHidden
        placeholder={placeholder}
        value={search}
        onChange={(value) => {
          onValueChange?.(value)
        }}
        variant='muted'
        autoFocus
        autoComplete='off'
        autoCorrect='off'
        spellCheck={false}
        aria-autocomplete='list'
        role='combobox'
      />
    </div>
  )
}
