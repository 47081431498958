import { type QueryKey } from '@tanstack/react-query'

type KeyPart = number | string

export type Flatten<
  T extends KeyPart | readonly (KeyPart | readonly KeyPart[])[],
> = T extends KeyPart
  ? [T]
  : T extends readonly KeyPart[]
    ? [...T]
    : T extends [
          infer U extends KeyPart | readonly KeyPart[],
          ...infer V extends (KeyPart | readonly KeyPart[])[],
        ]
      ? [...Flatten<U>, ...Flatten<V>]
      : never

export type TypedQueryKey<
  TKey extends QueryKey = QueryKey,
  TResponse = unknown,
> = TKey & {
  __data: TResponse
}

export type ExtractQueryKey<T extends QueryKey> =
  T extends TypedQueryKey<infer TQueryKey, any> ? TQueryKey : T

export type TypedQueryKeyData<T extends QueryKey> =
  T extends TypedQueryKey<any[], infer TResponse> ? TResponse : unknown

export function createKey<T extends readonly (KeyPart | readonly KeyPart[])[]>(
  ...parts: T
): Flatten<T> {
  return parts.flat() as Flatten<T>
}

type CreateTypedKeyFn<TResponse> = <
  T extends readonly (KeyPart | readonly KeyPart[])[],
>(
  ...parts: T
) => TypedQueryKey<Flatten<T>, TResponse>

function createTypedKeyFn<
  T extends readonly (KeyPart | readonly KeyPart[])[],
  TResponse,
>(...parts: T): TypedQueryKey<Flatten<T>, TResponse> {
  return parts.flat() as TypedQueryKey<Flatten<T>, TResponse>
}

export function typedKey<TResponse>(): { define: CreateTypedKeyFn<TResponse> } {
  return {
    define: createTypedKeyFn,
  }
}

export function createKey2<T extends readonly (KeyPart | readonly KeyPart[])[]>(
  ...parts: T
) {
  return {
    typed<TResponse>() {
      return createTypedKeyFn<T, TResponse>(...parts)
    },
    raw() {
      return parts
    },
  }
}
