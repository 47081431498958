import { XSolid } from '@motion/icons'

import { type ReactNode } from 'react'

import { IconButton } from '../../../button'

export type FullscreenModalHeaderProps = {
  title?: string
  actions?: ReactNode
  onClose: () => void
}

export function FullscreenModalHeader({
  title,
  actions,
  onClose,
}: FullscreenModalHeaderProps) {
  return (
    <div className='grid grid-flow-col auto-cols-auto items-center p-4 border-b border-semantic-neutral-border-active'>
      {title && (
        <span className='text-semantic-neutral-text-onDark text-sm'>
          {title}
        </span>
      )}
      <div className='grid grid-flow-col items-center justify-end gap-2'>
        {actions}
        <div className='flex items-center pl-2'>
          <IconButton
            icon={XSolid}
            size='small'
            sentiment='onDark'
            variant='muted'
            aria-label='Close'
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  )
}
