import { cloneDeep, merge } from '@motion/utils/core'

import { type QueryKey } from '@tanstack/react-query'

import {
  createKey,
  defineApi,
  defineMutation,
  optimisticUpdate,
  SKIP_UPDATE,
} from '../../core'
import { type RouteTypes } from '../types'

export const queryKeys = {
  root: createKey(['v2', 'users']),
  me: () => createKey(queryKeys.root, 'me'),
  mySettings: () => createKey(queryKeys.me(), 'settings'),
  pageViewSettings: () => createKey(queryKeys.me(), 'pageViewSettings'),
}

type GetMySettings = RouteTypes<'UsersController_getMySettings'>

type UpdateCtaSettings =
  RouteTypes<'UsersController_updateMySettingsCallToActions'>

type UpdateTimezoneSettings = RouteTypes<'UsersController_updateMyTimezones'>

type UpdateAutoScheduleSettings =
  RouteTypes<'UsersController_updateAutoScheduleSettings'>

type UpdateOnboardingSettings =
  RouteTypes<'UsersController_updateMyOnboardingSettings'>

// TODO: cache needs to be added to rpc
const MODEL_CACHE_KEY: QueryKey = ['model-cache']

export const settingsQueryKeysToUpdate = [
  queryKeys.mySettings(),
  MODEL_CACHE_KEY,
]

export const getMySettings = defineMutation<
  GetMySettings['request'],
  GetMySettings['response']
>().using({
  key: () => queryKeys.mySettings(),
  uri: `/v2/users/me/settings`,
  method: 'GET',
})

export const updateCtaSettings = defineMutation<
  UpdateCtaSettings['request'],
  UpdateCtaSettings['response']
>().using({
  key: () => queryKeys.mySettings(),
  uri: `/v2/users/me/settings/call-to-actions`,
  method: 'PATCH',
  effects: [
    {
      on: 'mutate',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value, prev) => (prev ? { ...prev, ...value } : SKIP_UPDATE),
    },
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value) => value,
    },
  ],
})

export const updateTimezoneSettings = defineMutation<
  UpdateTimezoneSettings['request'],
  UpdateTimezoneSettings['response']
>().using({
  key: () => queryKeys.mySettings(),
  uri: `/v2/users/me/settings/timezones`,
  method: 'PATCH',
  effects: [
    {
      on: 'mutate',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value, prev) => (prev ? { ...prev, ...value } : SKIP_UPDATE),
    },
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value) => value,
    },
  ],
})

export const updateAutoScheduleSettings = defineMutation<
  UpdateAutoScheduleSettings['request'],
  UpdateAutoScheduleSettings['response']
>().using({
  key: () => queryKeys.mySettings(),
  uri: `/v2/users/me/settings/auto-schedule`,
  method: 'PATCH',
  effects: [
    {
      on: 'mutate',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value, prev) => (prev ? { ...prev, ...value } : SKIP_UPDATE),
    },
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value) => value,
    },
  ],
})

export const updatePostgresOnboardingSettings = defineMutation<
  UpdateOnboardingSettings['request'],
  UpdateOnboardingSettings['response']
>().using({
  uri: '/v2/users/me/settings/onboarding',
  method: 'PATCH',
  body: (args) => args,
  effects: [
    {
      on: 'mutate',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value, prev) => (prev ? { ...prev, ...value } : SKIP_UPDATE),
    },
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value) => value,
    },
  ],
})

type UpdatePostgresConferenceSettings =
  RouteTypes<'UsersController_updateMyConferenceSettings'>
export const updatePostgresConferenceSettings = defineMutation<
  UpdatePostgresConferenceSettings['request'],
  UpdatePostgresConferenceSettings['response']
>().using({
  method: 'PATCH',
  uri: '/v2/users/me/settings/conference',
  body: (args) => args,
  effects: [
    {
      on: 'mutate',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value, prev) => (prev ? { ...prev, ...value } : SKIP_UPDATE),
    },
    {
      on: 'success',
      action: 'update',
      key: (args) => queryKeys.mySettings(),
      merge: (value) => value,
    },
  ],
})

type UpdateFolderSettings = RouteTypes<'UsersController_updateFolderSettings'>

export const updateFolderSettings = defineMutation<
  UpdateFolderSettings['request'],
  UpdateFolderSettings['response']
>().using({
  uri: '/v2/users/me/settings/folder-settings',
  method: 'PATCH',
  effects: [
    optimisticUpdate({
      key: () => queryKeys.mySettings(),
      merge: (
        { folderId, ...newFolderState },
        prev: UpdateFolderSettings['response'] | undefined
      ) => {
        if (!prev) return SKIP_UPDATE

        const data = cloneDeep(prev)
        const userSettingsKey = Object.keys(data.models.userSettings)[0]

        if (!userSettingsKey) return SKIP_UPDATE

        const prevFolderState =
          data.models.userSettings[userSettingsKey].folderState[folderId] ?? {}

        data.models.userSettings[userSettingsKey].folderState[folderId] = merge(
          prevFolderState,
          newFolderState
        )

        return data
      },
    }),
    {
      on: 'success',
      action: 'update',
      key: () => queryKeys.mySettings(),
      merge: (
        newValue,
        prevValue: UpdateFolderSettings['response'] | undefined,
        { folderId }
      ) => {
        if (!prevValue) return newValue

        const data = cloneDeep(newValue)
        const userSettingsKey = Object.keys(data.models.userSettings)[0]

        if (!userSettingsKey) return newValue

        const prevFolderState = cloneDeep(
          prevValue.models.userSettings[userSettingsKey].folderState
        )
        const modifiedFolderState =
          data.models.userSettings[userSettingsKey].folderState[folderId]

        if (prevFolderState && modifiedFolderState) {
          data.models.userSettings[userSettingsKey].folderState = merge(
            prevFolderState,
            { [folderId]: modifiedFolderState }
          )
        }

        return data
      },
    },
  ],
})

type UpdateTaskDefaultSettings =
  RouteTypes<'UsersController_updateMyTaskDefaultSettings'>

export const updateTaskDefaultSettings = defineMutation<
  UpdateTaskDefaultSettings['request'],
  UpdateTaskDefaultSettings['response']
>().using({
  method: 'PATCH',
  uri: '/v2/users/me/settings/task-defaults',
  body: (args) => args,
})

type UpdateCalendarDisplaySettings =
  RouteTypes<'UsersController_updateCalendarDisplaySettings'>

export const updateCalendarDisplaySettings = defineMutation<
  UpdateCalendarDisplaySettings['request'],
  UpdateCalendarDisplaySettings['response']
>().using({
  method: 'PATCH',
  uri: '/v2/users/me/settings/calendar-display',
  body: (args) => args,
})

type GetCurrentUser = RouteTypes<'UsersController_getCurrentUser'>
export const getCurrentUser = defineApi<
  GetCurrentUser['request'],
  GetCurrentUser['response']
>().using({
  key: queryKeys.me,
  method: 'GET',
  uri: '/v2/users/me',
  queryOptions: {
    staleTime: 30 * 60 * 1000, // 30 min
  },
})

type AllPageViewSettings = RouteTypes<'UsersController_getAllPageViewSettings'>
export const getAllPageViewSettings = defineApi<
  AllPageViewSettings['request'],
  AllPageViewSettings['response']
>().using({
  key: queryKeys.pageViewSettings,
  method: 'GET',
  uri: '/v2/users/me/settings/page-view',
})

type UpsertPageViewsSettings =
  RouteTypes<'UsersController_upsertPageViewSettings'>
export const upsertPageViewsSettings = defineMutation<
  UpsertPageViewsSettings['request'],
  UpsertPageViewsSettings['response']
>().using({
  method: 'PUT',
  uri: (args) => `/v2/users/me/settings/page-view/${args.type}/${args.id}`,
  body: ({ id, type, ...args }) => args,
})
