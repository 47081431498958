import { type StatusSchema, type TaskSchema } from '@motion/rpc-types'
import { isCanceledStatus } from '@motion/shared/common'
import { groupByKey } from '@motion/utils/array'
import { daysBetweenDates } from '@motion/utils/dates'

import { DateTime } from 'luxon'

import { type ExtendedDeadlineStatus, getEtaLabel } from './general'

import { formatToReadableWeekDayMonth, templateStr } from '../../utils'

export const normalizeTaskDeadlineStatus = (task: TaskSchema) => {
  return 'deadlineStatus' in task && task.deadlineStatus
    ? task.deadlineStatus
    : 'none'
}

export const getTaskEtaTitle = (task: TaskSchema) => {
  const deadlineStatus = normalizeTaskDeadlineStatus(task)
  return getEtaLabel(deadlineStatus, 'task')
}

export const getTaskEtaReason = (
  task: TaskSchema,
  pluralize: (
    numDays: number,
    singular: string,
    plural: string
  ) => string | React.ReactNode
) => {
  const deadlineStatus = normalizeTaskDeadlineStatus(task)

  const scheduledDate =
    task.type === 'NORMAL' && task.estimatedCompletionTime
      ? task.estimatedCompletionTime
      : (task.scheduledEnd ?? null)

  const dateDue = formatToReadableWeekDayMonth(task.dueDate ?? '')

  const daysDiff =
    task.dueDate && scheduledDate
      ? Math.abs(
          daysBetweenDates(
            DateTime.fromISO(task.dueDate),
            DateTime.fromISO(scheduledDate)
          )
        )
      : 0

  const pluralizedText = pluralize(daysDiff, 'day', 'days')

  switch (deadlineStatus) {
    case 'missed-deadline':
      return task.dueDate
        ? templateStr('Due {{dateDue}} ({{daysAgo}})', {
            dateDue,
            daysAgo: DateTime.fromISO(task.dueDate).toRelative(),
          })
        : ''
    case 'scheduled-past-deadline':
      return scheduledDate
        ? templateStr(
            'Due {{dateDue}}. Scheduled {{daysDiff}} {{pluralizedText}} after',
            {
              dateDue,
              daysDiff,
              pluralizedText,
            }
          )
        : ''
    case 'on-track':
      return scheduledDate
        ? templateStr('Due {{dateDue}} {{scheduledText}}', {
            dateDue,
            scheduledText:
              daysDiff > 0
                ? templateStr(
                    '({{daysDiff}} {{pluralizedText}} after scheduled date)',
                    {
                      daysDiff,
                      pluralizedText,
                    }
                  )
                : '(Scheduled on due date)',
          })
        : ''
    default:
      return ''
  }
}

export const getExtendedTaskDeadlineStatus = (
  task: TaskSchema | null,
  workspaceStatuses: StatusSchema[] = []
): ExtendedDeadlineStatus => {
  if (task === null) return 'none'

  const deadlineStatus = normalizeTaskDeadlineStatus(task)
  if (deadlineStatus !== 'none') return deadlineStatus

  const statusesMap = groupByKey(workspaceStatuses, 'id')
  if (task.completedTime) return 'completed'

  const taskStatus = statusesMap[task.statusId]?.[0] ?? null
  if (!taskStatus) return 'none'

  if (isCanceledStatus(taskStatus)) return 'cancelled'

  return 'none'
}

export const getTaskNoEtaReason = (
  task: TaskSchema,
  workspaceStatuses: StatusSchema[]
) => {
  const deadlineStatus = getExtendedTaskDeadlineStatus(task, workspaceStatuses)

  if (
    deadlineStatus !== 'none' &&
    deadlineStatus !== 'completed' &&
    deadlineStatus !== 'cancelled'
  )
    return null

  const isUnfit =
    'scheduledStatus' in task && task.scheduledStatus === 'UNFIT_SCHEDULABLE'

  if (deadlineStatus === 'completed') {
    return 'Task complete'
  } else if (deadlineStatus === 'cancelled') {
    return 'Task canceled'
  } else if (isUnfit) {
    return 'No ETA because Motion only schedules tasks for the next 92 days. Once this task can be fit within the next 92 day period, it will be auto-scheduled.'
  } else if (!task.isAutoScheduled) {
    return 'No ETA because this task is not auto-scheduled'
  }

  return 'No ETA'
}
