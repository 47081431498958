import { type StripeSubscriptionSchema } from '@motion/zod/client'

import { DateTime } from 'luxon'

import { type SubscriptionResponse, type SubscriptionState } from './types'

export const validStatuses = ['trialing', 'active', 'past_due'] as const

export function isValidStatus(
  status: string
): status is (typeof validStatuses)[number] {
  return validStatuses.includes(status)
}

// there's a copy of this function in utils
export const calculateIsValid = (response: SubscriptionResponse) => {
  const stripeSubscription = response.combined?.subscription

  if (!stripeSubscription) return false
  if (!stripeSubscription.status) return false
  if (!isValidStatus(stripeSubscription.status)) return false
  if (!stripeSubscription.current_period_end) return false

  const expiryDate = DateTime.fromMillis(
    stripeSubscription.current_period_end * 1_000
  ).plus({
    days: 1,
  })

  return expiryDate > DateTime.now()
}

export const buildSubscriptionState = (
  sub: SubscriptionResponse | null | undefined
): SubscriptionState => {
  if (sub === undefined) return { state: 'unset' }
  if (sub === null) return { state: 'none', value: null }
  const isActive = calculateIsValid(sub)

  return {
    state: isActive ? 'active' : 'inactive',
    value: sub,
  }
}

export const isTeamSubscription = (
  subscription: StripeSubscriptionSchema
): boolean => {
  // TODO: Fix types and remove cast. This is hotfix code.
  const plan = subscription.plan ?? (subscription.items.data[0] as any).plan
  const itemCode = plan?.metadata?.ItemCode
  if (!itemCode) {
    return (subscription.items.data[0].quantity ?? 1) > 1
  }
  return itemCode === 'TEAM' || itemCode !== 'INDI'
}
