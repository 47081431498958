/**
 * Returns a new array with the element at the specified index replaced with a new value
 * @param array The source array
 * @param index The index to replace at
 * @param value The new value
 * @returns A new array with the replaced element
 */
export function replace<T>(array: T[], index: number, value: T): T[] {
  if (index < 0 || index >= array.length) {
    return array
  }

  return array.toSpliced(index, 1, value)
}
