/**
 * A 'typesafe' way to iterate over the entries of an object.
 * NOTE: This can be dangerous as an object can have more properties on it than is in the type
 *
 * @param obj the object to iterate
 * @returns a typed tuple of [key, value]
 */
export function entries<T extends Record<string, any>>(
  obj: T
): [key: keyof T, value: T[keyof T]][] {
  return Object.entries(obj) as [key: keyof T, value: T[keyof T]][]
}

/**
 * A 'typesafe' way to iterate over the keys of an object
 * NOTE: This can be dangerous as an object can have more properties on it than is in the type
 * @param obj the object to iterate
 * @returns the typed list of keys
 */
export function keys<T extends Record<string, any>>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[]
}

/**
 * A 'typesafe' way to iterate over the values of an object
 * @param obj the object to iterate
 * @returns the typed list of values
 */
export function values<T extends Record<string, any>>(obj: T): T[keyof T][] {
  return Object.values(obj) as T[keyof T][]
}
