import { Button } from '../../../button'
import { type FilePreviewModalProps } from '../file-preview-modal'

const FALLBACK_MESSAGE = "This file can't be previewed"

type FilePreviewFallbackProps = Pick<
  FilePreviewModalProps,
  'fileName' | 'onDownload'
>

export function FilePreviewFallback({
  fileName,
  onDownload,
}: FilePreviewFallbackProps) {
  return (
    <div className='flex items-center justify-center h-full w-full'>
      <div className='flex flex-col items-center gap-2'>
        <span className='text-lg'>{FALLBACK_MESSAGE}</span>
        <span className='text-sm mb-6'>{fileName}</span>
        {onDownload && (
          <Button onClick={onDownload} aria-label='Download file'>
            Download
          </Button>
        )}
      </div>
    </div>
  )
}
