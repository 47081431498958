import { DateTime, Interval } from 'luxon'

import { DateLike, parseDate } from './parse-date'

export const DowShortFormat = 'ccc'
export const DateFormat = 'MM/dd/yy'
export type Day = 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU'

/**
 * Two letter, uppercase days of the week MO, TU, WE, TH, FR, SA, SU
 */
export const daysOfTheWeek: Day[] = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']

export const replaceDate = (dateToCopy: DateTime, dateToReplace: DateTime) => {
  return dateToReplace.set({
    day: dateToCopy.get('day'),
    month: dateToCopy.get('month'),
    year: dateToCopy.get('year'),
  })
}

export const dateTimeFromFormats = (
  time: string,
  formats: string[],
  timezone: string
): DateTime | undefined => {
  let result: DateTime | undefined = undefined
  formats.forEach((format) => {
    try {
      result = DateTime.fromFormat(time, format, { zone: timezone })
    } catch (err) {}
  })

  return result
}

/*
 * Returns whether date1 and date2 are the same day
 *
 * Completely disregards the time attached to the dates
 * */
export const isSameDay = (date1: DateLike, date2: DateLike) => {
  return (
    parseDate(date1).startOf('day').toISODate() ===
    parseDate(date2).startOf('day').toISODate()
  )
}

/**
 * Get the whole number of days between two DateTimes. This accounts for
 * fractional diffs that can occur during daylight savings.
 *
 * For example, diff between starts of 11/9 and 11/4 is just over 5.
 */
export const daysBetweenDates = (date1: DateTime, date2: DateTime) => {
  return Math.floor(date1.diff(date2, 'days').days)
}

/**
 * For a given JS date that represents a specific date (WITHOUT time), convert
 * into a luxon DateTime in the specified timezone without affecting the date
 *
 * E.g. if `date` is `2022-12-01 00:00:00.000Z` and `timezone` is
 * `America/Los_Angeles`, this will return `2022-12-01 00:00:00.000-07:00`
 * @param date Date object that contains the date and NO time data in UTC format
 * @param timezone The target timezone to convert to date into
 */
export const makeDateFromJSDate = (date: Date, timezone: string) => {
  return DateTime.fromObject(
    {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    },
    { zone: timezone }
  )
}

/**
 * Calculate the start of the week for a given
 * luxon DateTime, where the start of the week
 * is Sunday. luxon.DateTime.startOf('week') is called
 * on monday, so we must implement our own to handle
 * the case where it is sunday.
 */
export const startOfWeekFromTime = (time: DateTime): DateTime => {
  // 1 is monday, 7 is sunday
  if (time.weekday === 7) {
    return time.startOf('day')
  }
  return time.startOf('week').minus({ days: 1 })
}

/**
 * Calculate the end of the week for a given
 * luxon DateTime, where the start of the week
 * is Sunday. luxon.DateTime.endOf('week') is called
 * on Sunday, so we must implement our own to handle
 * the case where it is sunday.
 */
export const endOfWeekFromTime = (time: DateTime): DateTime => {
  // If it's sunday, we want to go to the end of the next week,
  // so add a day and then call end of week and shift back by 1
  // day (since luxon days are from Monday -> Sunday, but we want
  // to start from Sunday -> Saturday).
  if (time.weekday === 7) {
    return time.plus({ days: 1 }).endOf('week').minus({ days: 1 })
  }
  return time.endOf('week').minus({ days: 1 })
}

/**
 * Converts a given DateTime object into the day of the week format.
 */
export const dateTimeToDay = (time: DateTime): Day => {
  return time.toFormat(DowShortFormat).substring(0, 2).toUpperCase() as Day
}

export const makeDateFromDateString = (
  date: string,
  timezone: string
): DateTime => {
  return DateTime.fromFormat(date, 'MM/dd/yy', { zone: timezone })
}

/**
 * Counts the number of days spanned, inclusive of the start and end dates
 */
export const getDaySpan = (start: DateTime, end: DateTime) => {
  const startDate = start.startOf('day')
  const endDate = end.startOf('day')
  return daysBetweenDates(endDate, startDate) + 1
}
export type DateRange = { start: DateTime; end: DateTime }

export type DateOnlyRange = {
  /**
   * ISODate
   */
  start: string
  /**
   * ISODate
   */
  end: string
}

/**
 * Given an array of date only ranges, merge and return an array of
 * non-overlapping date only ranges
 * @param ranges
 * @returns
 */
export const mergeDateRanges = (ranges: DateOnlyRange[]): DateOnlyRange[] => {
  if (!ranges.length) return []

  const intervals = ranges.map((range) =>
    Interval.fromDateTimes(
      DateTime.fromISO(range.start),
      DateTime.fromISO(range.end).plus({ days: 1 })
    )
  )

  return Interval.merge(intervals).map((range) => {
    return {
      start: range.start.toISODate(),
      end: range.end.minus({ days: 1 }).toISODate(),
    }
  })
}

export const truncateTime = (date: DateLike) => {
  const parsedDate = parseDate(date)

  return new Date(parsedDate.toISODate())
}

export const isDateBetweenDates = (
  prevDate: DateTime | null,
  nextDate: DateTime | null,
  date: DateTime,
  options: { inclusive?: boolean } = { inclusive: true }
): boolean => {
  if (!prevDate || !nextDate) return false

  return (
    (options.inclusive ? prevDate <= date : prevDate < date) &&
    (options.inclusive ? date <= nextDate : date < nextDate)
  )
}
