export const newOnboardingPrePurchasePages = [
  'individual_or_team_prompt',
  'billing',
] as const

export const newOnboardingPostPurchasePages = [
  'work_email_prompt',
  '1_min_video',
  'connect_calendar',
  'choose_work_hours',
  'pm_tm_video',
  'create_team',
] as const

export const newOnboardingPages = [
  ...newOnboardingPrePurchasePages,
  ...newOnboardingPostPurchasePages,
] as const

export type NewOnboardingPage = (typeof newOnboardingPages)[number]

export type OnboardingPage =
  | 'invited-pm-member'
  | 'personalize'
  | 'calendar-setup'
  | 'team-setup'
  | 'task'
  | 'referral'
  | 'scheduler'
  | 'desktop-app'
  | 'shortcuts'
  | 'flexible-events'
  | 'task-tutorial'
  | 'scheduler-tutorial'
  | 'flexible-tutorial'

export type OnboardingProgressKey =
  | 'calendar'
  | 'calendarTasks'
  | 'tasks'
  | 'scheduler'
  // newOnboarding fields
  | 'billing'
  | 'individualOrTeam'
  | 'oneMinVideo'
  | 'connectCalendar'
  | 'chooseWorkHours'
  | 'pmTmvideo'
  | 'createTeam'
  | 'alreadyInTeam'
  | 'isPmOnboardingInProgress'

export type OnboardingProgress = Record<OnboardingProgressKey, boolean>

export type PersistedOnboardingState = {
  isOnboardingComplete?: boolean | undefined
  didSkipOnboarding: boolean
  onboardingProgress?: Record<string, boolean>
  onboardingLatestScreen: string
  lastOnboardTimestamp: number
  onboardingType?: OnboardingType | undefined
  onboardingVersion: string
  onboardingExtraData?: PMOnboardingExtraData | undefined
  onboardingCompletedSurvey: boolean
}

export type OnboardingType =
  | 'individual'
  | 'individual_no_card'
  | 'team'
  | 'team_invitee'
  | 'team_with_card'

export type PMOnboardingExtraData = {
  workspaceId?: string
  projectId?: string
  didSkipPmOnboarding?: boolean
}
