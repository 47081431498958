import { useState } from 'react'

import { stats } from './stats'
import { type Mark } from './types'

export const useMountMark = (name: Mark) => {
  const [mountTime] = useState(() => {
    const now = performance.now()
    stats.mark(name, [], now)
    return now
  })
  return mountTime
}

export const useMountMeasure = (name: string, from: Mark) => {
  const [entry] = useState(() => {
    return stats.measure(name, from)
  })
  return entry
}

const measureOnceCache = new Map<string, boolean>()

type MountMeasureWhenContext = {
  initialPath: string
}

type MountMeasureOnceProps = {
  name: string
  from: Mark
  tags?: string[]
  when?: (ctx: MountMeasureWhenContext) => boolean
}
export const useMountMeasureOnce = (props: MountMeasureOnceProps) => {
  if (measureOnceCache.has(props.name)) return
  if (props.when && !props.when({ initialPath: window._timings.initialPath }))
    return

  measureOnceCache.set(props.name, true)
  stats.measure(props.name, props.from, props.tags)
}
