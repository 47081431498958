import { type ModalOnDismissFn } from './types'

export const ModalDismissed = Symbol('ModalDismissed')

export type PromptCallbacks<T> = {
  onValue: (value: T) => void
  onDismiss: ModalOnDismissFn
}

export interface ModalDefinitions {}
