import { type EmailAccountSchema } from '@motion/rpc-types'
import {
  type CalendarEventSchemaV2,
  type CalendarSchema,
} from '@motion/zod/client'

export function isEventReadOnly({
  event,
  hostEmailAccount,
  hostCalendar,
}: {
  event:
    | Pick<CalendarEventSchemaV2, 'attendees' | 'organizer'>
    | null
    | undefined
  hostEmailAccount: Pick<EmailAccountSchema, 'email'> | null | undefined
  hostCalendar: Pick<CalendarSchema, 'accessRole'> | null | undefined
}): boolean {
  if (hostEmailAccount == null || hostCalendar == null) return true

  if (event == null) return false

  const isEventOrganizer =
    event.organizer?.email === hostEmailAccount.email ||
    event.attendees.length === 0

  const isCalendarEditable = hostCalendar.accessRole !== 'VIEWER'

  return !isEventOrganizer || !isCalendarEditable
}
