import { type Row } from '@tanstack/react-table'
import { createContext } from 'react'

import { type VirtualizedTreeNode } from '../types'

export type TreeKeyboardContextValue = {
  activeRow: Row<VirtualizedTreeNode> | null
  setActiveRow: (row: Row<VirtualizedTreeNode>) => void
  focusedIndex: { index: number; scroll: boolean }
  resetFocusedIndex: () => void
}
export const TreeKeyboardContext = createContext<
  TreeKeyboardContextValue | undefined
>(undefined)
