import type { AllModelsSchema } from '@motion/rpc-types'
import { entries } from '@motion/utils/object'

import type { QueryFilters, QueryKey } from '@tanstack/react-query'

import { applyPartialToTargetStore } from './shared'
import {
  type ModelStore,
  type QueryCacheMatches,
  type QueryCachePatch,
} from './types'
import { matchQueries, updateQueryData } from './utils'

import { type Model } from '../model-cache'
import { type MotionCacheContext, type PatchStoreShape } from '../types'
import { log } from '../utils'

/**
 * Patch the cache with a partial update.
 *
 * @param ctx - The MotionCacheContext with the client and userId.
 * @param filter - The QueryFilters to determine which queries to update.
 * @param patch - The partial update to apply to the cache.
 *
 * @returns An array of QueryCachePatch objects representing the changes made.
 */
export function applyPartialToCaches<TType extends keyof AllModelsSchema>(
  ctx: MotionCacheContext,
  filter: QueryFilters,
  typeOrTypes: TType | TType[],
  patch: PatchStoreShape
): QueryCachePatch<TType>[] {
  const freshQueries = matchQueries(ctx, { ...filter, stale: false })
  const staleQueries = matchQueries(ctx, { ...filter, stale: true })

  const queries = [...freshQueries, ...staleQueries]

  const cacheUpdates: QueryCachePatch<TType>[] = []

  log.time('patch.all', () => {
    queries.forEach(([key, cacheStore]) => {
      // Not all queries have an operable cache store
      // can be any arbitrary shape, but only do updates on V2ResponseStoreShape
      if (cacheStore == null || cacheStore.models == null) return

      log.time('patch', (): void => {
        const updates = processQueryCachePatch(
          ctx,
          cacheStore,
          typeOrTypes,
          patch,
          key
        )
        cacheUpdates.push(...updates)
      })
    })
  })

  updateQueryData(ctx, cacheUpdates)

  // Using queryClient.setQueryData marks the query as no longer stale.
  // To ensure updated queries are correctly refetched when needed,
  // we manually invalidate the stale queries that were updated
  staleQueries.forEach(([key]) => {
    ctx.client.invalidateQueries(key)
  })

  return cacheUpdates
}

function processQueryCachePatch<TType extends keyof AllModelsSchema>(
  ctx: MotionCacheContext,
  cacheStore: QueryCacheMatches,
  typeOrTypes: TType | TType[],
  patch: PatchStoreShape,
  key: QueryKey
): QueryCachePatch<TType>[] {
  const cacheUpdates: QueryCachePatch<TType>[] = []

  const types = Array.isArray(typeOrTypes) ? typeOrTypes : [typeOrTypes]
  types.forEach((type) => {
    if (cacheStore.models[type] == null) return

    const targetStore = cacheStore.models[type] as ModelStore<TType>

    entries(patch).forEach(([_id, _partial]) => {
      const id = _id as string
      const partial = _partial as Partial<Model<TType>>

      const applied = applyPartialToTargetStore(targetStore, id, partial)

      if (!applied.changed) return

      log.debug('applied', {
        key,
        type,
        id,
        updates: partial,
        target: targetStore[id],
      })

      cacheUpdates.push({
        key,
        data: cacheStore,
        id,
        type,
        updates: partial,
        inverse: applied.inverse,
      })
    })
  })

  return cacheUpdates
}
