import {
  FileTypeCSV,
  FileTypeDOC,
  FileTypeDOCX,
  FileTypeImage,
  FileTypePDF,
  FileTypePPT,
  FileTypePPTX,
  FileTypeTXT,
  FileTypeXLS,
  FileTypeXLSX,
  FileTypeZIP,
  type SvgIconProps,
} from '@motion/icons'

import { type FunctionComponent } from 'react'

export const ICONS_MIME_TYPE_REGEX: [
  FunctionComponent<SvgIconProps>,
  RegExp,
][] = [
  [FileTypeCSV, /text\/csv/],
  [FileTypeDOC, /application\/msword/],
  [
    FileTypeDOCX,
    /application\/vnd.openxmlformats-officedocument.wordprocessingml.document/,
  ],
  [FileTypeImage, /image\//],
  [FileTypePDF, /application\/pdf/],
  [FileTypePPT, /application\/vnd.ms-powerpoint/],
  [
    FileTypePPTX,
    /application\/vnd.openxmlformats-officedocument.presentationml.presentation/,
  ],
  [FileTypeTXT, /text\//],
  [FileTypeXLS, /application\/vnd.ms-excel/],
  [
    FileTypeXLSX,
    /application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet/,
  ],
  [FileTypeZIP, /application\/zip/],
]
