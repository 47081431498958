import { useContext } from 'react'

import { ModalApiContext } from './context'
import { type ModalDefinitions } from './definitions'
import { type ModalApi, type ModalOptions } from './types'

export const useModalApi = <
  TModals = ModalDefinitions,
>(): ModalApi<TModals> => {
  return useContext(ModalApiContext).api as ModalApi<TModals>
}

export function useModalStatus<TName extends string & keyof ModalDefinitions>(
  name: TName
): ModalOptions<ModalDefinitions[TName]>
export function useModalStatus<TModals, TName extends string & keyof TModals>(
  name: TName
): ModalOptions<TModals[TName]>
export function useModalStatus<TModals, TName extends string & keyof TModals>(
  name: TName
) {
  const api = useModalApi<TModals>()
  return api.status(name)
}
