import { classed } from '@motion/theme'

import { type ReactNode } from 'react'

import { Modal, type ModalProps } from './modal'
import { type ModalAction } from './types'

import { Button } from '../button'

export type AlertModalProps = Pick<ModalProps, 'visible'> & {
  children: ReactNode
  title: ReactNode

  banner?: ReactNode

  actions: ModalAction[]

  blocking?: boolean
  onClose?: ModalProps['onClose']
}

export const AlertModal = (props: AlertModalProps) => {
  const {
    visible,
    onClose = () => {},
    title,
    banner,
    actions,
    blocking = false,
    children,
  } = props

  if (actions.length === 0) {
    throw new Error('The AlertModal requires at least one action.')
  }

  return (
    <Modal
      visible={visible}
      disableOverlayClick={blocking}
      disableEscapeKey={blocking}
      onClose={onClose}
    >
      <Shell>
        {banner && <BannerContainer>{banner}</BannerContainer>}
        <Contents>
          <Header>
            <p>{title}</p>
          </Header>
          {children}
        </Contents>
        <VerticalActionBar>
          {actions.map(({ label, onAction, ...props }) => (
            <Button key={label} onClick={onAction} {...props}>
              {label}
            </Button>
          ))}
        </VerticalActionBar>
      </Shell>
    </Modal>
  )
}

const Shell = classed('div', {
  base: `flex flex-col gap-6 w-[512px] text-semantic-neutral-text-default`,
})

const Contents = classed('div', {
  base: `
    font-sans text-sm
    flex flex-col gap-2
    px-6
  `,
})

const Header = classed('div', {
  base: `
    font-sans font-semibold text-base
    grid grid-cols-[1fr_auto]
    pt-6
  `,
})

const BannerContainer = classed('div', {
  base: 'grid',
})

const VerticalActionBar = classed('div', {
  base: `flex flex-col gap-2 p-6 pt-0`,
})
