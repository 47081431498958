import { useEffect, useState } from 'react'

export function useMediaQuery(query: string) {
  const [isMatch, setIsMatch] = useState(() => window.matchMedia(query).matches)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query)
    const handleMediaChange = (event: MediaQueryListEvent) => {
      setIsMatch(event.matches)
    }

    mediaQueryList.addEventListener('change', handleMediaChange)
    return () => {
      mediaQueryList.removeEventListener('change', handleMediaChange)
    }
  }, [query])

  return isMatch
}
