/* c8 ignore start */

import { useEffect, useState } from 'react'

type ElementSize = {
  width: number
  height: number
}

type Options = 'border-box' | 'content-box' | 'device-pixel-content-box'

export function useElementSize(boxModel: Options = 'border-box') {
  const [element, setElement] = useState<HTMLElement | null>(null)
  const [size, setSize] = useState<ElementSize>({ width: 0, height: 0 })

  useEffect(() => {
    const el = element
    if (el == null) return

    const obs = new ResizeObserver((entries) => {
      setSize(normalizeEntry(entries[0], boxModel))
    })
    obs.observe(el, { box: boxModel })

    return () => obs.disconnect()
  }, [boxModel, element])

  return [size, setElement] as const
}

// Normalizes the ResizeObserverEntry to handle some edge cases
// https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry#examples

function normalizeEntry(entry: ResizeObserverEntry, mode: Options) {
  const prop =
    mode === 'border-box'
      ? 'borderBoxSize'
      : mode === 'content-box'
        ? 'contentBoxSize'
        : 'devicePixelContentBoxSize'

  if (entry[prop]) {
    if (entry[prop][0]) {
      return {
        width: entry[prop][0].inlineSize,
        height: entry[prop][0].blockSize,
      }
    }
    return {
      // @ts-expect-error - handles old firefox
      width: entry[prop].inlineSize,
      // @ts-expect-error - handles old firefox
      height: entry[prop].blockSize,
    }
  }
  return {
    width: entry.contentRect.width,
    height: entry.contentRect.height,
  }
}
