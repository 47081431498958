import {
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { type PropsWithChildren, useMemo } from 'react'

import { TreeContext } from './tree-context'

import { type MultiSelectVirtualizedTreeProps } from '../types'

type TreeProviderProps = PropsWithChildren<MultiSelectVirtualizedTreeProps>

export const MultiSelectTreeProvider = (props: TreeProviderProps) => {
  const { children, rootNode, onSelect, selectedIds, renderNode } = props

  const rowSelection = selectedIds.reduce<Record<string, true>>((acc, id) => {
    acc[id] = true
    return acc
  }, {})

  const table = useReactTable({
    data: rootNode.children,
    columns: [
      {
        accessorKey: 'label',
        cell: (info) => info.getValue(),
      },
    ],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowId: (row) => row.id,
    getSubRows: (row) => row.children,
    enableRowSelection: (row) => !row.original.disabled,
    onRowSelectionChange: (updater) => {
      const currentSelection = table.getState().rowSelection

      const updatedSelection =
        typeof updater === 'function' ? updater(currentSelection) : updater
      const selectedRowIds = Object.keys(updatedSelection).filter(
        (id) => updatedSelection[id]
      )
      onSelect(selectedRowIds)
    },
    state: {
      rowSelection,
    },
    initialState: {
      rowSelection,
    },
  })

  const flatTree = table.getExpandedRowModel().rows

  const value = useMemo(
    () => ({
      flatTree,
      onSelect: () => null,
      selectedId: selectedIds[0] ?? null,
      computeSelectable: () => true,
      renderNode,
    }),
    [selectedIds, flatTree, renderNode]
  )
  return <TreeContext.Provider value={value}>{children}</TreeContext.Provider>
}
