import { type Row } from '@tanstack/react-table'
import { type PropsWithChildren } from 'react'

import { PrettyPath } from './pretty-path'
import { TreeNodeShell } from './tree-node-shell'

import { Checkbox } from '../../../forms'
import { useTreeKeyboardContext } from '../tree-keyboard-context'
import { type VirtualizedTreeNode } from '../types'
import { useTreeContext } from '../virtualized-tree-context'

type SearchResultTreeNodeProps = PropsWithChildren<{
  row: Row<VirtualizedTreeNode>
}>

export const SearchResultCheckboxTreeNode = (
  props: SearchResultTreeNodeProps
) => {
  const { onSelect } = useTreeContext()
  const { activeRow, setActiveRow } = useTreeKeyboardContext()

  const { row, children } = props

  const node = row.original
  const isActive = !!activeRow && activeRow.id === row.id

  return (
    <TreeNodeShell
      className='h-12'
      onClick={() => {
        row.toggleSelected()
        onSelect(row)
      }}
      focused={isActive}
      onPointerMove={() => (!isActive ? setActiveRow(row) : undefined)}
    >
      <div className='truncate flex-grow'>
        <div className='flex items-center px-1'>
          <Checkbox
            disabled={node.disabled || row.originalSubRows?.length === 0}
            checked={row.getIsSelected()}
            onChange={() => {
              row.toggleSelected()
              onSelect(row)
            }}
          />
          {children}
        </div>
        <PrettyPath node={node} />
      </div>
    </TreeNodeShell>
  )
}
