import {
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'
import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { MODAL_MOUNT_DURATION } from './utils'

import { type ModalType, usePushModalStack } from '../../providers'
import { type DataTestIdAttribute } from '../../types'
import { ShortcutScopeProvider } from '../shortcut'

export type UnstyledModalProps = DataTestIdAttribute & {
  type?: ModalType
  visible: boolean
  disableOverlayClick?: boolean
  disableEscapeKey?: boolean
  children: ReactNode
  onClose: () => void
  withAnimation?: boolean
  overlayClassName?: string
  modalClassName?: string
}

export const UnstyledModal = (props: UnstyledModalProps) => {
  const {
    type = 'inline',
    visible,
    disableOverlayClick = false,
    disableEscapeKey = false,
    onClose,
    children,
    withAnimation = false,
    overlayClassName,
    modalClassName,
    ...rest
  } = props

  const { nodeId, isPageModalStacked } = usePushModalStack(type, visible)

  const { context, refs } = useFloating({
    open: visible,
    onOpenChange: (open) => !open && onClose(),
    nodeId,
    transform: false,
  })
  const dismiss = useDismiss(context, {
    enabled: visible,
    escapeKey: !disableEscapeKey,
    outsidePress: (event) => {
      const otherElements = [
        // Radix toasts use this attribute
        '[data-radix-collection-item]',
        // Motion desktop deeplink popup
        '[data-motion-desktop-deeplink-popup]',
        // Custom dialogs (schedule modal)
        '#motion-task-input-iframe',
        // Grammarly browser extension
        '[data-grammarly-part]',
      ]

      return (
        !disableOverlayClick &&
        !otherElements.some((name) =>
          (event.target as Element | undefined)?.closest(name)
        )
      )
    },
    bubbles: false,
  })
  const role = useRole(context)
  const { getFloatingProps } = useInteractions([dismiss, role])

  const { isMounted, styles } = useTransitionStyles(context, {
    duration: MODAL_MOUNT_DURATION,
    initial: {
      opacity: 0,
      transform: 'scale(0.95)',
    },
    open: {
      opacity: 1,
      transform: 'scale(1)',
    },
  })
  const { styles: overlayStyles } = useTransitionStyles(context, {
    duration: MODAL_MOUNT_DURATION,
    initial: { opacity: 0 },
    open: { opacity: 1 },
  })

  if (!isMounted) {
    return null
  }

  const stackedStyles = isPageModalStacked
    ? {
        transform: 'scale(0.9) translate(0, -140px)',
      }
    : {}

  return (
    <FloatingNode id={nodeId}>
      <FloatingPortal>
        <FloatingOverlay
          className={twMerge('z-[1]', overlayClassName)}
          lockScroll
          style={withAnimation ? overlayStyles : undefined}
        />
        <FloatingFocusManager context={context}>
          <ShortcutScopeProvider scope={nodeId}>
            <div className='fixed z-[1] top-0 left-0 grid place-items-center w-screen h-screen'>
              <div
                ref={refs.setFloating}
                {...getFloatingProps()}
                {...rest}
                style={
                  withAnimation ? { ...styles, ...stackedStyles } : undefined
                }
                className={twMerge(
                  modalClassName,
                  // @ts-expect-error - Because the ExpandableModal passes a className
                  rest.className
                )}
              >
                {children}
              </div>
            </div>
          </ShortcutScopeProvider>
        </FloatingFocusManager>
      </FloatingPortal>
    </FloatingNode>
  )
}
