import { useClosure } from '@motion/react-core/hooks'

import { useEffect } from 'react'

import { bus } from '../../event-bus'
import { firebase, type User } from '../../firebase'

type UserChangedEventArgs = {
  user: User | null
}
type UserChangedEventHandler = (
  args: UserChangedEventArgs
) => void | Promise<void>

export const useOnFirebaseUserChanged = (fn: UserChangedEventHandler) => {
  const stable = useClosure(fn)
  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      stable({ user })
    })
  }, [stable])
}

export const useOnUserChanged = (fn: UserChangedEventHandler) => {
  const stable = useClosure(fn)
  useEffect(() => bus.on('auth:user-changed', stable), [stable])
}
