import {
  useSaveFirebaseSettings,
  useSettings,
} from '@motion/web-common/settings'

import { useCallback } from 'react'

import { type PersistedOnboardingState } from '../../types'

export const usePersistedOnboardingStateFirestore = () => {
  const { mutateAsync: saveToFirebase } = useSaveFirebaseSettings()

  const data: PersistedOnboardingState | undefined = useSettings([
    'isOnboardingComplete',
    'didSkipOnboarding',
    'onboardingProgress',
    'onboardingLatestScreen',
    'onboardingType',
    'onboardingVersion',
    'onboardingExtraData',
    'onboardingCompletedSurvey',
    'lastOnboardTimestamp',
  ])

  const update = useCallback(
    (data: Partial<PersistedOnboardingState>) => saveToFirebase(data),
    [saveToFirebase]
  )

  return [data, update] as const
}
