/* c8 ignore start */
import {
  type InternalStateKey,
  type SharedStateSerializerApi,
  SharedStateSerializerContext,
  type StateKey,
} from '@motion/react-core/shared-state'
import { cloneDeep } from '@motion/utils/core'
import { time } from '@motion/utils/debug'
import { Sentry } from '@motion/web-base/sentry'

import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { DB } from '../db'

type LocalStorageStateSerializerProps = {
  prefix: string
  children: ReactNode
  preload: StateKey<any>[]
  initialValues?: Record<string, any>
}

export const IndexedDbStateSerializer = (
  props: LocalStorageStateSerializerProps
) => {
  const initialValues = useRef<Record<string, any>>(props.initialValues ?? {})

  const getKey = useCallback(
    (key: StateKey<any>) => {
      return `${props.prefix}:${key.name}`
    },
    [props.prefix]
  )

  const api = useMemo((): SharedStateSerializerApi => {
    return {
      save<T>(key: InternalStateKey<T>, value: T) {
        /* c8 ignore next */
        if (!key.serialize) return
        const t = key.serialize(value)
        const storageKey = getKey(key)
        try {
          delete initialValues.current[storageKey]
          DB.state.set(storageKey, t).catch((ex) => {
            Sentry.captureException(ex, {
              extra: {
                operation: 'indexeddb.save',
              },
            })
          })

          // This is just so its easier to see the value while debugging

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - __IS_DEV__ is defined at the root
          if (typeof __IS_DEV__ !== 'undefined' && __IS_DEV__) {
            // @ts-expect-error - debugging only
            void DB.state.set(`${storageKey}:value`, value)
          }

          /* c8 ignore next 3 */
        } catch (ex) {
          Sentry.captureException(ex, {
            extra: {
              operation: 'indexeddb.save',
            },
          })
        }
      },
      load<T>(key: InternalStateKey<T>): T | undefined {
        /* c8 ignore next */
        if (!key.deserialize) return undefined

        const parse = (text: string) => {
          // /* c8 ignore next */
          if (text == null) return undefined
          try {
            const value = key.deserialize?.(text)
            return value ?? cloneDeep(key.__defaultValue)
          } catch (ex) {
            return cloneDeep(key.__defaultValue)
          }
        }

        const storageKey = getKey(key)
        if (initialValues.current[storageKey]) {
          const value = parse(initialValues.current[storageKey])
          delete initialValues.current[storageKey]
          return value
        }

        // maybe put in an event to notify store that value was retrieved

        return cloneDeep(key.__defaultValue)
      },
    }
  }, [getKey])

  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    async function initialLoad() {
      await DB.open()

      const values = await time('db.load', () =>
        DB.state.getMany(props.preload.map((key) => getKey(key)))
      )

      for (let i = 0; i < props.preload.length; i++) {
        const key = props.preload[i]
        const storageKey = getKey(key)
        // Fallback to local storage
        const value = values[storageKey] ?? localStorage.getItem(storageKey)
        localStorage.removeItem(storageKey)

        initialValues.current[getKey(key)] = value
      }

      return null
    }

    // eslint-disable-next-line promise/catch-or-return
    initialLoad()
      .catch((ex) => {
        Sentry.captureException(ex, {
          tags: {
            position: 'indexeddb.load',
          },
        })
      })
      .finally(() => setLoaded(true))

    // only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prefix])

  if (!loaded) return null

  return (
    <SharedStateSerializerContext.Provider value={api}>
      {props.children}
    </SharedStateSerializerContext.Provider>
  )
}
