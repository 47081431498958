export const SHORTCUT_DELIMITER = '+' as const
export const IGNORED_TAGS = ['INPUT', 'TEXTAREA']

const isMac = window.navigator.userAgent.includes('Mac')

export const keyDisplayMapping: Record<string, string> = {
  meta: '⌘',
  alt: isMac ? '⌥' : 'Alt',
  control: 'ctrl',
  escape: 'esc',
  enter: '⮐',
  arrowup: '↑',
  arrowdown: '↓',
}
