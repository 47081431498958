import { type EventConferenceTypeHumanReadable } from '@motion/rpc/types'
import { type EventConferenceType } from '@motion/shared/common'
import { type CalendarProviderTypeSchema } from '@motion/zod/client'

const zoomImageLink =
  'https://uploads-ssl.webflow.com/5e0ecd43df6433531a1dec71/5f08ef7a6b42722574b89117_image%2035.svg'
const meetImageLink =
  'https://uploads-ssl.webflow.com/5e0ecd43df6433531a1dec71/5ee914800ce4563a57723c81_meet_icon.png'
const teamsImageLink =
  'https://uploads-ssl.webflow.com/5e0ecd43df6433531a1dec71/5fae21d3d53152635d7d40e0_teams_icon.png'
const phoneImageLink =
  'https://uploads-ssl.webflow.com/5fcb349058268443b1f5dbdb/6049680838e0ed7b75a80c4f_Group%201117.png'
const customLocationImageLink =
  'https://uploads-ssl.webflow.com/5fcb349058268443b1f5dbdb/607fa042cc8e288ef585beb6_custom_link.png'

/**
 * List of event conference types that users can use when creating or updating events
 */
export const limitedEventConferenceTypes = [
  'none',
  'zoom',
  'hangoutsMeet',
  'teamsForBusiness',
  'phone',
  'customLocation',
] as const satisfies EventConferenceType[]
export type LimitedEventConferenceType =
  (typeof limitedEventConferenceTypes)[number]

/**
 * List of event conference types used on Mobile
 */
export const MobileEventConferenceTypes = [
  'none',
  'zoom',
  'hangoutsMeet',
  'teamsForBusiness',
  'phone',
  'customLocation',
] as const satisfies EventConferenceType[]

export type MobileEventConferenceType =
  (typeof MobileEventConferenceTypes)[number]

/**
 * Returns a list of Event conference types based on the specific provider type
 */
export function getConferenceTypesForProviderType(
  providerType: CalendarProviderTypeSchema
) {
  return limitedEventConferenceTypes.filter((type) => {
    if (type === 'hangoutsMeet') {
      return providerType === 'GOOGLE'
    }
    if (type === 'teamsForBusiness') {
      return providerType === 'MICROSOFT'
    }

    return true
  })
}

type ConferenceUIData = {
  title: string
  category?: 'meet' | 'zoom' | 'teams'
  link?: string
}

export function getConferenceDataForType<T extends EventConferenceType>(
  type: T
): ConferenceUIData {
  switch (type) {
    case 'zoom':
      return { title: 'Zoom', category: 'zoom', link: zoomImageLink }

    case 'skypeForConsumer':
    case 'skypeForBusiness':
    case 'teamsForBusiness':
      return {
        title: 'Microsoft Teams',
        category: 'teams',
        link: teamsImageLink,
      }

    case 'eventNamedHangout':
    case 'eventHangout':
      return { title: 'Google Hangouts', category: 'meet', link: meetImageLink }

    case 'hangoutsMeet':
    case 'meet':
      return { title: 'Google Meet', category: 'meet', link: meetImageLink }

    case 'phone':
      return { title: 'Default phone number', link: phoneImageLink }
    case 'customLocation':
      return { title: 'Default location', link: customLocationImageLink }
    case 'none':
      return { title: 'No conferencing' }
    default:
      return { title: 'Unknown' }
  }
}

type GetConferenceTypeFromLinkArgs = {
  conferenceLink: string | null | undefined
  readable?: boolean
}

/**
 * @deprecated use the `event.conferenceType` from the event, and `getConferenceDataForType`
 */
export function getConferenceTypeFromConferenceLink({
  conferenceLink,
  readable,
}: GetConferenceTypeFromLinkArgs & {
  readable: true
}): EventConferenceTypeHumanReadable
export function getConferenceTypeFromConferenceLink({
  conferenceLink,
  readable,
}: GetConferenceTypeFromLinkArgs & {
  readable?: false
}): EventConferenceType
export function getConferenceTypeFromConferenceLink({
  conferenceLink,
  readable,
}: GetConferenceTypeFromLinkArgs): unknown {
  if (!conferenceLink)
    return readable ? getConferenceDataForType('none').title : 'none'

  if (conferenceLink.includes('zoom.us')) {
    return readable ? getConferenceDataForType('zoom').title : 'zoom'
  } else if (conferenceLink.includes('meet.google.com')) {
    return readable
      ? getConferenceDataForType('hangoutsMeet').title
      : 'hangoutsMeet'
  } else if (conferenceLink.includes('teams.microsoft.com')) {
    return readable
      ? getConferenceDataForType('teamsForBusiness').title
      : 'teamsForBusiness'
  } else if (conferenceLink.includes('join.skype.com')) {
    return readable
      ? getConferenceDataForType('skypeForConsumer').title
      : 'skypeForConsumer'
  }

  return readable ? getConferenceDataForType('none').title : 'none'
}
