/* eslint-disable react-refresh/only-export-components */
import { useMemoDeep } from '@motion/react-core/hooks'

import { createContext, type ReactNode, useContext } from 'react'

export const AnalyticsContext = createContext<Record<string, unknown>>({})

export type AnalyticsMetadataProps = { children: ReactNode } & {
  data: Record<string, unknown>
}
export const AnalyticsMetadata = (props: AnalyticsMetadataProps) => {
  const parentData = useContext(AnalyticsContext)
  const value = useMemoDeep({ ...parentData, ...props.data })

  return (
    <AnalyticsContext.Provider value={value}>
      {props.children}
    </AnalyticsContext.Provider>
  )
}
