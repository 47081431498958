import { createKey, defineMutation } from '../../core'
import { type RouteTypes } from '../types'

type ParseCancellation = RouteTypes<'AiController_parseCancellation'>
export const parseCancellation = defineMutation<
  ParseCancellation['request'],
  { reason: string }
>().using({
  method: 'POST',
  uri: '/ai/cancellation',
  body: (args) => args,
})

type CreateView = RouteTypes<'AiController_createView'>
export const createView = defineMutation<
  CreateView['request'],
  CreateView['response']
>().using({
  method: 'POST',
  uri: '/ai/view',
  body: (args) => args,
  effects: [
    {
      on: 'success',
      action: 'invalidate',
      key: () => createKey(['v2', 'views']),
    },
  ],
})

type CreateFlows = RouteTypes<'AiController_createPwtArgs'>

export const createFlows = defineMutation<
  CreateFlows['request'],
  CreateFlows['response']
>().using({
  method: 'POST',
  uri: '/ai/pwt',
})
