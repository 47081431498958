import { type SvgIcon } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'

import { type ReactNode } from 'react'

import { TreeviewItemShell } from './treeview-item'

import { ProjectColoredIcon } from '../../project'

type TreeviewItemProps = {
  color: COLOR
  label: ReactNode
  icon?: SvgIcon | null
  selected?: boolean
  highlighted?: boolean
  isButton?: boolean
}

export const ProjectTreeviewItem = ({
  color,
  label,
  icon,
  selected,
  highlighted,
  isButton,
}: TreeviewItemProps) => {
  const Icon = icon
  return (
    <TreeviewItemShell
      active={selected}
      highlighted={highlighted}
      isButton={isButton}
    >
      {Icon ? (
        <div className='pl-1 pr-1.5 relative'>
          <Icon className='w-4' />
        </div>
      ) : (
        <div className='pl-1 relative'>
          <ProjectColoredIcon color={color} height={16} width={16} />
        </div>
      )}

      <div className='truncate w-full relative pl-1.5'>{label}</div>
    </TreeviewItemShell>
  )
}
