import { useOnceWhen } from '@motion/react-core/hooks'
import { API, createUseMutation, createUseQuery } from '@motion/rpc'
import {
  createQueryFilter,
  MotionCache,
  type OptimisticUpdateValue,
} from '@motion/rpc-cache'
import {
  type ClientFirebaseSettingsDto,
  type GlobalUserTaskDefaultSettingsSchema,
} from '@motion/rpc-types'
import { Sentry } from '@motion/web-base/sentry'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { useCurrentUserOrNull } from '../auth/hooks'
import { firebase } from '../firebase'

export const useSetting = <TKey extends keyof ClientFirebaseSettingsDto>(
  name: TKey,
  defaultValue: ClientFirebaseSettingsDto[TKey]
): [
  ClientFirebaseSettingsDto[TKey],
  (val: ClientFirebaseSettingsDto[TKey]) => void,
] => {
  const { mutate } = useSaveFirebaseSettings()
  const { data } = useFirestoreSettings(undefined, {
    // @ts-expect-error - return type of createUseQuery doesn't support 'select' properly
    select(data) {
      return data[name]
    },
  })

  // @ts-expect-error - return type of createUseQuery doesn't support 'select' properly
  const value: ClientFirebaseSettingsDto[TKey] = data ?? defaultValue

  const setValue = useCallback(
    (val: ClientFirebaseSettingsDto[TKey]) =>
      mutate({
        [name]: val,
      }),
    [mutate, name]
  )

  return [value, setValue]
}

type SettingsSubset<TKeys extends keyof ClientFirebaseSettingsDto> = {
  [TKey in TKeys]: ClientFirebaseSettingsDto[TKey]
}

export const useSettings = <TKeys extends (keyof ClientFirebaseSettingsDto)[]>(
  keys: TKeys
): SettingsSubset<TKeys[number]> | undefined => {
  const query = useFirestoreSettings(undefined, {
    // @ts-expect-error - return type of createUseQuery doesn't support 'select' properly
    select(data) {
      return keys.reduce((acc, key) => {
        if (!(key in data)) return acc
        // @ts-expect-error - typed externally
        acc[key] = data[key]
        return acc
      }, {})
    },
  })

  return query.data as unknown as SettingsSubset<TKeys[number]> | undefined
}

export const useFirestoreSettings = createUseQuery(
  API.userSettings.getFirestoreSettings,
  {
    cacheTime: Infinity,
    notifyOnChangeProps: ['data', 'dataUpdatedAt', 'error'],
  }
)

export const SettingsQueryKey = ['settings', 'firestore']

type UpdateSettings = Partial<ClientFirebaseSettingsDto>

export const useSaveFirebaseSettings = () => {
  const client = useQueryClient()
  const user = useCurrentUserOrNull()

  return useMutation({
    async mutationFn(data: UpdateSettings) {
      if (user == null) {
        throw new Error('User is not authenticated')
      }

      await firebase
        .firestore()
        .collection<ClientFirebaseSettingsDto>('settings')
        .doc(user.uid)
        .set(data, { merge: true })
    },
    onMutate(data) {
      const existing =
        client.getQueryData<ClientFirebaseSettingsDto>(SettingsQueryKey)
      const merged = {
        ...existing,
        ...data,
      } as ClientFirebaseSettingsDto

      client.setQueryData(SettingsQueryKey, merged)

      return {
        prev: existing,
        merged,
      }
    },
    onError() {
      client.invalidateQueries({
        queryKey: SettingsQueryKey,
      })
    },
  })
}

export const useMyUserSettingsQuery = createUseQuery(API.usersV2.getMySettings)
export const useMyUserSettings = (
  args?: undefined,
  opts?: Parameters<typeof useMyUserSettingsQuery>[1]
) => {
  const user = useCurrentUserOrNull()
  const res = useMyUserSettingsQuery(args, opts)
  const { data } = res

  useOnceWhen(
    data != null && (data.id == null || data.models?.userSettings == null),
    () =>
      Sentry.captureException('User settings should not be undefined', {
        extra: {
          id: data?.id ?? 'undefined',
          userSettings: data?.models?.userSettings ?? 'undefined',
          userId: user == null ? '[null]' : user.uid,
        },
      })
  )

  return data == null
    ? res
    : { ...res, data: data.models?.userSettings?.[res.data?.id] ?? {} }
}

/**
 * Unlike other models, the backend sets the id on the user settings object to be the user id.
 * So best to use this hook to get the id from the response.
 */
export const useMyUserSettingsId = (): string | undefined => {
  const { data } = useMyUserSettingsQuery(undefined, {
    meta: { source: 'useMyUserSettingsId' },
  })
  return data?.id
}

export const useSavePostgresOnboardingSettings = createUseMutation(
  API.usersV2.updatePostgresOnboardingSettings
)

export const useSavePostgresConferenceSettings = createUseMutation(
  API.usersV2.updatePostgresConferenceSettings
)

const useUpdateFolderSettingsMutation = createUseMutation(
  API.usersV2.updateFolderSettings
)

export const useUpdateFolderSettings = () => {
  const queryClient = useQueryClient()

  return useUpdateFolderSettingsMutation({
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: API.usersV2.queryKeys.mySettings(),
      })
    },
  })
}

export const userSettingsQueryFilter = createQueryFilter(
  API.usersV2.settingsQueryKeysToUpdate
)

const useSaveTaskDefaultSettingsMutation = createUseMutation(
  API.usersV2.updateTaskDefaultSettings
)
export const useSaveTaskDefaultSettings = () => {
  const client = useQueryClient()
  const { data: mySettings } = useMyUserSettingsQuery(undefined, {
    meta: { source: 'useSaveTaskDefaultSettings' },
  })
  const userSettingsId = mySettings?.id

  return useSaveTaskDefaultSettingsMutation({
    onMutate: ({ data }) => {
      if (!userSettingsId) return

      const { level, ...rest } = data
      // Not supporting yet
      if (level === 'WORKSPACE') {
        return
      }

      const cacheUpdates = MotionCache.patch(
        client,
        userSettingsQueryFilter,
        'userSettings',
        {
          [userSettingsId]: {
            taskDefaultSettings: {
              global: {
                ...rest,
                level,
                // TODO: Fix the patch typing
              } as GlobalUserTaskDefaultSettingsSchema,
            },
          },
        }
      )
      return { cacheUpdates }
    },
    onSuccess: (data) => {
      MotionCache.upsert(client, userSettingsQueryFilter, data)
    },
    onError: (err, _, context = {}) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }

      cacheUpdates?.rollback()
    },
  })
}

const useSaveCalendarDisplaySettingsMutation = createUseMutation(
  API.usersV2.updateCalendarDisplaySettings
)
export const useSaveCalendarDisplaySettings = () => {
  const client = useQueryClient()
  const { data: mySettings } = useMyUserSettingsQuery(undefined, {
    meta: { source: 'useSaveCalendarDisplaySettings' },
  })
  const userSettingsId = mySettings?.id

  return useSaveCalendarDisplaySettingsMutation({
    onMutate: (calendarDisplaySettings) => {
      if (!userSettingsId) return

      const cacheUpdates = MotionCache.patch(
        client,
        userSettingsQueryFilter,
        'userSettings',
        {
          [userSettingsId]: { calendarDisplaySettings },
        }
      )

      return { cacheUpdates }
    },
    onSuccess: (data) => {
      MotionCache.upsert(client, userSettingsQueryFilter, data)
    },
    onError: (err, _, context = {}) => {
      const { cacheUpdates } = context as {
        cacheUpdates: OptimisticUpdateValue | undefined
      }

      cacheUpdates?.rollback()
    },
  })
}
