import { classed } from '@motion/theme'

import { forwardRef } from 'react'

import { UnstyledTooltip, type UnstyledTooltipProps } from './unstyled-tooltip'

export type TooltipProps = Omit<UnstyledTooltipProps, 'renderContent'> & {
  noPadding?: boolean
  renderContent?: UnstyledTooltipProps['renderContent'] | false
  content?: string | false | null
}

const suppressPropagation = (e: React.BaseSyntheticEvent) => {
  e.stopPropagation()
}

export const Tooltip = forwardRef<HTMLElement, TooltipProps>(function Tooltip(
  { renderContent, content, noPadding, ...props },
  ref
) {
  return (
    <UnstyledTooltip
      ref={ref}
      renderContent={
        !!renderContent || !!content
          ? () => (
              <Content noPadding={noPadding} onClick={suppressPropagation}>
                {renderContent ? renderContent() : content}
              </Content>
            )
          : undefined
      }
      {...props}
    />
  )
})

const Content = classed('div', {
  base: `
    cursor-default
    bg-tooltip-bg
    text-tooltip-text
    overflow-hidden rounded-lg
    font-sans text-sm text-center
    shadow-lg
    max-w-[295px]
    empty:hidden
  `,
  variants: {
    noPadding: {
      false: 'px-2 py-1.5',
    },
  },
  defaultVariants: {
    noPadding: false,
  },
})
