import { type COLOR } from '@motion/shared/common'

import { forwardRef, type ReactNode } from 'react'

import { StyledColorTag } from './styled'

import { getColorHueValue, getColorName, PaletteProvider } from '../../palette'

export type ColorTagProps = {
  color: COLOR
  size?: 'small' | 'normal' | 'large'
  icon?: ReactNode
  square?: boolean
  onClick?: () => void
}

export const ColorTag = forwardRef<
  HTMLDivElement | HTMLButtonElement,
  ColorTagProps
>(function ColorTag(
  { color, size = 'normal', icon, square, onClick }: ColorTagProps,
  ref
) {
  const colorHue = getColorHueValue(color)
  const colorName = getColorName(color)

  const clickable = onClick != null

  return (
    <PaletteProvider colorHue={colorHue}>
      <StyledColorTag
        ref={ref as any}
        as={clickable ? 'button' : 'div'}
        size={size}
        color={color}
        square={square}
        iconOnly={icon != null}
        clickable={clickable}
        onClick={onClick}
      >
        {icon ?? colorName}
      </StyledColorTag>
    </PaletteProvider>
  )
})
