import type { AdjustmentResults } from '@motion/shared/flows'

import { DateTime } from 'luxon'

import type { StageArg } from '../../form-fields'

export function mapStageAdjusterOutputToStageArgs(
  adjustedStages: AdjustmentResults,
  stageDueDates: StageArg[]
): StageArg[] {
  return adjustedStages.stages.map((stage, i) => ({
    stageDefinitionId: stage.stageDefinitionId,
    dueDate: DateTime.fromJSDate(stage.dueDate).toISODate(),
    skipped: stageDueDates[i].skipped,
  }))
}
