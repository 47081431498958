import { lazy } from '@motion/utils/promise'

import { initialize } from './initialize'
import { log } from './log'

import { type MotionDB } from '../types'

function createProxy(): MotionDB {
  const db = lazy(() => initialize())

  return new Proxy(
    {},
    {
      get(target, prop, receiver) {
        log.debug('get', prop, { resolved: db.resolved })
        if (db.resolved) {
          const value = Reflect.get(db.value, prop, receiver)
          if (prop === 'close') {
            return () => db.value.close().finally(() => db.reset())
          }

          return value
        }

        if (prop === 'initialized') {
          return false
        }

        if (prop === 'provider') {
          return 'unknown'
        }

        if (prop === 'clearAll') {
          return () => Promise.resolve()
        }

        if (prop === 'open') {
          return () => db.get().then((x) => x.open())
        }

        throw new Error('MotionDB is not initialized')
      },
    }
  ) as MotionDB
}

export const DB: MotionDB = createProxy()
