import { createChangeLog } from './changelog'

export const onlineChanges = createChangeLog<boolean>(
  'online',
  window.navigator.onLine
)

window.addEventListener('online', (e) =>
  onlineChanges.record(true, e.timeStamp)
)

window.addEventListener('offline', (e) =>
  onlineChanges.record(false, e.timeStamp)
)
