import { createContext } from 'react'

import {
  type MotionDesignTokenNames,
  type MotionTheme,
  type TailwindColorToken,
} from './types'

export type ThemeContextApi = {
  theme: MotionTheme
  resolve(token: MotionDesignTokenNames): string
  resolveTailwindColor(token: TailwindColorToken): string
  cssVar(token: MotionDesignTokenNames): string
}

const throwNoContext = () => {
  throw new Error('ThemeContext was not found.')
}

export const ThemeContext = createContext<ThemeContextApi>({
  theme: 'light',
  resolve: throwNoContext,
  resolveTailwindColor: throwNoContext,
  cssVar: throwNoContext,
})
