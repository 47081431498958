/**
 * Auto Generated
 */
import { type ClientZod } from '../../../utils/client-transform'
import type * as Server from '../../../server/v2/charts/dtos'

// Generated Types

export type GroupableIdFieldSchema = ClientZod<
  typeof Server.GroupableIdFieldSchema
>
export const GroupableIdFieldSchema = [
  'workspaceId',
  'projectId',
  'assigneeUserId',
  'statusId',
  'scheduledStatus',
] as const
export type GroupableBasicFieldSchema = ClientZod<
  typeof Server.GroupableBasicFieldSchema
>
export type GroupableDateFieldNameSchema = ClientZod<
  typeof Server.GroupableDateFieldNameSchema
>
export const GroupableDateFieldNameSchema = [
  'createdTime',
  'dueDate',
  'completedTime',
  'scheduledStart',
  'scheduledEnd',
  'startDate',
  'endDate',
  'estimatedCompletionTime',
] as const
export type GroupableFieldNameSchema = ClientZod<
  typeof Server.GroupableFieldNameSchema
>
export type GroupableDateFieldBucketSchema = ClientZod<
  typeof Server.GroupableDateFieldBucketSchema
>
export const GroupableDateFieldBucketSchema = [
  'day',
  'week',
  'month',
  'quarter',
  'half',
  'year',
] as const
export type GroupableDateSchema = ClientZod<typeof Server.GroupableDateSchema>
export type GroupableFieldSchema = ClientZod<typeof Server.GroupableFieldSchema>
export type ChartQueryAggregateSumFieldSchema = ClientZod<
  typeof Server.ChartQueryAggregateSumFieldSchema
>
export const ChartQueryAggregateSumFieldSchema = ['duration'] as const
export type ChartQueryAggregateCountSchema = ClientZod<
  typeof Server.ChartQueryAggregateCountSchema
>
export type ChartQueryAggregateSumSchema = ClientZod<
  typeof Server.ChartQueryAggregateSumSchema
>
export type ChartQueryAggregateSchema = ClientZod<
  typeof Server.ChartQueryAggregateSchema
>
export type ChartQueryRequestSchema = ClientZod<
  typeof Server.ChartQueryRequestSchema
>
export type CategoryValue = ClientZod<typeof Server.CategoryValue>
export type CategoryValuePointSchema = ClientZod<
  typeof Server.CategoryValuePointSchema
>
export type ChartQueryResponseSchema = ClientZod<
  typeof Server.ChartQueryResponseSchema
>
