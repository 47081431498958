import { type PMTaskStatusType } from '@motion/rpc/types'

export function getStatusColorClass(status?: Pick<PMTaskStatusType, 'color'>) {
  if (!status) return

  return (
    colorToNewColorClass[status.color] ??
    legacyColorToNewColorClass[status.color]
  )
}

function getStatusBorderColorClass(status?: Pick<PMTaskStatusType, 'color'>) {
  if (!status) return

  return (
    borderColorToNewColorClass[status.color] ??
    legacyBorderColorToNewColorClass[status.color]
  )
}

function getStatusActiveBackgroundColorClass(
  status?: Pick<PMTaskStatusType, 'color'>
) {
  if (!status) return

  return (
    bgColorToActiveBgClass[status.color] ??
    legacyBgColorToActiveBgClass[status.color]
  )
}

function getStatusBackgroundColorClass(
  status?: Pick<PMTaskStatusType, 'color'>
) {
  if (!status) return

  return (
    bgColorToNewColorClass[status.color] ??
    legacyBgColorToNewColorClass[status.color]
  )
}

export function getStatusColorClasses(
  status?: Pick<PMTaskStatusType, 'color'>
) {
  return {
    textColor: getStatusColorClass(status),
    bgColor: getStatusBackgroundColorClass(status),
    borderColor: getStatusBorderColorClass(status),
    activeColor: getStatusActiveBackgroundColorClass(status),
  }
}

// ⚠️ NOTE: These colors need to be fully mapped for tailwind compiler to pick them up and add them to our stylesheets
const legacyColorToNewColorClass: Record<string, string> = {
  // PMv1 colors
  '#A1A1A1': '!text-semantic-neutral-icon-default',
  '#F44E58': '!text-semantic-error-icon-default',
  '#FABD61': '!text-semantic-warning-icon-default',
  '#1DC5CF': '!text-semantic-teal-icon-default',
  '#47C96B': '!text-semantic-success-icon-default',
  '#7C23B3': '!text-semantic-purple-icon-default',
  '#F2762F': '!text-semantic-orange-icon-default',
  '#D01DA9': '!text-semantic-pink-icon-default',
  '#FFFFFF': '!text-primitives-global-white',
}

const colorToNewColorClass: Record<string, string> = {
  // PMv2 colors
  '#889096': '!text-semantic-neutral-icon-default',
  '#c1c8cd': '!text-semantic-neutral-icon-subtle',
  '#889097': '!text-semantic-neutral-icon-strong',
  '#30A66D': '!text-semantic-success-icon-default',
  '#FFB224': '!text-semantic-warning-icon-default',
  '#E5484D': '!text-semantic-error-icon-default',
  '#EA3E83': '!text-semantic-pink-icon-default',
  '#05A2C2': '!text-semantic-cyan-icon-default',
  '#12A594': '!text-semantic-teal-icon-default',
  '#F76808': '!text-semantic-orange-icon-default',
  '#0091FF': '!text-semantic-blue-icon-default',
  '#8E4EC6': '!text-semantic-purple-icon-default',
}

const bgColorToNewColorClass: Record<string, string> = {
  // PMv2 colors
  '#889096': '!bg-semantic-neutral-icon-default',
  '#30A66D': '!bg-semantic-success-icon-default',
  '#FFB224': '!bg-semantic-warning-icon-default',
  '#E5484D': '!bg-semantic-error-icon-default',
  '#EA3E83': '!bg-semantic-pink-icon-default',
  '#05A2C2': '!bg-semantic-cyan-icon-default',
  '#12A594': '!bg-semantic-teal-icon-default',
  '#F76808': '!bg-semantic-orange-icon-default',
  '#0091FF': '!bg-semantic-blue-icon-default',
  '#8E4EC6': '!bg-semantic-purple-icon-default',
}

const legacyBgColorToNewColorClass: Record<string, string> = {
  // PMv1 colors
  '#A1A1A1': '!bg-semantic-neutral-icon-default',
  '#F44E58': '!bg-semantic-error-icon-default',
  '#FABD61': '!bg-semantic-warning-icon-default',
  '#1DC5CF': '!bg-semantic-teal-icon-default',
  '#47C96B': '!bg-semantic-success-icon-default',
  '#7C23B3': '!bg-semantic-purple-icon-default',
  '#F2762F': '!bg-semantic-orange-icon-default',
  '#D01DA9': '!bg-semantic-pink-icon-default',
  '#FFFFFF': '!bg-primitives-global-white',
}

const borderColorToNewColorClass: Record<string, string> = {
  // PMv2 colors
  '#889096': '!border-semantic-neutral-icon-default',
  '#30A66D': '!border-semantic-success-icon-default',
  '#FFB224': '!border-semantic-warning-icon-default',
  '#E5484D': '!border-semantic-error-icon-default',
  '#EA3E83': '!border-semantic-pink-icon-default',
  '#05A2C2': '!border-semantic-cyan-icon-default',
  '#12A594': '!border-semantic-teal-icon-default',
  '#F76808': '!border-semantic-orange-icon-default',
  '#0091FF': '!border-semantic-blue-icon-default',
  '#8E4EC6': '!border-semantic-purple-icon-default',
}

const legacyBorderColorToNewColorClass: Record<string, string> = {
  // PMv1 colors
  '#A1A1A1': '!border-semantic-neutral-icon-default',
  '#F44E58': '!border-semantic-error-icon-default',
  '#FABD61': '!border-semantic-warning-icon-default',
  '#1DC5CF': '!border-semantic-teal-icon-default',
  '#47C96B': '!border-semantic-success-icon-default',
  '#7C23B3': '!border-semantic-purple-icon-default',
  '#F2762F': '!border-semantic-orange-icon-default',
  '#D01DA9': '!border-semantic-pink-icon-default',
  '#FFFFFF': '!border-primitives-global-white',
}

const bgColorToActiveBgClass: Record<string, string> = {
  // PMv2 colors
  '#889096': '!bg-semantic-neutral-bg-active-default',
  '#30A66D': '!bg-semantic-success-bg-active-default',
  '#FFB224': '!bg-semantic-warning-bg-active-default',
  '#E5484D': '!bg-semantic-error-bg-active-default',
  '#EA3E83': '!bg-semantic-pink-bg-active-default',
  '#05A2C2': '!bg-semantic-cyan-bg-active-default',
  '#12A594': '!bg-semantic-teal-bg-active-default',
  '#F76808': '!bg-semantic-orange-bg-active-default',
  '#0091FF': '!bg-semantic-blue-bg-active-default',
  '#8E4EC6': '!bg-semantic-purple-bg-active-default',
}

const legacyBgColorToActiveBgClass: Record<string, string> = {
  // PMv1 colors
  '#A1A1A1': '!bg-semantic-neutral-bg-active-default',
  '#F44E58': '!bg-semantic-error-bg-active-default',
  '#FABD61': '!bg-semantic-warning-bg-active-default',
  '#1DC5CF': '!bg-semantic-teal-bg-active-default',
  '#47C96B': '!bg-semantic-success-bg-active-default',
  '#7C23B3': '!bg-semantic-purple-bg-active-default',
  '#F2762F': '!bg-semantic-orange-bg-active-default',
  '#D01DA9': '!bg-semantic-pink-bg-active-default',
  '#FFFFFF': '!bg-primitives-global-white',
}

export const statusColorOptions = Object.keys(colorToNewColorClass)
