import { type DateLike, parseDate } from '@motion/utils/dates'

export const SHOW_TIME_ABOVE_DURATION_MINUTES = 30
/**
 * Computes duration in minutes for a given start and end date.
 * @returns
 */
export function getDurationfromDates(
  start: DateLike | null,
  end: DateLike | null
) {
  return end && start
    ? Math.floor(
        (parseDate(end).toMillis() - parseDate(start).toMillis()) / 1000 / 60
      )
    : 0
}
