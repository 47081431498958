import { parseKeybinding } from 'tinykeys'

import { IGNORED_TAGS, keyDisplayMapping } from './constants'

export const convertShortcutKeyToTinyKeys = (shortcutKeys: string) => {
  return shortcutKeys.replace(/mod/i, '$mod')
}

export function isFocusedInput() {
  const target = document.activeElement

  if (target == null || target.tagName == null) {
    return false
  }

  return (
    IGNORED_TAGS.includes(target.tagName) ||
    target.hasAttribute('contenteditable')
  )
}

export function getShortcutDisplaySequence(shortcut: string) {
  const parsedShortcut = parseKeybinding(convertShortcutKeyToTinyKeys(shortcut))
  const shortcutKeys = parsedShortcut.flat().flat()

  return shortcutKeys.map((k) => {
    if (k instanceof RegExp) return ''

    return (
      keyDisplayMapping[k.toLowerCase()] ??
      k.replace(/key|digit|numpad|arrow/i, '')
    )
  })
}
