import { classed } from '@motion/theme'

export const BannerContainer = classed('div', {
  base: 'flex items-center px-4 py-2 gap-3 text-sm',
  variants: {
    sentiment: {
      default: `
        bg-banner-default-bg
        text-banner-default-text

        [&_[data-icon="x"]]:text-banner-default-icon
      `,
      warning: '',
      error: '',
    },
    variant: {
      default: 'electron-drag',
      onPage: 'rounded border electron-no-drag',
    },
    size: {
      small: 'text-xs leading-5',
      normal: '',
    },
  },
  compoundVariants: [
    {
      sentiment: 'default',
      variant: 'onPage',
      className: `
        border-banner-default-border
      `,
    },
    {
      sentiment: 'warning',
      variant: 'default',
      className: `
        bg-banner-warning-strong-bg
        text-banner-warning-strong-text

        [&_[data-icon="x"]]:text-banner-warning-strong-icon
      `,
    },
    {
      sentiment: 'warning',
      variant: 'onPage',
      className: `
        bg-banner-warning-subtle-bg
        border-banner-warning-subtle-border
        text-banner-warning-subtle-text

        [&_[data-icon="x"]]:text-banner-warning-subtle-icon
      `,
    },
    {
      sentiment: 'error',
      variant: 'default',
      className: `
        bg-banner-error-strong-bg
        text-banner-error-strong-text

        [&_[data-icon="x"]]:text-banner-error-strong-icon
      `,
    },
    {
      sentiment: 'error',
      variant: 'onPage',
      className: `
        bg-banner-error-subtle-bg
        border-banner-error-subtle-border
        text-banner-error-subtle-text

        [&_[data-icon="x"]]:text-banner-error-subtle-icon
      `,
    },
  ],
  defaultVariants: {
    sentiment: 'default',
    variant: 'default',
    size: 'normal',
  },
})
