import { ChevronLeftSolid, ChevronRightSolid } from '@motion/icons'
import { useDelayedUnmount } from '@motion/react-core/hooks'
import { classed } from '@motion/theme'
import { addComponentName } from '@motion/ui/helpers'

import { Resizable } from 're-resizable'
import { useState } from 'react'

export type SidebarProps = {
  resizeDirection?: 'left' | 'right'
  hideCloseButton?: boolean
  initialWidth?: number
  minWidth?: number
  maxWidth?: number
  open: boolean
  onOpenChange?: (open: boolean) => void
  onResizeStop?: (width: number) => void
  children?: React.ReactNode
  closedWidth?: number
}

export const Sidebar = (props: SidebarProps) => {
  const mounted = useDelayedUnmount(props.open, 150)
  const [resizing, setResizing] = useState(false)
  const [width, setWidth] = useState(props.initialWidth ?? 240)
  const { resizeDirection = 'right', closedWidth = 12 } = props
  const minWidth = props.minWidth ?? 220
  const maxWidth = props.maxWidth ?? 440

  const margins =
    resizeDirection === 'left'
      ? { marginRight: props.open ? 0 : width * -1 + closedWidth }
      : { marginLeft: props.open ? 0 : width * -1 + closedWidth }

  return (
    <div className='relative min-h-0' {...addComponentName('Sidebar')}>
      <Shell
        enable={
          props.open
            ? {
                ...ENABLED_PROPS,
                right: resizeDirection === 'right' ? true : false,
                left: resizeDirection === 'left' ? true : false,
              }
            : false
        }
        onResize={(e, direction, ref) => {
          if (props.open) {
            setWidth(ref.offsetWidth)
            if (
              'clientX' in e &&
              resizeDirection === 'right' &&
              e.clientX < 100
            ) {
              props.onOpenChange?.(false)
            }
            if (
              'clientX' in e &&
              resizeDirection === 'left' &&
              e.clientX > window.innerWidth - minWidth / 2
            ) {
              props.onOpenChange?.(false)
            }

            return
          }

          if (
            'clientX' in e &&
            resizeDirection === 'left' &&
            e.clientX < window.innerWidth - minWidth / 2
          ) {
            props.onOpenChange?.(true)
          }
          if (
            'clientX' in e &&
            resizeDirection === 'right' &&
            e.clientX > 100
          ) {
            props.onOpenChange?.(true)
          }
        }}
        onResizeStart={() => {
          setResizing(true)
        }}
        onResizeStop={(e, direction, ref) => {
          setResizing(false)
          if (props.open) {
            props.onResizeStop?.(ref.offsetWidth)
          }
        }}
        handleComponent={{
          left:
            resizeDirection === 'left' ? (
              <Handle active={resizing} />
            ) : undefined,
          right:
            resizeDirection === 'right' ? (
              <Handle active={resizing} />
            ) : undefined,
        }}
        handleStyles={{
          right: { display: 'flex', justifyContent: 'center' },
          left: { display: 'flex', justifyContent: 'center' },
        }}
        handleClasses={{
          right: 'group',
          left: 'group',
        }}
        // add 12 so that the sidebar doesn't fully collapse to maintain the margin because in the new ui the main page card is not flush to the left
        style={margins}
        minWidth={minWidth}
        maxWidth={maxWidth}
        maxHeight='100%'
        defaultSize={{
          width: width,
          height: '100%',
        }}
      >
        {mounted && props.children}
      </Shell>
      {resizeDirection === 'left' && props.open && !props.hideCloseButton && (
        <LeftCloseButton onClick={() => props.onOpenChange?.(false)}>
          <ChevronRightSolid className='w-5 h-5' />
        </LeftCloseButton>
      )}
      {resizeDirection === 'right' && props.open && !props.hideCloseButton && (
        <RightCloseButton onClick={() => props.onOpenChange?.(false)}>
          <ChevronLeftSolid className='w-5 h-5' />
        </RightCloseButton>
      )}
    </div>
  )
}

const Handle = classed('div', {
  base: `
    w-0.5 h-full mt-1 bg-primitives-brand-700 z-0 opacity-0
    group-hover:opacity-100 transition-opacity
  `,
  variants: {
    active: {
      true: 'opacity-100',
    },
  },
})

const Shell = classed(Resizable, {
  base: `
    relative
    w-full transition-[margin]
    flex flex-col
    bg-semantic-neutral-bg-disabled
  `,
})

const RightCloseButton = classed('button', {
  base: `
    flex items-center justify-center
    w-6 h-6 bg-red-300 absolute right-0 rounded-full top-3 translate-x-1/2
    border border-semantic-neutral-border-strong bg-sidebar-bg
    text-semantic-neutral-icon-subtle hover:text-semantic-neutral-icon-default
    z-[1]
  `,
})

const LeftCloseButton = classed(RightCloseButton, {
  base: `
   left-0 -translate-x-1/2
  `,
})

const ENABLED_PROPS = {
  top: false,
  right: true,
  bottom: false,
  left: true,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
}
