/**
 * Title case a string
 * @param str
 * @returns {string} The title cased string
 */
export function titleCase(str: string): string {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
}

/**
 * Capitalize a string
 * @param str
 * @returns {string} The capitalized string
 */
export function capitalize<T extends string>(str: T): Capitalize<T> {
  return (str.charAt(0).toUpperCase() +
    str.slice(1).toLowerCase()) as Capitalize<T>
}

export function getNameInitials(name: string = ''): string {
  if (!name) return ''
  let initials = ''
  const nameParts = name.split(' ')
  for (let i = 0; i < Math.min(2, nameParts.length); i++) {
    initials += nameParts[i].charAt(0)
  }
  return initials
}

// Returns the first "character" of a string, including combined emojis
export const getFirstSymbol = (name: string) =>
  name.trim().match(/^([aA-zZ]|[\d]|[\p{Emoji}\u200d]+)/u)?.[0] ?? ''
