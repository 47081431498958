import { entries, keys, values } from '@motion/utils/object'
import { type AllModelsSchema } from '@motion/zod/client'

import { type QueryFilters, type QueryKey } from '@tanstack/react-query'

import { DELETE_MODEL } from './constants'
import { applyPartialToTargetStore } from './shared'
import {
  type ApplyModelReturn,
  type ModelStore,
  type QueryCacheMatches,
  type QueryCacheUpsert,
} from './types'
import {
  getResponseModelType,
  matchQueries,
  shouldAppendNew,
  shouldRemoveOldEntity,
  updateQueryData,
} from './utils'

import { updateIndexForModel } from '../indexes/index-manager'
import { isModelCacheKey, type Model, type ModelId } from '../model-cache'
import { type MotionCacheContext, type V2ResponseStoreShape } from '../types'
import { log } from '../utils'

/**
 * Upsert the cache with a **full** model response.
 * Appends new items by default
 *
 * @param ctx - The MotionCacheContext with the client and userId.
 * @param filter - The QueryFilters to determine which queries to update.
 * @param response - The full response data to upsert into the cache.
 * @param appendNewFilter - Optional function to determine whether to append new data.
 *
 * @returns An array of QueryCacheUpsert objects representing the changes made.
 */
export function updateQueryCaches(
  ctx: MotionCacheContext,
  filter: QueryFilters,
  response: V2ResponseStoreShape
): QueryCacheUpsert<keyof AllModelsSchema>[] {
  try {
    const freshQueries = matchQueries(ctx, { ...filter, stale: false })
    const staleQueries = matchQueries(ctx, { ...filter, stale: true })

    const queries = [...freshQueries, ...staleQueries]

    const cacheUpdates: QueryCacheUpsert<keyof AllModelsSchema>[] = []

    log.time('update.all', () => {
      queries.forEach(([key, cacheStore]) => {
        // Not all queries have an operable cache store
        // can be any arbitrary shape, but only do updates on V2ResponseStoreShape
        if (cacheStore == null || cacheStore.models == null) return

        log.time(`update`, (): void => {
          const updates = processQueryCacheUpsert(
            ctx,
            cacheStore,
            response,
            key
          )
          cacheUpdates.push(...updates)
        })
      })
    })

    // Handles optimistic updates
    updateQueryData(ctx, cacheUpdates)

    // Using queryClient.setQueryData marks the query as no longer stale.
    // To ensure updated queries are correctly refetched when needed,
    // we manually invalidate the stale queries that were updated
    staleQueries.forEach(([key]) => {
      ctx.client.invalidateQueries(key)
    })

    return cacheUpdates
  } catch (error) {
    log.error('Error updating query caches:', error)
    return []
  }
}

function processQueryCacheUpsert(
  ctx: MotionCacheContext,
  cacheStore: QueryCacheMatches,
  response: V2ResponseStoreShape,
  key: QueryKey
): QueryCacheUpsert<keyof AllModelsSchema>[] {
  const cacheUpdates: QueryCacheUpsert<keyof AllModelsSchema>[] = []

  const types = keys(response.models)
  types.forEach((type) => {
    const source = response.models[type]
    if (source == null) return

    const updates = applyMatching(ctx, cacheStore, type, source, key)

    cacheUpdates.push(...updates)

    values(source).forEach((model) => {
      updateIndexForModel(ctx.client, type, model, cacheUpdates)
    })
  })

  return cacheUpdates
}

/**
 * Apply the source models to the target store.
 *
 * Either an upsert or an update depending on `appendNew` and if the model already exists.
 */
function applyMatching<TType extends keyof AllModelsSchema>(
  ctx: MotionCacheContext,
  cacheStore: QueryCacheMatches,
  type: TType,
  source: Record<ModelId, Partial<Model<TType>>>,
  key: QueryKey
): QueryCacheUpsert<TType>[] {
  const cacheUpdates: QueryCacheUpsert<TType>[] = []

  if (cacheStore.models[type] == null) return cacheUpdates

  const targetStore = cacheStore.models[type] as ModelStore<TType>

  entries(source).forEach(([id, model]) => {
    if (id == null || model == null) return

    const appendNew = shouldAppendNew({
      ctx,
      key,
      type,
      model: model as Model<TType>,
    })
    const isNew = targetStore[id] == null

    if (!isNew) {
      const shouldRemoveOld = shouldRemoveOldEntity({
        ctx,
        key,
        type,
        model: model as Model<TType>,
      })
      if (shouldRemoveOld) {
        handleRemoveEntity(targetStore, cacheStore, id)
      }
    }

    if (isNew && !appendNew) return

    handleModelUpsert(
      isNew,
      targetStore,
      id,
      model,
      type,
      key,
      cacheStore,
      cacheUpdates,
      appendNew
    )
  })

  return cacheUpdates as QueryCacheUpsert<TType>[]
}

function handleRemoveEntity(
  targetStore: ModelStore<any>,
  target: QueryCacheMatches,
  id: ModelId
) {
  delete targetStore[id]
  if ('ids' in target) {
    target.ids = target.ids.filter((x) => x !== id)
  }
}

function handleModelUpsert<TType extends keyof AllModelsSchema>(
  isNew: boolean,
  targetStore: ModelStore<TType>,
  id: ModelId,
  model: Partial<Model<TType>>,
  type: TType,
  key: QueryKey,
  cacheStore: QueryCacheMatches,
  cacheUpdates: QueryCacheUpsert<TType>[],
  appendNew: boolean
) {
  if (isNew) {
    // Create new model
    const { applied } = appendModelToTargetStore(
      key,
      targetStore,
      id,
      model as Model<TType>
    )

    if (!applied.changed) return

    cacheUpdates.push({
      key,
      data: cacheStore,
      id,
      type,
      updates: model,
      inverse: applied.inverse,
    })
  } else {
    // Update existing model
    const applied = applyPartialToTargetStore(targetStore, id, model)
    if (!applied.changed) return

    cacheUpdates.push({
      key,
      data: cacheStore,
      id,
      type,
      updates: model,
      inverse: applied.inverse,
    })
  }

  if (appendNew) {
    const targetModelType = getResponseModelType(cacheStore)

    // If the model matches the target then update the ids
    if ('ids' in cacheStore && targetModelType === type && model.id != null) {
      cacheStore.ids = Array.from(new Set([...cacheStore.ids, model.id]))
    }
  }
}

function appendModelToTargetStore<TType extends keyof AllModelsSchema>(
  key: QueryKey,
  targetStore: ModelStore<TType>,
  id: ModelId,
  model: Model<TType>
): { applied: ApplyModelReturn<TType> } {
  const now = Date.now()

  if (isModelCacheKey(key)) {
    targetStore[id] = { updatedAt: now, value: model }
  } else {
    targetStore[id] = model
  }

  return {
    applied: {
      changed: true,
      inverse: DELETE_MODEL,
      data: model,
    },
  }
}
