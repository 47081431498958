import type { RecurringTaskSchema, TaskSchema } from '@motion/rpc-types'

export function getIsUsingTaskData(
  task: TaskSchema | RecurringTaskSchema | undefined,
  taskId: string | undefined,
  searchParams: { templateId?: string; template?: 'new' }
): task is TaskSchema | RecurringTaskSchema {
  // Task Modal
  const isTaskModal = taskId != null
  // Task Definition Modal above Flow Template Modal
  const isTaskDefinitionModal =
    searchParams.templateId != null || searchParams.template === 'new'

  return task != null && (isTaskModal || isTaskDefinitionModal)
}
