export const wrapVariableInDelimiters = (
  key: string,
  type: 'name' | 'description' = 'name'
) => {
  if (type === 'description') {
    return `"${key}"`
  }

  return `{{${key}}}`
}
