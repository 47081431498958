import { createTimer, type TimeFn } from '@motion/utils/debug'

/**
 * Creates a log function that prefixes text for a standard `logInDev` call to
 * make specific logs more distinguishable. This is useful for logging in a
 * specific module.
 * @param prefix
 * @param enabled Disables the logger if false is provided. Useful for turning
 * off situational logs
 */

type LogFn = (...args: unknown[]) => void
type Logger = LogFn & {
  debug: LogFn
  info: LogFn
  warn: LogFn
  error: LogFn
  time: TimeFn
}

type LogLevel = 'debug' | 'info' | 'warn' | 'error'
export function makeLog(prefix: string, enabled = true): Logger {
  prefix = normalizePrefix(prefix)

  const createLogger =
    (type: LogLevel) =>
    (...args: unknown[]) => {
      if (__IS_PROD__) {
        return
      }
      // eslint-disable-next-line no-console
      console[type](prefix, ...args)
    }

  const debug = createLogger('debug')
  const info = createLogger('info')
  const warn = createLogger('warn')
  const error = createLogger('error')

  return Object.assign(info, {
    debug,
    info,
    warn,
    error,
    time: createTimer(debug),
  })
}

function normalizePrefix(prefix: string) {
  if (prefix.startsWith('[') || prefix.endsWith(']')) return prefix
  return `[${prefix}]`
}

const defaultLogger = makeLog('[dev]')

export const logInDev = defaultLogger.info
export const warnInDev = defaultLogger.warn
export const errorInDev = defaultLogger.error
