import { twMerge } from 'tailwind-merge'

import {
  FullscreenModalHeader,
  type FullscreenModalHeaderProps,
} from './components/fullscreen-modal-header'

import { isElectron } from '../../../utils/user-agent'
import { UnstyledModal, type UnstyledModalProps } from '../unstyled-modal'

export type FullscreenModalProps = UnstyledModalProps & {
  headerTitle?: FullscreenModalHeaderProps['title']
  headerActions?: FullscreenModalHeaderProps['actions']
}

export function FullscreenModal({
  children,
  headerTitle,
  headerActions,
  ...rest
}: FullscreenModalProps) {
  return (
    <UnstyledModal
      modalClassName={twMerge(
        'grid grid-rows-[auto,1fr] absolute bg-primitives-global-black text-semantic-neutral-text-onDark',
        isElectron() ? 'inset-14 rounded-lg' : 'inset-0'
      )}
      {...rest}
    >
      <FullscreenModalHeader
        title={headerTitle}
        actions={headerActions}
        onClose={rest.onClose}
      />
      {children}
    </UnstyledModal>
  )
}
