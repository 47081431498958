import {
  PROJECT_TO_TASKS_INDEX,
  STAGE_DEFINITION_TO_TASKS_INDEX,
} from './index-names'
import { type TypedIndexDefinition } from './types'

/**
 * Add new indexes here.
 *
 * An index provides a way to query a relationship between two types.
 *
 * For example, the `projects` to `tasks` index allows us to query for all tasks
 * that belong to a project.
 *
 * Indexes are created by specifying a `sourceType`, `targetType`, and
 * `targetRelationField`.
 *
 * The `sourceType` is the type that will be the source of the relationship.
 * The `targetType` is the type that will be the target of the relationship.
 * The `targetRelationField` is the field on the target type that will be used to
 * establish the relationship (i.e., the foreign key).
 *
 * Note: the index updater contains logic to ensure that the index is updated
 * when the relationship changes. For example, when a task is moved to a
 * different project, the old `projects` to `tasks` index will be updated to
 * remove the task from the old project index, and the new `projects` to `tasks`
 * index will be updated to add the task to the new project index.
 */

type AllIndexes = {
  projects: 'tasks'
  stageDefinitions: 'tasks'
}

type IndexPairs = {
  [S in keyof AllIndexes]: TypedIndexDefinition<S, AllIndexes[S]>
}[keyof AllIndexes]

export const INDEX_DEFINITIONS: IndexPairs[] = [
  {
    name: PROJECT_TO_TASKS_INDEX,
    sourceType: 'projects',
    targetType: 'tasks',
    targetRelationField: 'projectId',
  },
  {
    name: STAGE_DEFINITION_TO_TASKS_INDEX,
    sourceType: 'stageDefinitions',
    targetType: 'tasks',
    // @ts-expect-error - fine. On NormalTaskSchema
    targetRelationField: 'stageDefinitionId',
  },
]
