import { type PMTeamSubscriptionType } from '@motion/rpc/types'

/**
 * @deprecated use react query team and useIsTeamTrialSetToCancel instead
 */
export const getIsTeamTrialSetToCancel = (
  pmTeamSubscription?: PMTeamSubscriptionType
): boolean => {
  return (
    pmTeamSubscription?.subscription?.status === 'trialing' &&
    pmTeamSubscription?.subscription?.cancel_at_period_end &&
    pmTeamSubscription?.status !== 'canceled'
  )
}
