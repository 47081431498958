import { sleep } from '@motion/utils/promise'
import { isMobileExperience } from '@motion/web-base/env'
import { warnInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import { deleteDB } from 'idb'

import { DB_VERSION } from './constants'
import { log } from './log'

import { createDb } from '../indexeddb/create-db'
import { createSimpleStore } from '../local-storage/local-storage-store'
import { type MotionDB } from '../types'

export async function initialize(): Promise<MotionDB> {
  if (!window.indexedDB || isMobileExperience()) {
    return createLocalStorageDb()
  }

  const idb = createDb('motion')
  return idb
    .open()
    .then(() => idb)
    .catch(async (ex) => {
      Sentry.captureException(ex, {
        tags: {
          category: 'indexeddb',
        },
        extra: {
          action: 'indexeddb.open',
          type: ex instanceof Error ? ex.name : 'Unknown',
        },
      })

      if (ex instanceof DOMException && ex.name === 'VersionError') {
        log('Version mismatch. Deleting existing database', { error: ex })
        Sentry.captureEvent({
          level: 'info',
          message: 'Version mismatch. Deleting existing database',
          extra: {
            original: ex.message,
          },
          fingerprint: ['indexeddb-version-mismatch'],
        })
        await Promise.race([
          deleteDB(idb.name).catch((deleteEx) => {
            Sentry.captureException(deleteEx, {
              tags: {
                category: 'indexeddb',
              },
              extra: {
                action: 'indexeddb.deleteDatabase',
              },
            })
            throw deleteEx
          }),
          sleep(3_000).then(() => {
            throw new Error('Timeout while deleting database')
          }),
        ])
        await idb.open()
        return idb
      }

      throw ex
    })
    .catch((ex) => {
      Sentry.captureException(
        new Error('Failed to open IndexedDB. Falling back to localStorage', {
          cause: ex,
        }),
        {
          tags: {
            category: 'indexeddb',
          },
          fingerprint: ['indexeddb-open-failed'],
        }
      )
      return createLocalStorageDb()
    })
}

function createLocalStorageDb(): MotionDB {
  return {
    version: DB_VERSION,
    name: 'localStorage',
    provider: 'localStorage',
    async open() {},
    async close() {},
    initialized: true,
    async clearAll() {
      try {
        window.localStorage.clear()
      } catch (ex) {
        warnInDev('Unable to clear local storage', ex)
      }
    },

    state: createSimpleStore(),
    queryCache: createSimpleStore(),
  }
}
