import { ExclamationTriangleSolid, RefreshSolid } from '@motion/icons'
import { classed, type VariantProps } from '@motion/theme'
import { Tooltip } from '@motion/ui/base'
import {
  formatDateTimeRange,
  formatTimeRange,
  SHOW_TIME_ABOVE_DURATION_MINUTES,
} from '@motion/ui-logic'
import { type DateLike } from '@motion/utils/dates'

import {
  type CSSProperties,
  forwardRef,
  type MouseEventHandler,
  type ReactNode,
  useMemo,
} from 'react'

import { EventColorBarsGrid } from './event-color-bars-grid'
import {
  getDurationEvent,
  getNameLineCount,
  SHORT_DURATION_THRESHOLD,
} from './utils'

import { CalendarPalette } from '../palette'

export interface CalendarEventProps
  extends Omit<VariantProps<typeof Event>, 'multiCalendar'>,
    CalendarPalette {
  startTime: DateLike
  endTime: DateLike
  allDay?: boolean
  past?: boolean
  name?: string
  hideTime?: boolean
  allOthersDeclined?: boolean
  otherCalendars?: CalendarPalette[]
  eventLocation?: string
  isRecurring?: boolean
  showDate?: boolean
  onContextMenu?: MouseEventHandler<HTMLDivElement>
  variant?: 'default' | 'dashed'
  disableTooltip?: boolean
  renderTopRightContent?: () => ReactNode
}

export const CalendarEvent = forwardRef<HTMLDivElement, CalendarEventProps>(
  function CalendarEvent(props, ref) {
    const {
      allOthersDeclined,
      colorId,
      colorHue,
      startTime,
      endTime,
      allDay = false,
      past = false,
      name = '',
      otherCalendars = [],
      eventLocation,
      isRecurring = false,
      showDate = false,
      hideTime,
      disableTooltip,
      renderTopRightContent,
      ...rest
    } = props

    const duration = useMemo(
      () => getDurationEvent(startTime, endTime),
      [startTime, endTime]
    )

    const hasOtherCalendars = otherCalendars.length > 0

    const formatter = showDate ? formatDateTimeRange : formatTimeRange

    const showName = name.length > 0
    const showTime =
      !hideTime &&
      !allDay &&
      (!showName || duration >= SHOW_TIME_ABOVE_DURATION_MINUTES)
    const height = allDay ? '20px' : '100%'

    const NameLabel = (
      <Name
        style={
          {
            '--name-lines': getNameLineCount(duration),
          } as CSSProperties
        }
      >
        {name}
      </Name>
    )

    const renderEvent = ({ past }: { past: CalendarEventProps['past'] }) => (
      <Event
        ref={ref}
        {...rest}
        multiCalendar={hasOtherCalendars}
        style={{ height }}
      >
        <EventOverlays past={past} multiCalendar={hasOtherCalendars} />
        <TextContainer
          variant={rest.variant}
          short={duration < SHORT_DURATION_THRESHOLD}
          timeOnly={showTime && !showName}
          data-timeonly={showTime && !showName}
        >
          {showName && (
            <NameAndIconContainer>
              <Tooltip
                asChild
                renderContent={() => {
                  if (disableTooltip) {
                    return null
                  }

                  return (
                    <div className='flex flex-col gap-2 items-start text-left'>
                      {allOthersDeclined && (
                        <p>Everyone else declined the event</p>
                      )}
                      <p>{name}</p>
                      {showTime && (
                        <p className='text-xs'>
                          {formatter(startTime, endTime)}
                        </p>
                      )}
                    </div>
                  )
                }}
              >
                {allOthersDeclined ? (
                  <div className='flex items-center gap-1 min-w-0'>
                    <DeclinedIcon showTime={showTime} />
                    {NameLabel}
                  </div>
                ) : (
                  NameLabel
                )}
              </Tooltip>
              <div className='flex items-center shrink-0 self-start mt-0.5'>
                {isRecurring && (
                  <Tooltip
                    asChild
                    renderContent={() => {
                      if (disableTooltip) {
                        return null
                      }

                      return 'Recurring event'
                    }}
                  >
                    <RefreshSolid className='size-2.5' />
                  </Tooltip>
                )}
                {renderTopRightContent?.()}
              </div>
            </NameAndIconContainer>
          )}
          {showTime && (
            <TimeAndLocationWrapper
              duration={duration}
              startTime={startTime}
              endTime={endTime}
              eventLocation={eventLocation}
              formatter={formatter}
            />
          )}
        </TextContainer>
      </Event>
    )

    return (
      <CalendarPalette colorId={colorId} colorHue={colorHue}>
        {hasOtherCalendars ? (
          <EventColorBarsGrid otherCalendars={otherCalendars} roundFirst>
            {renderEvent({ past })}
          </EventColorBarsGrid>
        ) : (
          renderEvent({ past })
        )}
      </CalendarPalette>
    )
  }
)

interface TimeAndLocationProps {
  duration: number
  startTime: DateLike
  endTime: DateLike
  eventLocation?: string
  formatter: (startDate: DateLike, endDate: DateLike) => string
}

function TimeAndLocationWrapper({
  duration,
  startTime,
  endTime,
  eventLocation,
  formatter,
}: TimeAndLocationProps) {
  /**
   * Doesn't have location or under 30 mins
   */
  if (!eventLocation || duration < 30) {
    return (
      <TimeAndLocation
        formatter={formatter}
        startTime={startTime}
        endTime={endTime}
      />
    )
  }

  /**
   * Has location and between 30 and 60 mins
   */
  if (duration >= 30 && duration < 60) {
    return (
      <TimeAndLocation
        formatter={formatter}
        startTime={startTime}
        endTime={endTime}
        eventLocation={eventLocation}
        inline
      />
    )
  }

  /**
   * Has location and >60 mins
   */
  return (
    <TimeAndLocation
      formatter={formatter}
      startTime={startTime}
      endTime={endTime}
      eventLocation={eventLocation}
    />
  )
}

const Event = classed('div', {
  base: `
  group/event-item
  min-w-full
  rounded
  relative
  cursor-pointer
  border
  border-transparent

  before:block
  before:absolute
  before:rounded-l
  before:-left-px
  before:-top-px
  before:-bottom-px
  before:w-[4px]
  before:bg-palette-highlight-subtle
  overflow-hidden
  `,
  variants: {
    multiCalendar: {
      true: 'rounded-l-none before:rounded-l-none',
    },
    availability: {
      default: 'bg-calendar-palette-bg-default',
      free: `
        bg-calendar-bg-default
        border-calendar-palette-border-default
        border
      `,
    },
    selected: {
      true: `
        bg-calendar-palette-highlight-default
        border-calendar-palette-highlight-default
        before:w-0
      `,
    },
    variant: {
      default: ``,
      dashed: `
        border-calendar-palette-border-default border-dashed border
      `,
    },
    rsvp: {
      default: `
        border
        border-dashed
        border-calendar-palette-border-default
      `,
      maybe: `
        border
        border-dashed
        border-calendar-palette-border-default
      `,
      no: `
        border
        border-dashed
        border-calendar-palette-border-default
      `,
    },
  },
  compoundVariants: [
    {
      availability: 'default',
      variant: 'dashed',
      className: 'bg-calendar-palette-bg-light',
    },
    {
      availability: 'free',
      variant: 'dashed',
      className: 'bg-transparent',
    },
    {
      selected: true,
      variant: 'dashed',
      className: 'border-[2px] border-calendar-palette-bg-selected',
    },
    {
      selected: false,
      rsvp: 'default',
      className:
        'bg-event-stripes from-calendar-bg-default to-calendar-palette-stripe-default',
    },
    {
      selected: false,
      rsvp: 'maybe',
      className:
        'bg-event-stripes from-calendar-bg-default to-calendar-palette-stripe-default',
    },
    {
      selected: false,
      rsvp: 'no',
      className: 'bg-calendar-bg-default',
    },
  ],
  defaultVariants: {
    selected: false,
    availability: 'default',
  },
  dataAttributes: ['rsvp', 'selected'],
})

const EventOverlays = classed(
  'div',
  'relative absolute -inset-px',
  'pointer-events-none rounded-sm',
  '[[data-selected]_&]:invisible [[data-selected]:hover_&]:invisible',
  {
    variants: {
      past: {
        true: `
          opacity-50 bg-calendar-bg-default
          group-hover/event-item:opacity-15 group-hover/event-item:bg-calendar-palette-highlight-default
        `,
        false:
          'invisible group-hover/event-item:visible opacity-15 bg-calendar-palette-highlight-default',
      },
      multiCalendar: {
        true: 'rounded-l-none',
      },
    },
  }
)

const TextContainer = classed(
  'div',
  'flex h-full pl-[7px] pr-1 text-[11px] text-calendar-palette-text-default font-medium',
  {
    variants: {
      short: {
        true: 'items-center leading-none',
        false: 'flex-col gap-0.5 leading-4',
      },
      variant: {
        default: '[[data-selected]_&]:text-calendar-palette-text-selected',
        dashed: '',
      },
      timeOnly: {
        true: 'pt-1',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

const DeclinedIcon = classed(ExclamationTriangleSolid, {
  base: 'h-3 w-3 flex-shrink-0 mt-0.5',
  variants: {
    showTime: {
      true: 'mt-1 self-start',
      false: 'mt-0.5',
    },
  },
})

const Name = classed(
  'div',
  'font-regular line-clamp-[--name-lines] pr-1',
  `[[data-rsvp='no']_&]:line-through`
)

const NameAndIconContainer = classed(
  'div',
  'flex items-center w-full justify-between'
)

const TimeLocationContainer = classed(
  'div',
  `text-[9px] overflow-hidden gap-0.5 flex flex-col font-thin`,
  '[[data-timeonly]_&]:font-medium'
)
const Time = classed('span', {
  base: 'pr-1 leading-3',
  variants: {
    truncate: { true: `truncate`, false: `whitespace-nowrap` },
  },
  defaultVariants: { truncate: false },
})
const Location = classed('span', `whitespace-nowrap leading-3`)

function TimeAndLocation({
  startTime,
  endTime,
  eventLocation,
  inline,
  formatter,
}: Omit<TimeAndLocationProps, 'duration'> & { inline?: true }) {
  return (
    <TimeLocationContainer>
      <Time>
        {formatter(startTime, endTime)}
        {eventLocation != null && inline != null && (
          <Location>{`, ${eventLocation}`}</Location>
        )}
      </Time>
      {eventLocation != null && inline == null && (
        <Location>{eventLocation}</Location>
      )}
    </TimeLocationContainer>
  )
}
