import { type ReactNode, useEffect } from 'react'

import { markExposure } from './functions'
import { type AmplitudeExperimentName, type TreatmentsOf } from './types'
import { useHasTreatment } from './use-experiment'

export type HasExperimentProps<TName extends AmplitudeExperimentName> = {
  name: TName
  treatment?: TreatmentsOf<TName>
  fallback?: ReactNode
  children?: ReactNode
}

export const HasExperiment = <TName extends AmplitudeExperimentName>(
  props: HasExperimentProps<TName>
) => {
  const { name, fallback = null, children = null, treatment } = props

  const enabled = useHasTreatment(name, treatment)

  useEffect(() => {
    markExposure(name)
  }, [enabled, name])

  if (!enabled) {
    return <>{fallback}</>
  }
  return <>{children}</>
}
