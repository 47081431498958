import { type SvgIconProps, WorkspaceSolid } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { ProjectPalette } from '../palette'

type WorkspaceColoredIconProps = SvgIconProps & {
  color?: COLOR
}

export const WorkspaceColoredIcon = forwardRef<
  SVGSVGElement,
  WorkspaceColoredIconProps
>(function WorkspaceColoredIcon({ color = 'gray', className, ...etc }, ref) {
  return (
    <ProjectPalette color={color}>
      <WorkspaceSolid
        ref={ref}
        {...etc}
        className={twMerge(
          '!text-palette-highlight-default shrink-0',
          className
        )}
      />
    </ProjectPalette>
  )
})
