import { debounce } from 'es-toolkit/compat'
import { useCallback, useRef } from 'react'

export type DebouncedFunc<F extends (...args: any[]) => void> = ReturnType<
  typeof debounce<F>
>
type DebounceSettings = Exclude<Parameters<typeof debounce>[2], undefined>

export const useDebouncedCallback = <T extends (...args: any[]) => any>(
  callback: T,
  delayMs: number,
  options?: DebounceSettings
): DebouncedFunc<T> => {
  const callbackRef = useRef<T>(callback)
  callbackRef.current = callback
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(
      ((...args) => callbackRef.current(...args)) as T,
      delayMs,
      options
    ),
    []
  )
}
