import { useMemoDeep } from '@motion/react-core/hooks'
import { ThemeResolver, type ThemeResolverProps } from '@motion/theme/dom'

import { type ReactNode } from 'react'

import { LinkContext, type LinkLikeComponent } from './link-context'
import { ModalStackProvider } from './modal-stack'
import { type UIOptions, UIOptionsContext } from './ui-options'

type MotionUIProviderProps = {
  linkComponent?: LinkLikeComponent
  theme?: ThemeResolverProps['theme']
  options?: UIOptions
  children: ReactNode
}

export function MotionUIProvider({
  linkComponent,
  theme,
  options,
  children,
}: MotionUIProviderProps) {
  const opts = useMemoDeep(options)

  return (
    <ThemeResolver theme={theme}>
      <UIOptionsContext.Provider value={opts}>
        <LinkContext.Provider value={linkComponent}>
          <ModalStackProvider>{children}</ModalStackProvider>
        </LinkContext.Provider>
      </UIOptionsContext.Provider>
    </ThemeResolver>
  )
}
