import { classed, type VariantProps } from '@motion/theme'
import { useTheme } from '@motion/theme/dom'
import { addComponentName } from '@motion/ui/helpers'
import { templateStr } from '@motion/ui-logic'

import { forwardRef, type ReactNode } from 'react'

import { useShortcut } from './use-shortcut'
import { getShortcutDisplaySequence } from './utils'

import { Tooltip } from '../tooltip'

export type ShortcutProps = {
  shortcut: string
  renderTooltip?: ({ shortcut }: { shortcut: ReactNode }) => ReactNode
  size?: VariantProps<typeof KeyboardKey>['size']
  sentiment?: VariantProps<typeof KeyboardKey>['sentiment']
}

export const Shortcut = (props: ShortcutProps) => {
  const { theme } = useTheme()
  const { renderTooltip, sentiment, ...rest } = props

  const effectiveTheme = theme === 'dark' ? 'light' : 'dark'

  return (
    <Tooltip
      asChild
      renderContent={
        renderTooltip
          ? () => {
              const renderedKeys = <ShortcutKeys {...rest} />

              const tooltip = renderTooltip({
                shortcut: renderedKeys,
              })

              if (typeof tooltip === 'string') {
                return (
                  <span data-theme={effectiveTheme}>
                    {templateStr(tooltip, {
                      shortcut: renderedKeys,
                    })}
                  </span>
                )
              }

              return <span data-theme={effectiveTheme}>{tooltip}</span>
            }
          : undefined
      }
    >
      <ShortcutKeys {...rest} sentiment={sentiment} />
    </Tooltip>
  )
}

const ShortcutKeys = forwardRef<HTMLSpanElement, ShortcutProps>(
  function ShortcutKeys({ shortcut, sentiment, size }, ref) {
    return (
      <span
        className='inline-flex gap-1'
        ref={ref}
        {...addComponentName('ShortcutKeys')}
      >
        {getShortcutDisplaySequence(shortcut).map((k) => (
          <KeyboardKey key={k} size={size} sentiment={sentiment}>
            {k}
          </KeyboardKey>
        ))}
      </span>
    )
  }
)

const KeyboardKey = classed('span', {
  base: `
    rounded-sm px-[3px]
    capitalize text-center font-semibold
  `,
  variants: {
    size: {
      default: 'text-[10px] leading-[14px] h-3.5 min-w-3.5',
      small: 'text-[8px]',
    },
    sentiment: {
      default:
        'bg-keyboardShortcut-default-bg text-keyboardShortcut-default-text',
      primary:
        'bg-keyboardShortcut-primary-bg text-keyboardShortcut-primary-text',
      error: 'bg-keyboardShortcut-error-bg text-keyboardShortcut-error-text',
      warning:
        'bg-keyboardShortcut-warning-bg text-keyboardShortcut-warning-text',
      onWarning:
        'bg-keyboardShortcut-onWarning-bg text-keyboardShortcut-onWarning-text',
      onDark: 'bg-keyboardShortcut-onDark-bg text-keyboardShortcut-onDark-text',
      inherit: 'bg-keyboardShortcut-default-bg',
    },
  },
  defaultVariants: {
    size: 'default',
    sentiment: 'default',
  },
})

export type ShortcutListener = {
  shortcut: Parameters<typeof useShortcut>['0']
  handler: Parameters<typeof useShortcut>['1']
  options?: Parameters<typeof useShortcut>['2']
}

export const ShortcutListener = ({
  shortcut,
  handler,
  options,
}: ShortcutListener) => {
  useShortcut(shortcut, handler, options)

  return null
}
