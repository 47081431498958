import { FormModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { isEnterpriseEmail, templateStr } from '@motion/ui-logic'

import { useState } from 'react'

type EnterpriseModalProps = {
  onClose: () => void
  onAction: (newEmail: string) => void
}

export const EnterpriseEmailModal = ({
  onClose,
  onAction,
}: EnterpriseModalProps) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [workEmail, setWorkEmail] = useState('')

  return (
    <FormModal
      title='Please enter your work email'
      visible
      onClose={onClose}
      submitAction={{
        text: 'Submit',
        onAction: () => {
          if (!isEnterpriseEmail(workEmail)) {
            setErrorMessage(
              templateStr(
                "{{email}}, is not a valid work email, please enter an email for your company's domain",
                { email: workEmail }
              )
            )
          } else {
            onAction(workEmail)
            onClose()
          }
        },
      }}
    >
      <div className='w-[400px] flex flex-col gap-4'>
        <TextField
          placeholder='Enter your work email'
          value={workEmail}
          onChange={setWorkEmail}
        />
        {!!errorMessage && (
          <p className='text-semantic-error-text-default text-xs'>
            {errorMessage}
          </p>
        )}
      </div>
    </FormModal>
  )
}
