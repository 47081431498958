import { useClosure } from '@motion/react-core/hooks'
import { makeLog } from '@motion/web-base/logging'

import { beforeAuthStateChanged } from 'firebase/auth'
import { useEffect } from 'react'

import { type User } from '../../firebase'
import { firebase } from '../../firebase'

let authPostProcess: FirebaseUserChangedHandler | undefined

export type FirebaseUserChangedHandler = (user: User | null) => Promise<boolean>

const log = makeLog('auth')
export function getAuthPostProcess(user: User | null): Promise<boolean> {
  if (authPostProcess == null) return Promise.resolve(true)

  log('deferring login')
  return authPostProcess(user).finally(() => log('post process complete'))
}

export function setAuthPostProcess(fn: FirebaseUserChangedHandler) {
  log('setting deferred login')
  authPostProcess = fn
}
export function clearAuthPostProcess() {
  log('clearing deferred login')
  authPostProcess = undefined
}

export const useBeforeUserChanged = (fn: FirebaseUserChangedHandler) => {
  const stable = useClosure(fn)

  useEffect(() => {
    setAuthPostProcess(stable)
    return () => clearAuthPostProcess()
  }, [stable])
}

export const useBeforeFirebaseUserChanged = (
  fn: FirebaseUserChangedHandler
) => {
  const stable = useClosure(fn)
  useEffect(() => {
    return beforeAuthStateChanged(firebase.auth(), async (user) => {
      const result = await stable(user)
      if (result === false) {
        throw new Error('Login aborted')
      }
    })
  }, [stable])
}
