import { isWeekend } from '@motion/utils/dates'

import { type DateTime } from 'luxon'

/**
 *
 * Returns a list of all the dates between the start date & end date
 * including the start date and the end date
 *
 * @param startDate {DateTime} - Start Date
 * @param endDate {DateTime} - End Date
 * @param includeWeekends {boolean} - Include weekends in the list
 *
 * **/
export function getDatesBetween(
  startDate: DateTime,
  endDate: DateTime,
  includeWeekends: boolean = true
): DateTime[] {
  const { days: daysBetween } = endDate.diff(startDate, ['days'])
  return Array.from({ length: daysBetween + 1 }, (_, days) => {
    const date = startDate.plus({ days })

    if (isWeekend(date) && !includeWeekends) {
      return null
    }

    return date
  }).filter(Boolean)
}
