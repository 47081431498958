import { makeLog } from '@motion/web-base/logging'

import { initializeApp } from 'firebase/app'

const log = makeLog('firebase.app')

export const FIREBASE_CONFIG = !__IS_PROD__
  ? {
      apiKey: 'AIzaSyCCtXsy5isSk_qiOWfDz2dqUHMpZBab_KU',
      appId: '1:921788290562:web:ea0505f15bb5ca0b87f91b',
      authDomain: 'motion-dev-366719.firebaseapp.com',
      messagingSenderId: '921788290562',
      projectId: 'motion-dev-366719',
      storageBucket: 'motion-dev-366719.appspot.com',
    }
  : {
      apiKey: 'AIzaSyATtXlUjMMmvW2xQ6ArB9suQd8bXlgU0NE',
      appId: '1:808943271132:web:9a59d13ba5eb41dc5cc819',
      authDomain: 'auth.usemotion.com',
      databaseURL: 'https://light-relic-254202.firebaseio.com',
      messagingSenderId: '808943271132',
      projectId: 'light-relic-254202',
      storageBucket: 'light-relic-254202.appspot.com',
    }

export function initialize() {
  log('initialize')
  return initializeApp(FIREBASE_CONFIG)
}
