import { type COLOR } from '@motion/shared/common'

import { type ReactNode } from 'react'

import { getColorHueValue, PaletteProvider } from '../../palette'

type ProjectPaletteProps = {
  color: COLOR
  children: ReactNode
}

export const ProjectPalette = ({ children, color }: ProjectPaletteProps) => {
  const hue = getColorHueValue(color)
  return <PaletteProvider colorHue={hue}>{children}</PaletteProvider>
}
