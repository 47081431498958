import { type QueryKey } from '@tanstack/react-query'

import { type TypedQueryKeyData } from '../keys'

export const SKIP_UPDATE = Symbol('skip-update')

export type Effect<TArgs, TData> =
  | SuccessEffect<TArgs, TData>
  | OptimisiticEffect<TArgs>

export type SuccessEffectUpdate<
  TArgs,
  TData,
  TKey extends QueryKey = QueryKey,
> = {
  key(args: TArgs): TKey
  merge(
    value: TData,
    previousValue: TypedQueryKeyData<TKey> | undefined,
    args: TArgs
  ): any | typeof SKIP_UPDATE
  action: 'update'
}

export type SuccessEffectRemove<TArgs> = {
  key(args: TArgs): QueryKey
  action: 'remove'
}

export type SuccessEffectInvalidate<TArgs> = {
  key(args: TArgs): QueryKey
  action: 'invalidate'
}

export type SuccessEffect<TArgs, TData> = { on: 'success' } & (
  | SuccessEffectUpdate<TArgs, TData>
  | SuccessEffectRemove<TArgs>
  | SuccessEffectInvalidate<TArgs>
)

export type OptimisticEffectUpdate<TArgs, TKey extends QueryKey = QueryKey> = {
  key(args: TArgs): TKey
  merge(
    data: TArgs,
    previousValue: TypedQueryKeyData<NoInfer<TKey>> | undefined
  ): any | typeof SKIP_UPDATE
  action: 'update'
}

export type OptimisticEffectRemove<TArgs> = {
  key(args: TArgs): QueryKey
  action: 'remove'
}

export type OptimisiticEffect<TArgs> = { on: 'mutate' } & (
  | OptimisticEffectUpdate<TArgs>
  | OptimisticEffectRemove<TArgs>
)
