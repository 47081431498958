import { type VariantProps } from '@motion/theme'

import { type BannerContainer } from './banner-container'

export type BannerSentiment = NonNullable<
  VariantProps<typeof BannerContainer>['sentiment']
>
export type BannerVariant = NonNullable<
  VariantProps<typeof BannerContainer>['variant']
>
export type BannerSize = NonNullable<
  VariantProps<typeof BannerContainer>['size']
>

export const BannerSentimentValues: BannerSentiment[] = [
  'default',
  'warning',
  'error',
]

export const BannerVariantValues: BannerVariant[] = ['default', 'onPage']
