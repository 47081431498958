export type Details = {
  state: 'open' | 'close'
}

export const POPOVER_EVENT_NAME = 'motion:popover'

declare global {
  interface HTMLElementEventMap {
    [POPOVER_EVENT_NAME]: PopoverEvent
  }
}

export class PopoverEvent extends Event {
  detail: Details

  constructor(data: Details) {
    super(POPOVER_EVENT_NAME, {
      bubbles: true,
    })
    this.detail = data
  }
}
