import { type MotionDesignTokenNames } from './types'

/**
 * Gets the reference to the css variable for a given token
 * @param token the token to get the css variable for
 * @returns the formatted variable
 */
export function cssVar(token: MotionDesignTokenNames) {
  return `var(--motion-${token})`
}
