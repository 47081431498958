import {
  getSegmentAnalytics,
  getTrackingCookies,
  recordAnalyticsEvent,
} from '@motion/web-base/analytics'

export const useEnterpriseLink = () => {
  let enterpriseLink =
    'https://cal.com/forms/d79d5ff5-b55e-4f7e-a47c-a25d46bb1b28'

  return (
    location: string,
    userEmail?: string,
    workEmail?: string,
    displayName?: string
  ) => {
    const segmentAnalytics = getSegmentAnalytics()
    if (segmentAnalytics) {
      const cookieData = getTrackingCookies()
      segmentAnalytics.track(
        'ENTERPRISE_CARD_CLICKED',
        {
          url: enterpriseLink,
          isInvalidLink: false,
          location,
          ...cookieData,
        },
        {
          email: userEmail,
        }
      )
      if (userEmail) {
        segmentAnalytics.track(
          'ENTERPRISE_WORK_EMAIL_SUBMITTED',
          {
            email: workEmail ?? userEmail,
            location,
            ...cookieData,
          },
          {
            email: userEmail,
          }
        )
      }
    } else {
      recordAnalyticsEvent('ENTERPRISE_CARD_CLICKED', {
        url: enterpriseLink,
        isInvalidLink: false,
        location,
      })
    }

    if (userEmail || workEmail) {
      const url = new URL(enterpriseLink)
      url.searchParams.set('email', workEmail ?? userEmail ?? '') // Add fallback empty string
      if (displayName) {
        url.searchParams.set('name', displayName)
      }
      window.open(url.toString(), '_blank')
    } else {
      window.open(enterpriseLink, '_blank')
    }
  }
}
