import { DateTime } from 'luxon'

const possibleTimeFormats = ['h:mma', 'ha', 'hh:mma', 'H:mm', 'HH:mm']

/**
 * Convert a time string into a DateTime.
 * @param time string with one of these formats: 'h:mma', 'ha', 'hh:mma', 'H:mm', 'HH:mm'
 * @returns DateTime
 */
export function parseTime(time: string): DateTime {
  for (const format of possibleTimeFormats) {
    try {
      return DateTime.fromFormat(time, format)
    } catch (err) {}
  }

  throw new Error(`Invalid time format: "${time}"`)
}
