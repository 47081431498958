import { useClosure } from '@motion/react-core/hooks'
import {
  type SocketEventData,
  type SocketEventType,
} from '@motion/shared/websockets'

import { useEffect } from 'react'

import { websocketsEventSubscriber } from './websocketsEventSubscriber'

export const useOnWebsocketEvent = <E extends SocketEventType>(
  name: E,
  handler: (data: SocketEventData<E>) => void
) => {
  const stable = useClosure(handler)

  useEffect(() => {
    return websocketsEventSubscriber.on(name, (data) => {
      stable(data)
    })
  }, [name, stable])
}
