import { memo } from 'react'

type ChildProps<T> = {
  item: T
  index: number
  renderChildren: (props: { item: T; index: number }) => JSX.Element
}
const Child = <T>({ item, index, renderChildren }: ChildProps<T>) => {
  return renderChildren({ item, index })
}

export const MemoizedChild = memo(Child) as <T>(
  props: ChildProps<T>
) => JSX.Element
