/**
 * Performs a shallow test to see if the value matches
 * @param value the object to test
 * @param match the values to test against
 * @returns {boolean} true if matches
 */
export const shallowMatch = <T extends object>(
  value: T,
  match: Partial<T>
): boolean => {
  const keys = Object.keys(match) as (keyof T)[]
  return keys.every((key) => match[key] === value[key])
}

/**
 * Checks if @see {valueToMatch} is a subset of @see {value}
 *
 * @param value The value to check
 * @param valueToMatch The value to match against
 * @returns true if @see {valueToMatch} is a subset of @see {value}
 */
export function matches<TPartial, TObject extends TPartial>(
  value: TObject,
  valueToMatch: TPartial
): boolean {
  if (valueToMatch == null) return value == null
  if (value == null) return false

  if (typeof valueToMatch !== typeof value) return false
  if (Array.isArray(valueToMatch)) {
    if (!Array.isArray(value)) return false
    return valueToMatch.every((item, index) => matches(value[index], item))
  }
  if (typeof valueToMatch !== 'object') return valueToMatch === value

  const keys = Object.keys(valueToMatch)
  return keys.every((key) => {
    // mobile thinks this line is fine, although it fails in utils.
    // suppressing the warnings / typecheck failures
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - keys will be fine
    return matches(value[key], valueToMatch[key])
  })
}
