function formatTime(start: number, end: number) {
  return (end - start).toFixed(2).padStart(8)
}
function isPromise<T>(value: Promise<T> | T): value is Promise<T> {
  return typeof value === 'object' && 'then' in (value as any)
}

export type TimeFn = {
  <T>(
    label: string,
    fn: () => Promise<T>,
    reporter?: (value: T) => unknown
  ): Promise<T>
  <T>(label: string, fn: () => T, reporter?: (value: T) => unknown): T
}

declare const __IS_PROD__: boolean

// eslint-disable-next-line no-console
export const time = createTimer(console.debug.bind(console))

export function createTimer(
  logger: (msg: string, ...args: unknown[]) => void
): TimeFn {
  return function time<T>(
    label: string,
    fn: () => Promise<T> | T,
    reporter?: (value: T) => any
  ): Promise<T> | T {
    if (typeof __IS_PROD__ !== 'undefined' && __IS_PROD__) return fn()

    const start = performance.now()
    const result = fn()
    if (isPromise(result)) {
      return result.then((ret) => {
        const end = performance.now()
        if (reporter) {
          logger(`TIME: [${formatTime(start, end)}] ${label}`, reporter(ret))
        } else {
          logger(`TIME: [${formatTime(start, end)}] ${label}`)
        }
        return ret
      })
    }
    const end = performance.now()

    logger(`TIME: [${formatTime(start, end)}] ${label}`)
    return result
  }
}
