import { safeJsonParse } from '@motion/utils/object'

import { type DataStore } from '../types'

export function createSimpleStore<TValue>(
  prefix: string = 'motion_'
): DataStore<TValue> {
  const store = window.localStorage

  function read(key: string): TValue | undefined {
    const value = store.getItem(key)
    return safeJsonParse(value)
  }

  function qualifiedKey(key: string) {
    return `${prefix}:${key}`
  }

  return {
    async get(key) {
      return read(qualifiedKey(key))
    },
    async getMany(keys): Promise<Record<string, TValue | undefined>> {
      return keys.reduce(
        (acc, key) => {
          acc[key] = read(qualifiedKey(key))
          return acc
        },
        {} as Record<string, TValue | undefined>
      )
    },
    async set(key, value) {
      store.setItem(qualifiedKey(key), JSON.stringify(value))
    },
    async delete(key) {
      store.removeItem(qualifiedKey(key))
    },
    async clear() {
      for (let i = 0; i < store.length; i++) {
        const key = store.key(i)
        if (key == null) break
        if (!key.startsWith(prefix)) continue
        store.removeItem(key)
      }
    },
  }
}
