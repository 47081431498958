import { replace } from './replace'

/**
 * Replaces an element in an array by matching a field value and returns a new array
 * @param {T[]} array - The source array
 * @param {T} value - The replacement value
 * @param {K} [key='id'] - The key to match on. Defaults to `id`
 * @returns {T[]} A new array with the matched element replaced, or the original array if no match
 */
export function replaceByField<T extends { id: string | number }>(
  array: T[],
  value: T
): T[]
export function replaceByField<T, K extends keyof T>(
  array: T[],
  value: T,
  key: K
): T[]
export function replaceByField<T, K extends keyof T>(
  array: T[],
  value: T,
  key: K = 'id' as K
): T[] {
  const index = array.findIndex((item) => item[key] === value[key])

  if (index === -1) {
    return array
  }

  return replace(array, index, value)
}
