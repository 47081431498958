import type { QueryClient, QueryKey } from '@tanstack/react-query'

export function setCacheInfinite(client: QueryClient, key: QueryKey) {
  const queryCache = client.getQueryCache()
  const cacheQuery = queryCache.find(key)

  // Update cacheTime to infinite
  if (cacheQuery && cacheQuery.cacheTime !== Infinity) {
    cacheQuery.cacheTime = Infinity
    queryCache.remove(cacheQuery)
    queryCache.add(cacheQuery)
  }
}
