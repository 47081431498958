import { timeout } from '@motion/utils/promise'

import { type IDBPDatabase, openDB, type StoreNames } from 'idb'

import { log } from './log'
import { type MotionDBConfig } from './types'

import { DB_VERSION, QUERY_PERSISTOR_MIN_DB_VERSION } from '../db/constants'

async function openDatabase(name: string) {
  const db = await openDB<MotionDBConfig>(name, DB_VERSION, {
    upgrade(db, oldVersion, newVersion, tx) {
      log('upgrade', oldVersion, newVersion)
      if (newVersion == null) return

      createStore(db, 'state')
      if (newVersion >= QUERY_PERSISTOR_MIN_DB_VERSION) {
        createStore(db, 'react-query')
      }
    },
    blocked(currentVersion, blockedVersion, event) {
      log('blocked', currentVersion, blockedVersion, event)
    },
    blocking(currentVersion, blockedVersion, event) {
      log('blocking', currentVersion, blockedVersion, event)

      // reload the page so that the connection can be re-established
      window.location.reload()
    },
    terminated() {
      log('terminated')
    },
  })

  return db
}

export function openDatabaseWithTimeout(name: string, ms = 5_000) {
  return Promise.race([openDatabase(name), timeout('indexeddb.open', ms)])
}

function createStore(
  db: IDBPDatabase<MotionDBConfig>,
  name: StoreNames<MotionDBConfig>
) {
  if (db.objectStoreNames.contains(name)) return
  log('creating store', name)
  db.createObjectStore(name)
}
