import { CompanyLogo } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'

const providerTextMap = {
  APPLE: 'Apple',
  MICROSOFT: 'Microsoft',
  GOOGLE: 'Google',
} as const
type Provider = keyof typeof providerTextMap

export const LoginButton = (props: {
  onClick: () => void
  provider?: Provider
  prefix?: string
  className?: string
}) => {
  const { onClick, provider, prefix = 'Log in with', className = '' } = props

  return (
    <button
      className={`flex w-full items-center justify-center gap-2 rounded border border-[#D3D3D8] py-1.5 h-[45px] ${className}`}
      onClick={onClick}
    >
      {provider && (
        <div className='flex items-center h-[18px] w-[18px]'>
          <CompanyLogo provider={provider} />
        </div>
      )}
      <span className='font-semibold text-black'>
        {templateStr('{{prefix}} {{provider}}', {
          prefix: prefix,
          provider: provider && providerTextMap[provider],
        })}
      </span>
    </button>
  )
}
