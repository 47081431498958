import { CheckOutline, FilledChevronRightSolid } from '@motion/icons'

import { type Row } from '@tanstack/react-table'
import { type PropsWithChildren, type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { TreeNodeShell } from './tree-node-shell'

import { Button } from '../../button'
import { useTreeKeyboardContext } from '../tree-keyboard-context'
import { type VirtualizedTreeNode } from '../types'
import { useTreeContext } from '../virtualized-tree-context'

type ExpandableTreeNodeProps = PropsWithChildren<{
  row: Row<VirtualizedTreeNode>
  renderIcon: (node: VirtualizedTreeNode) => ReactNode
}>

export const ExpandableTreeNode = (props: ExpandableTreeNodeProps) => {
  const { row, renderIcon, children } = props
  const { onSelect, computeSelectable } = useTreeContext()
  const { activeRow, setActiveRow } = useTreeKeyboardContext()

  const node = row.original

  const isActive = !!activeRow && activeRow.id === row.id
  const showArrow = !!row.originalSubRows

  return (
    <TreeNodeShell
      disabled={node.disabled}
      className='h-8'
      onClick={() => {
        if (computeSelectable(node)) {
          row.toggleSelected(true)
          onSelect(row)
          return
        }
        if (row.getCanExpand()) {
          row.toggleExpanded()
        }
      }}
      focused={isActive}
      style={{
        marginLeft: (row.depth ?? 0) * 12,
      }}
      onPointerMove={() => (!isActive ? setActiveRow(row) : undefined)}
    >
      <div className={twMerge('opacity-0', showArrow && 'opacity-100')}>
        <Button
          onClick={(e) => {
            e.stopPropagation()
            row.toggleExpanded(!row.getIsExpanded())
          }}
          iconOnly
          size='small'
          variant='muted'
          sentiment='neutral'
          disabled={node.disabled || !row.getCanExpand()}
        >
          <FilledChevronRightSolid
            className={twMerge(
              'text-semantic-neutral-text-disabled transition-all',
              row.getIsExpanded() && 'rotate-90'
            )}
          />
        </Button>
      </div>
      {renderIcon(node)}
      <div className='flex-grow flex truncate min-w-0'>{children}</div>
      {row.getIsSelected() && (
        <CheckOutline className='text-semantic-primary-icon-default w-4 h-4 shrink-0' />
      )}
    </TreeNodeShell>
  )
}
