import { type VariantProps } from '@motion/theme'

import { type DraggableAttributes } from '@dnd-kit/core'
import { type ComponentRef, forwardRef, type ReactNode } from 'react'

import { TabButton, TabLink, TabListItem } from './tab-styled'

import { type TabItem } from '../types'

export type TabProps = {
  active?: boolean
  disabled?: boolean
  onAction?: TabItem['onAction']
  url?: TabItem['url']
  children: ReactNode
  variant?: VariantProps<typeof TabButton>['variant']
  size?: VariantProps<typeof TabButton>['size']
  spacing?: VariantProps<typeof TabListItem>['spacing']
} & DndTabProps

export type DndTabProps = {
  dndStyle?: {
    transform: string | undefined
    transition: string | undefined
  }
  dndAttributes?: DraggableAttributes
  dndListeners?: any
  isDragging?: boolean
}

export const Tab = forwardRef<ComponentRef<typeof TabListItem>, TabProps>(
  function Tab(
    {
      url,
      onAction,
      children,
      active,
      disabled,
      variant,
      size,
      spacing,
      dndAttributes,
      dndListeners,
      dndStyle,
      isDragging,
    },
    ref
  ) {
    const handleOnClick = () => {
      if (disabled) return
      onAction?.()
    }

    const Component = url != null ? TabLink : TabButton

    return (
      <TabListItem
        ref={ref}
        as={Component}
        spacing={spacing}
        style={dndStyle}
        {...dndAttributes}
        {...dndListeners}
        disabled={disabled}
        url={disabled ? '#' : url}
        active={active}
        variant={variant}
        size={size}
        isDragging={isDragging}
        onClick={handleOnClick}
        role='tab'
      >
        {children}
      </TabListItem>
    )
  }
)
