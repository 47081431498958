import { type ColorId } from '@motion/ui-logic/calendar'

import { type CSSProperties, forwardRef, type Ref } from 'react'

import { InternalCheckbox, type InternalCheckboxProps } from '../../internal'
import { CalendarPalette } from '../palette'

export type CheckboxProps = InternalCheckboxProps & {
  colorId: ColorId
}

const style = {
  '--c-border':
    'hsl(var(--motion-palette-hue, 0) var(--motion-palette-border-default))',
  '--c-border-hover':
    'hsl(var(--motion-palette-hue, 0) var(--motion-palette-highlight-default))',
  '--c-bg': 'hsl(--form-bg-default)',
  '--c-border-checked':
    'hsl(var(--motion-palette-hue, 0) var(--motion-palette-highlight-default))',
  '--c-bg-checked':
    'hsl(var(--motion-palette-hue, 0) var(--motion-palette-highlight-default))',
  '--c-bg-checked-hover':
    'hsl(var(--motion-palette-hue, 0) var(--motion-palette-highlight-default))',
  '--c-border-checked-hover':
    'hsl(var(--motion-palette-hue, 0) var(--motion-palette-highlight-default))',
  '--c-icon': 'var(--motion-form-icon-active)',
} as CSSProperties

export const CalendarCheckbox = forwardRef(function Calendarcheckbox(
  props: CheckboxProps,
  ref: Ref<HTMLLabelElement>
) {
  const { colorId, ...rest } = props

  return (
    <CalendarPalette colorId={colorId}>
      <div style={style} className='contents'>
        <InternalCheckbox ref={ref} {...rest} />
      </div>
    </CalendarPalette>
  )
})
