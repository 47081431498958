import { useContext } from 'react'

import { TreeContext, type TreeContextValue } from './tree-context'

export const useTreeContext = (): TreeContextValue => {
  const context = useContext(TreeContext)
  if (!context) {
    throw new Error('useTreeContext must be used within a TreeContextProvider')
  }
  return context
}
