import { DateTime } from 'luxon'

/**
 * Generate a start date for the provided datetime, ensuring the resulting date
 * is converted to UTC so that local timezone adjustments doesn't move the date
 * forward or back one day.
 * @returns
 */
export const getStartOfInputDayUtc = (date: DateTime, timezone?: string) => {
  if (timezone) {
    date = date.setZone(timezone)
  }
  return date.setZone('utc', { keepLocalTime: true }).startOf('day').toJSDate()
}

/**
 * Generate a start date for `now`, ensuring the resulting date is converted
 * to UTC so that local timezone adjustments doesn't move the date forward or
 * back one day.
 * @returns
 */
export const getStartOfDayUtc = (timezone?: string) => {
  return getStartOfInputDayUtc(DateTime.now(), timezone)
}
