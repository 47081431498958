import { makeLog } from '@motion/web-base/logging'

type ChangeRecord<T> = {
  timestamp: number
  value: T
}

export function createChangeLog<T>(
  name: string,
  initialValue: T,
  maxValues = 6
) {
  const changes: ChangeRecord<T>[] = [{ timestamp: 0, value: initialValue }]
  const log = makeLog(`changelog.${name}`)
  log('initial', initialValue)

  return {
    get initialValue() {
      return initialValue
    },
    get current() {
      return changes[changes.length - 1]
    },
    get all() {
      return [...changes]
    },

    record(value: T, timestamp = performance.now()) {
      if (value === this.current.value) return

      changes.push({ timestamp, value })

      while (changes.length > maxValues) {
        changes.shift()
      }

      log('changed', value, timestamp)
    },

    valueAt(timestamp: number) {
      return changes.findLast((x) => x.timestamp <= timestamp)?.value
    },

    changesBetween(from: number, to: number) {
      const beforeIndex = changes.findLastIndex((x) => x.timestamp <= from)
      const currentIndex = changes.findIndex((x) => x.timestamp <= to)
      if (beforeIndex === -1 || currentIndex === -1) return []

      return changes.slice(beforeIndex, currentIndex + 1)
    },

    ifNoChanges(from: number, to: number): T | null {
      const history = this.changesBetween(from, to)
      if (history.length === 1) return history[0].value
      return null
    },
  }
}
