import { type COLOR, COLOR_HUES } from '@motion/shared/common'
import { type ColorClassId } from '@motion/ui-logic'
import { entries } from '@motion/utils/object'

import { GRAY_HUE } from './palette-provider'

export function getColorHueValue(color: COLOR | string) {
  return colorOptions[color as COLOR]?.hue ?? GRAY_HUE
}

export function getColorName(color: COLOR | string): string {
  return colorOptions[color as COLOR]?.name ?? ''
}

export function getColorFromColorClassId(colorClassId: ColorClassId): COLOR {
  return colorClassIdToColor.get(colorClassId) ?? 'gray'
}

export function getColorClassIdForColor(color: COLOR): ColorClassId | null {
  return colorOptions[color].colorClassId
}

type ColorValue = {
  hue: number
  name: string
  colorClassId: ColorClassId | null
}

export const colorOptions: Record<COLOR, ColorValue> = {
  red: { hue: COLOR_HUES['red'], name: 'Red', colorClassId: '5' },
  orange: { hue: COLOR_HUES['orange'], name: 'Orange', colorClassId: '11' },
  tangerine: {
    hue: COLOR_HUES['tangerine'],
    name: 'Tangerine',
    colorClassId: null,
  },
  yellow: { hue: COLOR_HUES['yellow'], name: 'Yellow', colorClassId: '7' },
  'highlighter-yellow': {
    hue: COLOR_HUES['highlighter-yellow'],
    name: 'Yellow Highlight',
    colorClassId: null,
  },
  lime: { hue: COLOR_HUES['lime'], name: 'Lime', colorClassId: null },
  mint: { hue: COLOR_HUES['mint'], name: 'Mint', colorClassId: '4' },
  green: { hue: COLOR_HUES['green'], name: 'Green', colorClassId: '8' },
  sage: { hue: COLOR_HUES['sage'], name: 'Sage', colorClassId: null },
  emerald: { hue: COLOR_HUES['emerald'], name: 'Emerald', colorClassId: '1' },
  cyan: { hue: COLOR_HUES['cyan'], name: 'Cyan', colorClassId: '3' },
  teal: { hue: COLOR_HUES['teal'], name: 'Teal', colorClassId: null },
  sky: { hue: COLOR_HUES['sky'], name: 'Sky', colorClassId: null },
  blue: { hue: COLOR_HUES['blue'], name: 'Blue', colorClassId: '0' },
  cobalt: { hue: COLOR_HUES['cobalt'], name: 'Cobalt', colorClassId: null },
  lavender: {
    hue: COLOR_HUES['lavender'],
    name: 'Lavender',
    colorClassId: '9',
  },
  purple: { hue: COLOR_HUES['purple'], name: 'Purple', colorClassId: null },
  violet: { hue: COLOR_HUES['violet'], name: 'Violet', colorClassId: '2' },
  grape: { hue: COLOR_HUES['grape'], name: 'Grape', colorClassId: null },
  pink: { hue: COLOR_HUES['pink'], name: 'Pink', colorClassId: '12' },
  raspberry: {
    hue: COLOR_HUES['raspberry'],
    name: 'Raspberry',
    colorClassId: null,
  },
  rose: { hue: COLOR_HUES['rose'], name: 'Rose', colorClassId: '10' },
  // Gray is treated specially for web and doesn't use COLOR_HUES
  gray: { hue: GRAY_HUE, name: 'Gray', colorClassId: '6' },
}

const colorClassIdToColor = new Map(
  entries(colorOptions).map(([color, { colorClassId }]) => [
    colorClassId,
    color,
  ])
)
