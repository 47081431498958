import { classed, type VariantProps } from '@motion/theme'

import { type ReactNode } from 'react'

export type StyledFieldProps = VariantProps<typeof StyledField> & {
  children?: ReactNode
}

export const StyledField = classed('span', {
  base: `
    flex items-center justify-between
    h-full
    gap-2 px-2
    border border-field-border-default
    bg-field-bg-default

    [&_[data-icon]]:text-field-icon-default
    [&_[data-icon]]:shrink-0
    `,
  variants: {
    variant: {
      default: `
        hover:border-field-border-hover
        active:border-field-border-active
        focus-within:border-field-border-focus
        hover:focus-within:border-field-border-focus
      `,
      muted: `
        bg-transparent
        border-transparent
    `,
      minimal: `
        bg-transparent border-transparent
        p-0
      `,
    },
    disabled: {
      true: `
        cursor-not-allowed
        bg-field-bg-disabled
        border-field-border-disabled
        text-field-text-disabled
        hover:border-field-border-disabled
        active:border-field-border-disabled
      `,
      false: 'cursor-text',
    },
    readOnly: {
      true: 'bg-transparent border-transparent',
      false: '',
    },
    sentiment: {
      default: '',
      active: `
        border-field-border-focus
        hover:border-field-border-focus
        active:border-field-border-focus
      `,
      error: `
        border-field-border-error
        hover:border-field-border-error
        active:border-field-border-error
      `,
    },
    size: {
      xsmall: `text-2xs py-0 px-0 [&_[data-icon]]:size-3 [&>[data-icon]]:mt-0.5`,
      small: `text-xs py-1 [&_[data-icon]]:size-3 [&>[data-icon]]:mt-0.5`,
      normal: `text-sm py-1.5 [&_[data-icon]]:size-[18px] [&>[data-icon]]:mt-px`,
      large: `text-lg leading-8 py-1.5 [&_[data-icon]]:size-[18px] [&>[data-icon]]:mt-px`,
    },
    rounded: {
      default: 'rounded',
      full: 'rounded-full',
    },
  },
  dataAttributes: ['size'],
  compoundVariants: [
    {
      variant: 'muted',
      disabled: true,
      class:
        'bg-transparent border-transparent hover:border-transparent active:border-transparent',
    },
    {
      variant: 'minimal',
      disabled: true,
      class: `
          bg-transparent
          border-transparent hover:border-transparent active:border-transparent
        `,
    },
    {
      variant: 'minimal',
      sentiment: 'error',
      class: `
        rounded-none
        border-transparent
        hover:border-transparent
        active:border-transparent
        border-b-field-border-error
        hover:border-b-field-border-error
        active:border-b-field-border-error
      `,
    },
  ],
  defaultVariants: {
    size: 'normal',
    disabled: false,
    rounded: 'default',
  },
})
