import { useClosure } from '@motion/react-core/hooks'
import { Sentry } from '@motion/web-base/sentry'

import { useEffect, useState } from 'react'

// Consider moving the types and non-hook functions into separate files,
// but for now it's so much simpler to just have them here.

declare global {
  interface WindowEventMap {
    'local-storage': CustomEvent
  }
}

export function readLocalStorageValue<
  K extends keyof LocalStorageTypes,
  T extends LocalStorageTypes[K],
>(key: K, initialValue?: T): T | null {
  try {
    const text = window.localStorage.getItem(key)
    return (text ? JSON.parse(text) : initialValue) ?? null
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Error reading localStorage key "${key}":`, error)
    return initialValue ?? null
  }
}

export function removeLocalStorageValue<K extends keyof LocalStorageTypes>(
  key: K
): boolean {
  const previous = window.localStorage.getItem(key)
  window.localStorage.removeItem(key)
  return previous != null
}

export interface LocalStorageTypes {}

export const useLocalStorage = <
  K extends keyof LocalStorageTypes,
  T extends LocalStorageTypes[K],
>(
  key: K,
  initialValue?: T
) => {
  const [storedValue, setStoredValue] = useState<T | null>(() =>
    readLocalStorageValue(key, initialValue)
  )

  const setValue = useClosure(
    (value: T | ((val: T | null) => T | null) | null) => {
      const newValue =
        typeof value === 'function'
          ? (value as (val: T | null) => T | null)(storedValue)
          : value
      try {
        if (newValue == null) {
          removeLocalStorageValue(key)
        } else {
          window.localStorage.setItem(key, JSON.stringify(newValue))
        }
        setStoredValue(newValue)
        window.dispatchEvent(new CustomEvent('local-storage', { detail: key }))
      } catch (error) {
        Sentry.captureException(error, {
          tags: { position: 'useLocalStorage' },
          extra: {
            currentKeys: Object.keys(window.localStorage),
          },
        })
      }
    }
  )

  useEffect(() => {
    const handleStorageChange = (event: CustomEvent | StorageEvent) => {
      const eventKey = event instanceof StorageEvent ? event.key : event.detail
      if (eventKey !== key) {
        return
      }
      setStoredValue(readLocalStorageValue(key, initialValue))
    }
    // this only works for other documents, not the current one
    window.addEventListener('storage', handleStorageChange)
    window.addEventListener('local-storage', handleStorageChange)
    return () => {
      window.removeEventListener('storage', handleStorageChange)
      window.removeEventListener('local-storage', handleStorageChange)
    }
  }, [key, initialValue])

  return [storedValue, setValue] as const
}
