import { LoadingOutline } from '@motion/icons'
import { addComponentName } from '@motion/ui/helpers'

import { twMerge } from 'tailwind-merge'

import { useSyncAnimation } from '../../animation'

export type LoadingSpinnerProps = {
  size?: number
  color?: string
  className?: string
}

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const ref = useSyncAnimation<SVGSVGElement>('static-loading-spin')
  const { size = 30 } = props

  return (
    <LoadingOutline
      width={size}
      height={size}
      ref={ref}
      className={twMerge(
        'spin',
        props.className,
        'text-semantic-neutral-icon-default'
      )}
      style={{
        // @ts-expect-error - overriding color
        '--loading-fg-title':
          props.color ?? 'var(--motion-semantic-neutral-icon-default)',
      }}
      {...addComponentName('LoadingSpinner')}
    />
  )
}
