import { forwardRef } from 'react'

import { type LinkLikeComponentProps, useLink } from '../../providers'

export type LinkProps = LinkLikeComponentProps

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  function Link(props, ref) {
    const LinkComponent = useLink()

    if (LinkComponent) {
      return <LinkComponent ref={ref as any} {...props} />
    }

    const { url, external, ...rest } = props

    const target = external ? '_blank' : undefined
    const rel = external ? 'noopener noreferrer' : undefined

    return <a ref={ref} {...rest} href={url} target={target} rel={rel} />
  }
)
