import { useEffect, useState } from 'react'

import { useClosure } from './use-closure'

export const useOnDomEvent = <
  T extends HTMLElement,
  E extends keyof HTMLElementEventMap,
>(
  event: E,
  handler: (event: HTMLElementEventMap[E]) => void
) => {
  const stable = useClosure(handler)
  const [el, setEl] = useState<T | null>(null)

  useEffect(() => {
    if (el == null) return

    el.addEventListener(event, stable)
    return () => el.removeEventListener(event, stable)
  }, [el, event, stable])

  return setEl
}
