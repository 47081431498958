import {
  type ComponentProps,
  forwardRef,
  type MutableRefObject,
  useLayoutEffect,
  useRef,
} from 'react'

type GrowOnlyDivProps = ComponentProps<'div'> & {
  calculateWhenChange?: unknown
}

export const GrowOnlyDiv = forwardRef(function GrowOnlyDiv(
  props: GrowOnlyDivProps,
  ref
) {
  const localRef = useRef<HTMLDivElement | null>(null)
  const containerRef = (ref ??
    localRef) as MutableRefObject<HTMLDivElement | null>

  const { calculateWhenChange, ...domProps } = props

  useLayoutEffect(() => {
    // Once the div increases in width, don't let it shrink back down when the filtered items change, to prevent layout shifts.
    if (!containerRef.current) return
    const contentWidth = containerRef.current.offsetWidth
    containerRef.current.style.minWidth = `${contentWidth}px`
  }, [containerRef, props.calculateWhenChange])

  return <div ref={containerRef} {...domProps} />
})
