import { DeadlineStatuses } from '@motion/shared/common'

export const ExtendedDeadlineStatus = [
  ...DeadlineStatuses,
  'completed',
  'cancelled',
] as const

export type ExtendedDeadlineStatus = (typeof ExtendedDeadlineStatus)[number]

export const etaLabels = new Map<
  ExtendedDeadlineStatus,
  (type?: 'project' | 'stage' | 'task') => string
>([
  ['none', () => 'No ETA'],
  [
    'ahead-of-schedule',
    (type) => {
      switch (type) {
        case 'project':
          return 'Project is ahead of schedule'
        case 'stage':
          return 'Stage is ahead of schedule'
        case 'task':
          return 'Task is ahead of schedule'
        default:
          return 'Ahead of schedule'
      }
    },
  ],
  [
    'on-track',
    (type) => {
      switch (type) {
        case 'project':
          return 'Project is on track'
        case 'stage':
          return 'Stage is on track'
        case 'task':
          return 'Task is on track'
        default:
          return 'On track'
      }
    },
  ],
  // Need to add specific at-risk cases for project stages, etc.
  ['at-risk', () => 'Warnings'],
  [
    'scheduled-past-deadline',
    (type) => {
      switch (type) {
        case 'project':
          return 'Project is scheduled past deadline'
        case 'stage':
          return 'Scheduled past deadline'
        case 'task':
          return 'Task is scheduled past deadline'
        default:
          return 'Scheduled past deadline'
      }
    },
  ],
  [
    'missed-deadline',
    (type) => {
      switch (type) {
        case 'stage':
          return 'Stage missed deadline'
        default:
          return 'Missed deadline'
      }
    },
  ],
  [
    'cancelled',
    (type) => {
      switch (type) {
        case 'project':
          return 'Project is cancelled'
        case 'stage':
          return 'Stage is cancelled'
        case 'task':
          return 'Task is cancelled'
        default:
          return 'Cancelled'
      }
    },
  ],
  [
    'completed',
    (type) => {
      switch (type) {
        case 'project':
          return 'Project is completed'
        case 'stage':
          return 'Stage is completed'
        case 'task':
          return 'Task is completed'
        default:
          return 'Completed'
      }
    },
  ],
])

export const getEtaLabel = (
  status: ExtendedDeadlineStatus,
  type?: 'project' | 'stage' | 'task'
) => {
  return etaLabels.get(status)?.(type) ?? etaLabels.get('none')?.(type) ?? ''
}
