import { useContext } from 'react'

import {
  TreeKeyboardContext,
  type TreeKeyboardContextValue,
} from './tree-keyboard-context'

export const useTreeKeyboardContext = (): TreeKeyboardContextValue => {
  const context = useContext(TreeKeyboardContext)
  if (!context) {
    return {
      activeRow: null,
      focusedIndex: { index: 0, scroll: false },
      setActiveRow: () => null,
      resetFocusedIndex: () => null,
    }
  }
  return context
}
