import { DragSolid } from '@motion/icons'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { twMerge } from 'tailwind-merge'

type SortableRowProps = {
  id: string
  children: React.ReactNode
  renderDraggableRowSection: () => React.ReactNode
  rowClassName?: string
}

export const SortableRow = ({
  id,
  children,
  renderDraggableRowSection,
  rowClassName,
}: SortableRowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      className={twMerge(
        'flex items-center justify-between w-full',
        rowClassName
      )}
      style={style}
    >
      <button
        ref={setActivatorNodeRef}
        {...listeners}
        className='cursor-grab flex items-center'
      >
        <DragSolid className='shrink-0 size-4 text-semantic-neutral-icon-subtle' />
        {renderDraggableRowSection()}
      </button>

      {children}
    </div>
  )
}
