import { AppleLogo, GoogleLogo, MicrosoftLogo } from '@motion/icons'

export type CompanyLogoProps = {
  provider: 'APPLE' | 'GOOGLE' | 'MICROSOFT'
}

export const CompanyLogo = (props: CompanyLogoProps) => {
  const { provider } = props

  if (provider === 'APPLE') {
    return <AppleLogo className='p-[1.5px]' />
  }

  if (provider === 'GOOGLE') {
    return <GoogleLogo className='p-[1.5px]' />
  }

  if (provider === 'MICROSOFT') {
    return <MicrosoftLogo className='p-0.5' />
  }

  return null
}
