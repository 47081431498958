import { useOnce } from '@motion/react-core/hooks'
import { addComponentName } from '@motion/ui/helpers'

import { useVirtualizer } from '@tanstack/react-virtual'
import { useLayoutEffect, useRef } from 'react'

import { useTreeKeyboardContext } from './tree-keyboard-context'
import { type VirtualizedTreeNode } from './types'
import { useTreeContext } from './virtualized-tree-context'

type TreeNodeListProps = { estimateSize: (node: VirtualizedTreeNode) => number }
export const TreeNodeList = (props: TreeNodeListProps) => {
  const { estimateSize } = props
  const { flatTree, selectedId, renderNode } = useTreeContext()
  const { focusedIndex } = useTreeKeyboardContext()
  const scrollerRef = useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: flatTree.length,
    getScrollElement: () => scrollerRef.current,
    estimateSize: (index) => estimateSize(flatTree[index].original),
    overscan: 2,
    paddingStart: 4,
    paddingEnd: 4,
    scrollPaddingStart: 4,
    scrollPaddingEnd: 4,
  })

  useOnce(() => {
    const selectedIndex = flatTree.findIndex(
      (n) => n.original.id === selectedId
    )
    if (selectedIndex < 0 || flatTree.length <= selectedIndex) return
    rowVirtualizer.scrollToIndex(selectedIndex, {
      align: 'center',
    })
  })

  useLayoutEffect(() => {
    if (!focusedIndex.scroll || flatTree.length < 1) return
    rowVirtualizer.scrollToIndex(focusedIndex.index, {
      align: 'auto',
    })
  }, [flatTree.length, focusedIndex, rowVirtualizer])

  return (
    <div
      ref={scrollerRef}
      className='scrollbar-none overflow-y-auto overflow-x-hidden w-full px-1'
      {...addComponentName('TreeNodeList')}
    >
      <div
        className='relative w-full'
        style={{
          height: rowVirtualizer.getTotalSize(),
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const node = flatTree[virtualRow.index]

          return (
            <div
              key={node.id}
              className='absolute top-0 left-0 right-0'
              style={{
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {renderNode(node)}
            </div>
          )
        })}
      </div>
    </div>
  )
}
