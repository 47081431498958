export type VisibleAndHiddenIndicesProps = {
  containerWidth: number
  itemWidths: number[]
  selectedIndex: number
  activatorWidth: number
  keepOrder?: boolean
}

export function getVisibleAndHiddenIndices({
  containerWidth,
  itemWidths,
  selectedIndex,
  activatorWidth,
  keepOrder = false,
}: VisibleAndHiddenIndicesProps) {
  const { sumItemWidths, itemIndices } = itemWidths.reduce(
    (acc, width, index) => {
      acc.sumItemWidths += width
      acc.itemIndices.push(index)
      return acc
    },
    { sumItemWidths: 0, itemIndices: [] as number[] }
  )

  const visibleIndices: number[] = []
  const hiddenIndices: number[] = []
  const maxWidth = containerWidth - activatorWidth
  const isWithinBounds = selectedIndex >= 0 && selectedIndex < itemWidths.length

  if (containerWidth > sumItemWidths) {
    visibleIndices.push(...itemIndices)
  } else {
    let itemListWidth = 0

    if (!keepOrder && isWithinBounds) {
      visibleIndices.push(selectedIndex)
      itemListWidth += itemWidths[selectedIndex]
    }

    for (let currentIndex of itemIndices) {
      if (!keepOrder && currentIndex === selectedIndex) continue

      const currentItemWidth = itemWidths[currentIndex]

      if (itemListWidth + currentItemWidth >= maxWidth) {
        if (keepOrder && isWithinBounds) {
          const selectedIndexIsVisible = visibleIndices.includes(selectedIndex)

          if (selectedIndexIsVisible) {
            hiddenIndices.push(...itemIndices.slice(currentIndex))
          } else {
            const selectedItemWidth = itemWidths[selectedIndex]
            while (
              itemListWidth + selectedItemWidth >= maxWidth &&
              visibleIndices.length > 0
            ) {
              const lastVisibleIndex = visibleIndices.pop() as number
              itemListWidth -= itemWidths[lastVisibleIndex]
              hiddenIndices.push(lastVisibleIndex)
            }

            visibleIndices.push(selectedIndex)
            itemListWidth += selectedItemWidth
            hiddenIndices.push(
              ...itemIndices
                .filter((index) => index !== selectedIndex)
                .slice(currentIndex)
            )
          }

          break
        } else {
          hiddenIndices.push(currentIndex)
          continue
        }
      }

      visibleIndices.push(currentIndex)
      itemListWidth += currentItemWidth
    }
  }

  return {
    visibleIndices,
    hiddenIndices,
  }
}
