import {
  INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY,
  individualBullets,
  individualElevatorPitch,
} from '@motion/ui-logic/billing'

import {
  BaseBillingPlanCard,
  type BaseBillingPlanCardProps,
} from './components'

type IndividualBillingPlanCardProps = Pick<
  BaseBillingPlanCardProps,
  'onClick' | 'buttonText' | 'hidePrice' | 'isSecondary' | 'withBorder'
>

export const IndividualBillingPlanCard = ({
  onClick,
  buttonText,
  hidePrice,
  isSecondary,
  withBorder,
}: IndividualBillingPlanCardProps) => {
  return (
    <BaseBillingPlanCard
      isTeam={false}
      subtitle={individualElevatorPitch}
      items={individualBullets}
      onClick={onClick}
      price={INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY}
      buttonText={!buttonText ? 'Try Motion for free' : buttonText}
      planType='Individual'
      hidePrice={hidePrice}
      isSecondary={isSecondary}
      withBorder={withBorder}
    />
  )
}
