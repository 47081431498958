import { ChevronLeftSolid, ChevronRightSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { addComponentName } from '@motion/ui/helpers'
import { type CalendarStartDay } from '@motion/ui-logic/calendar'

import { DateTime } from 'luxon'
import {
  Fragment,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react'

import { type DateRangeColorData } from './types'
import { Day, useDatePicker } from './use-date-picker'
import { dateToDateStringKey, isNewDatePartOfExistingDateRange } from './utils'

import { ProjectPalette } from '../../project'
import { Button } from '../button'
import { IconStack } from '../icon-stack'
import { Tooltip } from '../tooltip'

type DatePickerMode = 'multiple' | 'range' | 'single'
type Variant = 'default' | 'highlight'
type Size = 'small' | 'normal'
export interface DatePickerProps {
  value: string[] | string | null
  mode?: DatePickerMode
  weekStartDay?: CalendarStartDay
  onChange: (value: string[] | string | null) => void
  onNextMonth?: () => void
  onPreviousMonth?: () => void
  onToday?: () => void
  disabledDate?: (date: DateTime) => boolean
  disabledDateTooltipContent?: ReactNode
  variant?: Variant
  size?: Size
  highlightSelectedWeek?: boolean
  showTodayButton?: boolean
  dateRangeColors?: DateRangeColorData[]
  showDayNumberSubtext?: boolean
  showDayNumberSupertext?: boolean
}
export const DatePicker = ({
  value,
  onChange,
  onNextMonth,
  onPreviousMonth,
  onToday,
  mode = 'single',
  weekStartDay = 'sunday',
  disabledDate = () => false,
  disabledDateTooltipContent,
  variant = 'default',
  size = 'normal',
  highlightSelectedWeek = false,
  showTodayButton = false,
  dateRangeColors,
  showDayNumberSubtext = false,
  showDayNumberSupertext = false,
}: DatePickerProps) => {
  const {
    calendar,
    select,
    isSelected,
    deselect,
    viewPreviousMonth,
    viewNextMonth,
    viewToday,
    viewing,
    setViewing,
    clearSelected,
    dateStringToColor,
  } = useDatePicker({
    weekStartsOn: weekStartDay === 'sunday' ? Day.SUNDAY : Day.MONDAY,
    viewing: parseViewingDate(value),
    selected: getSelectedDates(value),
    dateRangeColors,
  })

  const weekDays = useMemo(() => {
    const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
    if (weekStartDay === 'monday') {
      const first = days.shift()
      if (first) {
        days.push(first)
      }
      return days
    }

    return days
  }, [weekStartDay])

  useEffect(() => {
    clearSelected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  useEffect(() => {
    if (!value) {
      clearSelected()
      setViewing(DateTime.now())
      return
    }

    if (!Array.isArray(value) || mode === 'single') {
      const date = DateTime.fromISO(Array.isArray(value) ? value[0] : value)
      select(date, true)
      setViewing(date)
    } else if (Array.isArray(value) && mode === 'range') {
      clearSelected()

      let startDate, endDate
      if (value[0]) {
        startDate = DateTime.fromISO(value[0])
        select(startDate, false)
      }

      if (value[1]) {
        endDate = DateTime.fromISO(value[1])
        select(endDate, false)
      }

      if (endDate) {
        setViewing(endDate)
      } else if (startDate) {
        setViewing(startDate)
      }
    } else if (Array.isArray(value) && mode === 'multiple') {
      clearSelected()

      const dates = value.map((val) => DateTime.fromISO(val))
      dates.forEach((date) => select(date, false))
      if (dates.filter((date) => !!date).length > 0) {
        setViewing(dates[dates.length - 1])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const handleDaySelect = (day: DateTime) => {
    if (!day) return
    if (mode === 'single') {
      select(day, true)
      onChange(day.toISO())
    } else if (mode === 'range') {
      // If the start and end date are the same, the newly selected date becomes
      // the end date of the range
      if (
        value &&
        Array.isArray(value) &&
        isNewDatePartOfExistingDateRange(value, day)
      ) {
        select(day, false)
        onChange([value[0], day.toISO()])
        return
      }

      select(day, true)
      onChange([day.toISO(), day.toISO()])
    } else if (mode === 'multiple') {
      if (isSelected(day)) {
        deselect(day)
        if (Array.isArray(value)) {
          onChange(value.filter((val) => val !== day.toISO()))
        } else {
          onChange([])
        }
      } else {
        select(day, false)
        onChange([...(value || []), day.toISO()])
      }
    }
  }

  const getColorDataForDay = useCallback(
    (day: DateTime) => {
      if (day && Array.isArray(dateRangeColors) && dateRangeColors.length > 0) {
        const dateStringKey = dateToDateStringKey(day)
        const color = dateStringToColor.get(dateStringKey)
        const isEndDate = dateRangeColors.some(
          (range) => dateToDateStringKey(range.endDate) === dateStringKey
        )
        return { color, isEndDate }
      }

      return { color: undefined, isEndDate: false }
    },
    [dateRangeColors, dateStringToColor]
  )

  const getTooltipContentForEndDate = useCallback(
    (day: DateTime) => {
      if (day && Array.isArray(dateRangeColors) && dateRangeColors.length > 0) {
        const dateStringKey = dateToDateStringKey(day)
        const tooltipContents = dateRangeColors
          .filter(
            (range) => dateToDateStringKey(range.endDate) === dateStringKey
          )
          ?.map((range) => range.metadata?.endDateTooltipContent)

        return tooltipContents
      }

      return undefined
    },
    [dateRangeColors]
  )

  const getBadgeIconsForEndDate = useCallback(
    (day: DateTime): ReactNode[] | undefined => {
      if (day && Array.isArray(dateRangeColors) && dateRangeColors.length > 0) {
        const dateStringKey = dateToDateStringKey(day)
        const badgeIcons = dateRangeColors
          .filter(
            (range) => dateToDateStringKey(range.endDate) === dateStringKey
          )
          ?.map((range) => range.metadata?.endDateBadgeIcon)

        return badgeIcons
      }

      return undefined
    },
    [dateRangeColors]
  )

  const barredRange = useMemo(() => {
    if (Array.isArray(dateRangeColors) && dateRangeColors.length > 0) {
      return {
        startDate: DateTime.min(
          ...dateRangeColors.map((range) => range.startDate.startOf('day'))
        ),
        endDate: DateTime.max(
          ...dateRangeColors.map((range) => range.endDate.startOf('day'))
        ),
      }
    }
    return null
  }, [dateRangeColors])

  // Given a date and mode is range,
  const isDayInRange = (day: DateTime) => {
    if (
      mode === 'range' &&
      day &&
      Array.isArray(value) &&
      value.filter((date) => !!date).length === 2
    ) {
      const value0StartDay = DateTime.fromISO(value[0]).startOf('day')
      const value1StartDay = DateTime.fromISO(value[1]).startOf('day')

      if (
        value0StartDay <= day &&
        value1StartDay >= day &&
        !value0StartDay.equals(value1StartDay)
      ) {
        return {
          isInRange: true,
          isFirst: day.equals(value0StartDay),
          isLast: day.equals(value1StartDay),
        }
      }
    }

    return {
      isInRange: false,
      isFirst: false,
      isLast: false,
    }
  }

  const renderedTitle = (
    <Title size={size}>
      <span className='font-semibold'>{viewing.toFormat('LLLL')}</span>{' '}
      {viewing.toFormat('yyyy')}
    </Title>
  )

  const renderedPrevButton = (
    <Button
      iconOnly
      sentiment='neutral'
      variant='muted'
      size='small'
      onClick={() => {
        viewPreviousMonth()
        onPreviousMonth?.()
      }}
    >
      <ChevronLeftSolid />
    </Button>
  )

  const renderedNextButton = (
    <Button
      iconOnly
      sentiment='neutral'
      variant='muted'
      size='small'
      onClick={() => {
        viewNextMonth()
        onNextMonth?.()
      }}
    >
      <ChevronRightSolid />
    </Button>
  )

  return (
    <div className='w-fit' {...addComponentName('DatePicker')}>
      <Header size={size} hasTodayButton={showTodayButton}>
        {showTodayButton ? (
          <>
            {renderedTitle}

            <div className='inline-flex ml-auto mr-1'>
              <Button
                sentiment='neutral'
                size='xsmall'
                onClick={() => {
                  viewToday()
                  onToday?.()
                }}
              >
                Today
              </Button>
            </div>

            <div className='inline-flex [&_button]:p-px'>
              {renderedPrevButton}
              {renderedNextButton}
            </div>
          </>
        ) : (
          <>
            {renderedPrevButton}
            {renderedTitle}
            {renderedNextButton}
          </>
        )}
      </Header>

      <WeekDayRow size={size}>
        {weekDays.map((weekday) => (
          <WeekDay key={weekday} size={size}>
            {weekday}
          </WeekDay>
        ))}
      </WeekDayRow>

      {calendar.map((month) => (
        <Fragment key={month[0][0].toString()}>
          {month.map((week) => {
            const isDayWithinWeek = highlightSelectedWeek
              ? week.some(isSelected)
              : false

            return (
              <WeekRow
                key={`${month[0][0].toString()}-${week[0]}`}
                isSelected={isDayWithinWeek}
              >
                {week.map((day) => {
                  const dayIsSelected = isSelected(day)
                  const isDisabled = disabledDate(day)
                  const {
                    isInRange,
                    isFirst: isFirstDayInRange,
                    isLast: isLastDayInRange,
                  } = isDayInRange(day)

                  const isToday = DateTime.now().hasSame(day, 'day')

                  const hasLeftBar =
                    barredRange?.startDate.startOf('day').equals(day) ?? false
                  const hasRightBar =
                    barredRange?.endDate.startOf('day').equals(day) ?? false

                  const { color, isEndDate } = getColorDataForDay(day)
                  const endBadgeIcons = getBadgeIconsForEndDate(day)

                  const tooltipContentParts: (string | ReactNode)[] = []
                  if (hasLeftBar) {
                    tooltipContentParts.push('Project start date')
                  }

                  if (hasRightBar) {
                    tooltipContentParts.push('Project deadline')
                  }

                  if (isEndDate) {
                    const tooltipContent = getTooltipContentForEndDate(day)
                    if (tooltipContent != null && tooltipContent.length > 0) {
                      tooltipContentParts.push(...tooltipContent)
                    }
                  }

                  const getTooltipRenderContent = () => {
                    if (isDisabled && disabledDateTooltipContent) {
                      return () => disabledDateTooltipContent
                    }
                    if (tooltipContentParts.length > 0) {
                      return () => (
                        <div className='flex flex-col gap-1'>
                          {tooltipContentParts.map((part, index) => (
                            <div key={`tooltip-${index}`} className='text-xs'>
                              {part}
                            </div>
                          ))}
                        </div>
                      )
                    }

                    return undefined
                  }

                  const getDayNumberSubtextContent = () => {
                    if (endBadgeIcons != null && endBadgeIcons?.length > 0) {
                      return (
                        <IconStack icons={endBadgeIcons} iconSize='xsmall' />
                      )
                    }
                  }

                  const getDayNumberSupertextContent = () => {
                    if (isToday) {
                      return (
                        <TodayTextLabel isSelected={dayIsSelected} size={size}>
                          TODAY
                        </TodayTextLabel>
                      )
                    }
                  }

                  let content = (
                    <DayNumberWrapper
                      key={day.toString()}
                      inRange={isInRange}
                      isFirstDayInRange={isFirstDayInRange}
                      isLastDayInRange={isLastDayInRange}
                      disabled={isDisabled}
                      isColored={color != null}
                      hasLeftBar={hasLeftBar}
                      hasRightBar={hasRightBar}
                    >
                      <Tooltip
                        asChild
                        renderContent={getTooltipRenderContent()}
                      >
                        <DayNumberButton
                          disabled={isDisabled}
                          variant={variant}
                          size={size}
                          isSelected={
                            dayIsSelected ||
                            isFirstDayInRange ||
                            isLastDayInRange
                          }
                          isSameMonth={day.month === viewing.month}
                          isToday={isToday && !isInRange}
                          isEndDate={isEndDate}
                          hasLeftBar={hasLeftBar}
                          hasRightBar={hasRightBar}
                          onClick={() => {
                            if (!isDisabled) {
                              handleDaySelect(day)
                            }
                          }}
                        >
                          {showDayNumberSupertext && (
                            <DayNumberSupertext size={size}>
                              {getDayNumberSupertextContent()}
                            </DayNumberSupertext>
                          )}
                          {day.toFormat('d')}
                          {showDayNumberSubtext && (
                            <DayNumberSubtext size={size}>
                              {getDayNumberSubtextContent()}
                            </DayNumberSubtext>
                          )}
                        </DayNumberButton>
                      </Tooltip>
                    </DayNumberWrapper>
                  )

                  if (color != null) {
                    content = (
                      <ProjectPalette
                        color={color}
                        key={`palette-${day.toISO()}`}
                      >
                        {content}
                      </ProjectPalette>
                    )
                  }

                  return content
                })}
              </WeekRow>
            )
          })}
        </Fragment>
      ))}
    </div>
  )
}

function parseViewingDate(value: DatePickerProps['value']) {
  const date = Array.isArray(value) ? value[0] : value

  if (!date) return DateTime.now()

  return DateTime.fromISO(date)
}

function getSelectedDates(value: string[] | string | null) {
  if (value) {
    return Array.isArray(value)
      ? value.filter(Boolean).map((val) => DateTime.fromISO(val))
      : [DateTime.fromISO(value)]
  }

  return []
}

const Header = classed('div', {
  base: 'flex items-center',
  variants: {
    size: {
      small: '',
      normal: '',
    },
    hasTodayButton: {
      true: 'pl-1.5 justify-start min-h-6',
      false: 'justify-between',
    },
  },
  compoundVariants: [
    {
      hasTodayButton: true,
      size: 'normal',
      class: 'pl-2.5 pr-0.5',
    },
  ],
})

const Title = classed('span', {
  base: `text-semantic-neutral-text-default`,
  variants: {
    size: {
      small: 'text-xs',
      normal: 'text-sm',
    },
  },
})

const WeekDayRow = classed('div', {
  base: `
    text-xs
    text-semantic-neutral-text-disabled
    text-center
    flex
  `,
  variants: { size: { small: ' py-1.5', normal: ' py-2' } },
})

const WeekRow = classed('div', {
  base: 'my-0.5 flex',
  variants: {
    isSelected: {
      true: 'rounded bg-semantic-neutral-bg-subtle',
    },
  },
})

const WeekDay = classed('div', {
  base: 'font-semibold',
  variants: {
    size: {
      small: 'w-7 text-[11px]',
      normal: 'w-9 text-sm',
    },
  },
})

const DayNumberWrapper = classed('div', {
  base: 'flex',
  variants: {
    inRange: {
      true: '',
    },
    isFirstDayInRange: {
      true: 'bg-gradient-to-r from-transparent to-datepicker-item-bg-range',
    },
    isLastDayInRange: {
      true: 'bg-gradient-to-l from-transparent to-datepicker-item-bg-range',
    },
    variant: {
      default: '',
      highlight: '',
    },
    disabled: {
      true: '',
      false: '',
    },
    isColored: {
      true: 'bg-palette-bg-lightest',
    },
    hasLeftBar: {
      true: 'border-l-[2px] ml-0 border-semantic-neutral-icon-strong',
    },
    hasRightBar: {
      true: 'border-r-[2px] mr-0 border-semantic-neutral-icon-strong',
    },
  },
  compoundVariants: [
    {
      inRange: true,
      isFirstDayInRange: false,
      isLastDayInRange: false,
      class: 'bg-datepicker-item-bg-range',
    },
  ],
})

const DayNumberButton = classed('button', {
  base: `
    mx-0.5
    inline-block
    font-medium
    rounded
    border border-transparent
    relative

    hover:border-datepicker-item-border-hover

    focus-visible:outline-0
    focus-visible:transition-shadow
    focus-visible:ring-2
    focus-visible:ring-offset-datepicker-item-border-focus
    focus-visible:ring-datepicker-item-border-focus
  `,
  variants: {
    disabled: {
      true: 'cursor-not-allowed opacity-50',
      false: 'group cursor-pointer',
    },
    isSelected: {
      true: 'text-semantic-neutral-text-inverse bg-semantic-neutral-icon-strong',
      false: 'text-semantic-primary-text-strong',
    },
    isSameMonth: { true: '' },
    isToday: {
      true: 'semantic-neutral-text-default font-[860]',
    },
    variant: { highlight: '', default: '' },
    size: {
      small: 'w-6 h-6 text-xs',
      normal: 'w-8 h-8 text-sm',
    },
    isEndDate: {
      true: 'text-palette-highlight-default',
    },
    hasLeftBar: {
      true: 'ml-0',
    },
    hasRightBar: {
      true: 'mr-0',
    },
  },
  compoundVariants: [
    {
      disabled: true,
      isSelected: false,
      isToday: false,
      class: 'text-datepicker-item-text-muted',
    },
    {
      isSameMonth: false,
      isSelected: false,
      isToday: false,
      class: 'text-datepicker-item-text-muted',
    },
    {
      variant: 'highlight',
      disabled: false,
      isSelected: false,
      class: 'bg-datepicker-item-bg-range',
    },
    {
      isEndDate: true,
      isSelected: true,
      class:
        'text-semantic-neutral-text-inverse bg-semantic-neutral-icon-strong',
    },
  ],
})

const DayNumberSubtext = classed('span', {
  base: 'absolute left-1/2 -translate-x-1/2 leading-[0]',
  variants: {
    size: {
      small: 'bottom-[3px]',
      normal: 'bottom-0',
    },
  },
})

const DayNumberSupertext = classed('span', {
  base: 'absolute left-1/2 -translate-x-1/2 leading-[0]',
  variants: {
    size: {
      small: 'top-px',
      normal: 'top-0.5',
    },
  },
})

const TodayTextLabel = classed('span', {
  base: 'text-semantic-error-text-default font-bold',
  variants: {
    isSelected: {
      true: 'text-semantic-error-text-subtle',
    },
    size: {
      small: 'text-[4px]',
      normal: 'text-[6px]',
    },
  },
})
