// Needed for mobile
export * from './src/__generated__/dto'

// Manual types
export const IndividualOrTeam = {
  TEAM: 'TEAM',
  INDIVIDUAL: 'INDIVIDUAL',
} as const

export type IndividualOrTeam =
  (typeof IndividualOrTeam)[keyof typeof IndividualOrTeam]
