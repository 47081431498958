import {
  type SocketEventData,
  type SocketEventType,
} from '@motion/shared/websockets'
import { logInDev } from '@motion/web-base/logging'

import EventEmitter from 'events'

export class WebsocketsEventSubscriber {
  ee: EventEmitter

  constructor() {
    this.ee = new EventEmitter()
  }

  subscribe<E extends SocketEventType>(
    event: E,
    callback: (args: SocketEventData<E>) => void
  ) {
    this.ee.addListener(event, callback)
    return this
  }

  on<E extends SocketEventType>(
    event: E,
    callback: (args: SocketEventData<E>, event: E) => void
  ): () => void {
    this.ee.addListener(event, callback)
    return () => this.ee.removeListener(event, callback)
  }

  handle(event: string, args: any[]) {
    logInDev(`Received websockets message ${event}`, args)
    this.ee.listeners(event).forEach((cb) => {
      cb(args, event)
    })
  }
}

export const websocketsEventSubscriber = new WebsocketsEventSubscriber()
