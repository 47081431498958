import { type SvgIcon } from '@motion/icons'

import { forwardRef } from 'react'

import { Button, type ButtonProps } from './button'

export type IconButtonProps = ButtonProps & {
  icon: SvgIcon
  // We don't want to allow children on icon buttons
  withIcon?: never
  children?: never
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(props, ref) {
    const { icon: Icon, ...rest } = props

    return (
      <Button ref={ref} iconOnly {...rest}>
        <Icon />
      </Button>
    )
  }
)
