import { addComponentName } from '@motion/ui/helpers'

import { twMerge } from 'tailwind-merge'

type ColumnRender<T> = T extends object ? T : never
interface ColumnProp<T> {
  title?: string
  dataIndex?: string
  key: string
  className?: string
  render?: (renderProps: ColumnRender<T>) => React.ReactNode
}

interface TableProps<T> {
  columns: ColumnProp<T>[]
  dataSource: T[]
}

export const Table = <T extends Record<string, any>>({
  columns = [],
  dataSource = [],
}: TableProps<T>) => {
  return (
    <table className='w-full' {...addComponentName('Table')}>
      <thead>
        {columns.map((column) => (
          <th key={column.key} className={twMerge('pb-3', column.className)}>
            <p className='text-semantic-neutral-text-subtle truncate text-[11px] font-medium text-start'>
              {column.title}
            </p>
          </th>
        ))}
      </thead>

      <tbody>
        {dataSource.map((row) => (
          <tr
            key={row.key}
            className='border-semantic-neutral-border-subtle border-b'
          >
            {columns.map((column) => (
              <td
                key={column.key}
                className={twMerge('py-2 px-1', column.className)}
              >
                {column.render ? (
                  column.render({ ...row } as ColumnRender<T>)
                ) : (
                  <p className='text-semantic-neutral-text-default text-sm'>
                    {column.dataIndex && row[column.dataIndex]}
                  </p>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
