import { Primitive } from '../types/primitive'

type isArrayEqualOptions = {
  /**
   * The order of the elements in the arrays does not matter
   */
  unordered: boolean
}

/**
 * Compare two arrays for equality, note that T
 * @param arr1
 * @param arr2
 * @param param2
 * @returns
 */
export function isArrayEqual<T extends Primitive>(
  arr1: T[],
  arr2: T[],
  { unordered }: isArrayEqualOptions = { unordered: false }
): boolean {
  if (arr1.length !== arr2.length) return false

  if (!unordered) {
    return arr1.every((value, index) => value === arr2[index])
  }

  return arr1.every(
    (item) =>
      arr1.filter((x) => x === item).length ===
      arr2.filter((x) => x === item).length
  )
}
