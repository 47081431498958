import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useClosure } from './use-closure'

export type BatchAppendOptions = {
  flush?: boolean
}

export type Batch<T> = {
  append(item: T, options?: BatchAppendOptions): void
}

export function useBatch<T>(cb: (items: T[]) => void, batchTime = 1): Batch<T> {
  const stableCallback = useClosure(cb)

  const items = useRef<T[]>([])
  const timer = useRef<number | null>(null)
  const [signal, setSignal] = useState<object>(() => Object.create(null))

  const flush = useCallback(() => {
    if (items.current.length === 0) return

    const local = items.current
    items.current = []

    stableCallback(local)
  }, [stableCallback])

  useEffect(() => {
    flush()
  }, [flush, signal])

  return useMemo(() => {
    return {
      append(item: T, options: BatchAppendOptions = { flush: false }) {
        if (batchTime === 0) {
          return stableCallback([item])
        }

        items.current = [...items.current, item]

        if (options.flush) {
          if (timer.current) {
            clearTimeout(timer.current)
            timer.current = null
          }
          return flush()
        }

        if (timer.current) return
        timer.current = setTimeout(() => {
          timer.current = null
          setSignal(Object.create(null))
        }, batchTime) as unknown as number // fixes incompatibilities between node and web
      },
    }
  }, [batchTime, flush, stableCallback])
}
