import { classed, type ComponentProps } from '@motion/theme'
import { addComponentName } from '@motion/ui/helpers'

import { forwardRef } from 'react'

import { BaseButton } from './base-button'

/*
  css variables
  --b-bg
  --b-bg-hover
  --b-bg-active
  --b-bg-disabled
  --b-fg
  --b-fg-hover
  --b-fg-disabled
  --b-border
  --b-border-hover
  --b-border-active
  --b-border-disabled
  --b-icon
  --b-icon-hover
  --b-icon-disabled
*/
const StyledButton = classed(BaseButton, {
  base: `
    border border-solid

    bg-[var(--b-bg)]
    text-[var(--b-fg)]
    border-[var(--b-border,var(--b-bg))]
    [&_[data-icon]]:text-[var(--b-icon,var(--b-fg))]
    [&_[data-icon]]:shrink-0

    hover:text-[var(--b-fg-hover,var(--b-fg))]
    hover:bg-[var(--b-bg-hover,var(--b-bg))]
    hover:border-[var(--b-border-hover,var(--b-bg-hover))]
    [&:enabled:hover_[data-icon]]:text-[var(--b-icon-hover,var(--b-icon))]

    active:bg-[var(--b-bg-active,var(--b-bg))]
    active:border-[var(--b-border-active,var(--b-bg-active))]

    disabled:cursor-not-allowed
    disabled:text-[var(--b-fg-disabled,var(--b-fg))]
    disabled:bg-[var(--b-bg-disabled,var(--b-bg))]
    disabled:border-[var(--b-border-disabled,var(--b-bg-disabled))]
    [&:disabled_[data-icon]]:text-[var(--b-icon-disabled,var(--b-fg-disabled,var(--b-fg)))]
  `,
  variants: {
    pressed: { true: '', false: '' },
    variant: {
      solid: '',
      outlined: '',
      muted: `
        setvar-[b-bg=transparent]
        setvar-[b-bg-disabled=transparent]
      `,
      mutedBg: '',
      link: `
        setvar-[b-bg=transparent]
        setvar-[b-bg-disabled=transparent]
        setvar-[b-bg-hover=transparent]
        setvar-[b-bg-active=transparent]
        hover:underline
      `,
    },
    neutralBase: {
      true: '',
      false: '',
    },
    sentiment: {
      primary: '',
      neutral: '',
      error: '',
      success: '',
      warning: '',
      onDark: `
        setvar-[b-bg=transparent]
        setvar-[b-bg-disabled=transparent]
      `,
    },
  },
  compoundVariants: [
    {
      variant: 'solid',
      sentiment: 'primary',
      className: `
      themevar-[b-fg=button-primary-solid-text-default]
      themevar-[b-bg=button-primary-solid-bg-default]
      themevar-[b-icon=button-primary-solid-icon-default]
      themevar-[b-bg-hover=button-primary-solid-bg-hover]
      themevar-[b-bg-active=button-primary-solid-bg-pressed]

      themevar-[b-bg-disabled=button-primary-solid-bg-disabled]
      themevar-[b-fg-disabled=button-primary-solid-text-disabled]
      themevar-[b-icon-disabled=button-primary-solid-icon-disabled]
      themevar-[b-border-disabled=button-primary-solid-border-disabled]
      `,
    },
    {
      variant: 'solid',
      sentiment: 'error',
      className: `
        themevar-[b-fg=button-error-solid-text-default]
        themevar-[b-bg=button-error-solid-bg-default]
        themevar-[b-icon=button-error-solid-icon-default]
        themevar-[b-bg-hover=button-error-solid-bg-hover]
        themevar-[b-bg-active=button-error-solid-bg-pressed]

        themevar-[b-bg-disabled=button-error-solid-bg-disabled]
        themevar-[b-fg-disabled=button-error-solid-text-disabled]
        themevar-[b-icon-disabled=button-error-solid-icon-disabled]
        themevar-[b-border-disabled=button-error-solid-border-disabled]
      `,
    },
    {
      variant: 'solid',
      sentiment: 'warning',
      className: `
        themevar-[b-fg=button-warning-solid-text-default]
        themevar-[b-bg=button-warning-solid-bg-default]
        themevar-[b-icon=button-warning-solid-icon-default]
        themevar-[b-bg-hover=button-warning-solid-bg-hover]
        themevar-[b-bg-active=button-warning-solid-bg-pressed]

        themevar-[b-bg-disabled=button-warning-solid-bg-disabled]
        themevar-[b-fg-disabled=button-warning-solid-text-disabled]
        themevar-[b-icon-disabled=button-warning-solid-icon-disabled]
        themevar-[b-border-disabled=button-warning-solid-border-disabled]
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'neutral',
      className: `
        themevar-[b-fg=button-neutral-outlined-text-default]
        themevar-[b-bg=button-neutral-outlined-bg-default]
        themevar-[b-icon=button-neutral-outlined-icon-default]
        themevar-[b-border=button-neutral-outlined-border-default]

        themevar-[b-bg-hover=button-neutral-outlined-bg-hover]
        themevar-[b-border-hover=button-neutral-outlined-border-hover]
        themevar-[b-bg-active=button-neutral-outlined-bg-pressed]
        themevar-[b-border-active=button-neutral-outlined-border-pressed]

        themevar-[b-fg-disabled=button-neutral-outlined-text-disabled]
        themevar-[b-bg-disabled=button-neutral-outlined-bg-disabled]
        themevar-[b-icon-disabled=button-neutral-outlined-icon-disabled]
        themevar-[b-border-disabled=button-neutral-outlined-border-disabled]
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'primary',
      className: `
        themevar-[b-bg-hover=button-primary-outlined-bg-hover]
        themevar-[b-border-hover=button-primary-outlined-border-hover]
        themevar-[b-bg-active=button-primary-outlined-bg-pressed]
        themevar-[b-border-active=button-primary-outlined-border-pressed]
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'primary',
      neutralBase: true,
      className: `
        themevar-[b-fg=button-neutral-outlined-text-default]
        themevar-[b-bg=button-neutral-outlined-bg-default]
        themevar-[b-icon=button-neutral-outlined-icon-default]
        themevar-[b-border=button-neutral-outlined-border-default]

        themevar-[b-fg-hover=button-primary-outlined-text-default]
        themevar-[b-icon-hover=button-primary-outlined-icon-default]

        themevar-[b-fg-disabled=button-neutral-outlined-text-disabled]
        themevar-[b-bg-disabled=button-neutral-outlined-bg-disabled]
        themevar-[b-icon-disabled=button-neutral-outlined-icon-disabled]
        themevar-[b-border-disabled=button-neutral-outlined-border-disabled]
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'primary',
      neutralBase: false,
      className: `
        themevar-[b-fg=button-primary-outlined-text-default]
        themevar-[b-bg=button-primary-outlined-bg-default]
        themevar-[b-icon=button-primary-outlined-icon-default]
        themevar-[b-border=button-primary-outlined-border-default]
        themevar-[b-fg-disabled=button-primary-outlined-text-disabled]
        themevar-[b-icon-disabled=button-primary-outlined-icon-disabled]
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'success',
      className: `
        themevar-[b-fg=button-success-outlined-text-default]
        themevar-[b-bg=button-success-outlined-bg-default]
        themevar-[b-icon=button-success-outlined-icon-default]
        themevar-[b-border=button-success-outlined-border-default]

        themevar-[b-bg-hover=button-success-outlined-bg-hover]
        themevar-[b-border-hover=button-success-outlined-border-hover]
        themevar-[b-bg-active=button-success-outlined-bg-pressed]
        themevar-[b-border-active=button-success-outlined-border-pressed]

        themevar-[b-fg-disabled=button-success-outlined-text-disabled]
        themevar-[b-bg-disabled=button-success-outlined-bg-disabled]
        themevar-[b-icon-disabled=button-success-outlined-icon-disabled]
        themevar-[b-border-disabled=button-success-outlined-border-disabled]
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'error',
      className: `
        themevar-[b-bg-hover=button-error-outlined-bg-hover]
        themevar-[b-border-hover=button-error-outlined-border-hover]
        themevar-[b-bg-active=button-error-outlined-bg-pressed]
        themevar-[b-border-active=button-error-outlined-border-pressed]
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'error',
      neutralBase: true,
      className: `
        themevar-[b-fg=button-neutral-outlined-text-default]
        themevar-[b-bg=button-neutral-outlined-bg-default]
        themevar-[b-icon=button-neutral-outlined-icon-default]
        themevar-[b-border=button-neutral-outlined-border-default]

        themevar-[b-fg-hover=button-error-outlined-text-default]
        themevar-[b-icon-hover=button-error-outlined-icon-default]

        themevar-[b-fg-disabled=button-neutral-outlined-text-disabled]
        themevar-[b-bg-disabled=button-neutral-outlined-bg-disabled]
        themevar-[b-icon-disabled=button-neutral-outlined-icon-disabled]
        themevar-[b-border-disabled=button-neutral-outlined-border-disabled]
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'error',
      neutralBase: false,
      className: `
        themevar-[b-fg=button-error-outlined-text-default]
        themevar-[b-bg=button-error-outlined-bg-default]
        themevar-[b-icon=button-error-outlined-icon-default]
        themevar-[b-border=button-error-outlined-border-default]
        themevar-[b-fg-disabled=button-error-outlined-text-disabled]
        themevar-[b-icon-disabled=button-error-outlined-icon-disabled]
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'warning',
      className: `
        themevar-[b-bg-hover=button-warning-outlined-bg-hover]
        themevar-[b-border-hover=button-warning-outlined-border-hover]
        themevar-[b-bg-active=button-warning-outlined-bg-pressed]
        themevar-[b-border-active=button-warning-outlined-border-pressed]
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'warning',
      neutralBase: true,
      className: `
        themevar-[b-fg=button-neutral-outlined-text-default]
        themevar-[b-bg=button-neutral-outlined-bg-default]
        themevar-[b-icon=button-neutral-outlined-icon-default]
        themevar-[b-border=button-neutral-outlined-border-default]

        themevar-[b-fg-hover=button-warning-outlined-text-default]
        themevar-[b-icon-hover=button-warning-outlined-icon-default]

        themevar-[b-fg-disabled=button-neutral-outlined-text-disabled]
        themevar-[b-bg-disabled=button-neutral-outlined-bg-disabled]
        themevar-[b-icon-disabled=button-neutral-outlined-icon-disabled]
        themevar-[b-border-disabled=button-neutral-outlined-border-disabled]
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'warning',
      neutralBase: false,
      className: `
        themevar-[b-fg=button-warning-outlined-text-default]
        themevar-[b-bg=button-warning-outlined-bg-default]
        themevar-[b-icon=button-warning-outlined-icon-default]
        themevar-[b-border=button-warning-outlined-border-default]
        themevar-[b-fg-disabled=button-warning-outlined-text-disabled]
        themevar-[b-icon-disabled=button-warning-outlined-icon-disabled]
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'onDark',
      className: `
        themevar-[b-fg=button-on-dark-outlined-text-default]
        themevar-[b-border=button-on-dark-outlined-border-default]
        themevar-[b-bg-hover=button-on-dark-outlined-bg-hover]
        themevar-[b-icon=button-on-dark-outlined-icon-default]
        themevar-[b-border-hover=button-on-dark-outlined-border-default]
        themevar-[b-bg-active=button-on-dark-outlined-bg-pressed]
        themevar-[b-border-active=button-on-dark-outlined-border-default]

        themevar-[b-fg-disabled=button-on-dark-outlined-text-disabled]
        themevar-[b-border-disabled=utton-on-dark-outlined-border-disabled]
        themevar-[b-icon-disabled=button-on-dark-outlined-icon-disabled]
      `,
    },
    {
      variant: 'muted',
      sentiment: 'neutral',
      className: `
        themevar-[b-fg=button-neutral-muted-text-default]
        themevar-[b-icon=button-neutral-muted-icon-default]
        themevar-[b-bg-hover=button-neutral-muted-bg-hover]
        themevar-[b-bg-active=button-neutral-muted-bg-pressed]
        themevar-[b-fg-disabled=button-neutral-muted-text-disabled]
        themevar-[b-icon-disabled=button-neutral-muted-icon-disabled]
      `,
    },
    {
      variant: 'mutedBg',
      sentiment: 'neutral',
      className: `
        themevar-[b-fg=button-neutral-muted-text-default]
        themevar-[b-icon=button-neutral-muted-icon-default]
        themevar-[b-bg=button-neutral-muted-bg-default]
        themevar-[b-bg-hover=button-neutral-muted-bg-hover]
        themevar-[b-bg-active=button-neutral-muted-bg-pressed]
        themevar-[b-fg-disabled=button-neutral-muted-text-disabled]
        themevar-[b-icon-disabled=button-neutral-muted-icon-disabled]
      `,
    },
    {
      variant: 'muted',
      sentiment: 'primary',
      className: `
        themevar-[b-bg-hover=button-primary-muted-bg-hover]
        themevar-[b-bg-active=button-primary-muted-bg-pressed]
      `,
    },
    {
      variant: 'muted',
      sentiment: 'primary',
      neutralBase: true,
      className: `
        themevar-[b-fg=button-neutral-muted-text-default]
        themevar-[b-icon=button-neutral-muted-icon-default]

        themevar-[b-fg-hover=button-primary-muted-text-default]
        themevar-[b-icon-hover=button-primary-muted-icon-default]

        themevar-[b-fg-disabled=button-neutral-muted-text-disabled]
        themevar-[b-icon-disabled=button-neutral-muted-icon-disabled]
      `,
    },
    {
      variant: 'muted',
      sentiment: 'primary',
      neutralBase: false,
      className: `
        themevar-[b-fg=button-primary-muted-text-default]
        themevar-[b-icon=button-primary-muted-icon-default]
        themevar-[b-fg-disabled=button-primary-muted-text-disabled]
        themevar-[b-icon-disabled=button-primary-muted-icon-disabled]
      `,
    },
    {
      variant: 'muted',
      sentiment: 'warning',
      className: `
        themevar-[b-bg-hover=button-warning-muted-bg-hover]
        themevar-[b-bg-active=button-warning-muted-bg-pressed]
      `,
    },
    {
      variant: 'muted',
      sentiment: 'warning',
      neutralBase: true,
      className: `
        themevar-[b-fg=button-neutral-muted-text-default]
        themevar-[b-icon=button-neutral-muted-icon-default]

        themevar-[b-fg-hover=button-warning-muted-text-default]
        themevar-[b-icon-hover=button-warning-muted-icon-default]

        themevar-[b-fg-disabled=button-neutral-muted-text-disabled]
        themevar-[b-icon-disabled=button-neutral-muted-icon-disabled]
      `,
    },
    {
      variant: 'muted',
      sentiment: 'warning',
      neutralBase: false,
      className: `
        themevar-[b-fg=button-warning-muted-text-default]
        themevar-[b-icon=button-warning-muted-icon-default]
        themevar-[b-fg-disabled=button-warning-muted-text-disabled]
        themevar-[b-icon-disabled=button-warning-muted-icon-disabled]
      `,
    },
    {
      variant: 'muted',
      sentiment: 'error',
      className: `
        themevar-[b-bg-hover=button-error-muted-bg-hover]
        themevar-[b-bg-active=button-error-muted-bg-pressed]
      `,
    },
    {
      variant: 'muted',
      sentiment: 'error',
      neutralBase: true,
      className: `
        themevar-[b-fg=button-neutral-muted-text-default]
        themevar-[b-icon=button-neutral-muted-icon-default]

        themevar-[b-fg-hover=button-error-muted-text-default]
        themevar-[b-icon-hover=button-error-muted-icon-default]

        themevar-[b-fg-disabled=button-neutral-muted-text-disabled]
        themevar-[b-icon-disabled=button-neutral-muted-icon-disabled]
      `,
    },
    {
      variant: 'muted',
      sentiment: 'error',
      neutralBase: false,
      className: `
        themevar-[b-fg=button-error-muted-text-default]
        themevar-[b-icon=button-error-muted-icon-default]
        themevar-[b-fg-disabled=button-error-muted-text-disabled]
        themevar-[b-icon-disabled=button-error-muted-icon-disabled]
      `,
    },
    {
      variant: 'muted',
      sentiment: 'onDark',
      className: `
        themevar-[b-fg=button-on-dark-outlined-text-default]
        themevar-[b-icon=button-on-dark-outlined-icon-default]
        themevar-[b-bg-hover=button-on-dark-muted-bg-hover]
        themevar-[b-bg-active=button-on-dark-muted-bg-pressed]

        themevar-[b-fg-disabled=button-on-dark-outlined-text-disabled]
        themevar-[b-icon-disabled=button-on-dark-outlined-icon-disabled]
      `,
    },
    {
      variant: 'link',
      sentiment: 'neutral',
      className: `
        themevar-[b-fg=button-neutral-muted-text-default]
        themevar-[b-icon=button-neutral-muted-icon-default]
        themevar-[b-bg-active=button-neutral-muted-bg-pressed]
        themevar-[b-fg-disabled=button-neutral-muted-text-disabled]
        themevar-[b-icon-disabled=button-neutral-muted-icon-disabled]
        px-0
      `,
    },
    {
      variant: 'link',
      sentiment: 'primary',
      className: `
        themevar-[b-bg-active=button-primary-muted-bg-pressed]
        px-0
      `,
    },
    {
      variant: 'link',
      sentiment: 'primary',
      neutralBase: true,
      className: `
        themevar-[b-fg=button-neutral-muted-text-default]
        themevar-[b-icon=button-neutral-muted-icon-default]

        themevar-[b-fg-hover=button-primary-muted-text-default]
        themevar-[b-icon-hover=button-primary-muted-icon-default]

        themevar-[b-fg-disabled=button-neutral-muted-text-disabled]
        themevar-[b-icon-disabled=button-neutral-muted-icon-disabled]
      `,
    },
    {
      variant: 'link',
      sentiment: 'primary',
      neutralBase: false,
      className: `
        themevar-[b-fg=button-primary-muted-text-default]
        themevar-[b-icon=button-primary-muted-icon-default]
        themevar-[b-fg-disabled=button-primary-muted-text-disabled]
        themevar-[b-icon-disabled=button-primary-muted-icon-disabled]
      `,
    },
    {
      variant: 'link',
      sentiment: 'warning',
      className: `
        themevar-[b-bg-active=button-warning-muted-bg-pressed]
        px-0
      `,
    },
    {
      variant: 'link',
      sentiment: 'warning',
      neutralBase: true,
      className: `
        themevar-[b-fg=button-neutral-muted-text-default]
        themevar-[b-icon=button-neutral-muted-icon-default]

        themevar-[b-fg-hover=button-warning-muted-text-default]
        themevar-[b-icon-hover=button-warning-muted-icon-default]

        themevar-[b-fg-disabled=button-neutral-muted-text-disabled]
        themevar-[b-icon-disabled=button-neutral-muted-icon-disabled]
      `,
    },
    {
      variant: 'link',
      sentiment: 'warning',
      neutralBase: false,
      className: `
        themevar-[b-fg=button-warning-muted-text-default]
        themevar-[b-icon=button-warning-muted-icon-default]
        themevar-[b-fg-disabled=button-warning-muted-text-disabled]
        themevar-[b-icon-disabled=button-warning-muted-icon-disabled]
      `,
    },
    {
      variant: 'link',
      sentiment: 'error',
      className: `
        themevar-[b-bg-active=button-error-muted-bg-pressed]
        px-0
      `,
    },
    {
      variant: 'link',
      sentiment: 'error',
      neutralBase: true,
      className: `
        themevar-[b-fg=button-neutral-muted-text-default]
        themevar-[b-icon=button-neutral-muted-icon-default]

        themevar-[b-fg-hover=button-error-muted-text-default]
        themevar-[b-icon-hover=button-error-muted-icon-default]

        themevar-[b-fg-disabled=button-neutral-muted-text-disabled]
        themevar-[b-icon-disabled=button-neutral-muted-icon-disabled]
      `,
    },
    {
      variant: 'link',
      sentiment: 'error',
      neutralBase: false,
      className: `
        themevar-[b-fg=button-error-muted-text-default]
        themevar-[b-icon=button-error-muted-icon-default]
        themevar-[b-fg-disabled=button-error-muted-text-disabled]
        themevar-[b-icon-disabled=button-error-muted-icon-disabled]
      `,
    },
    {
      variant: 'link',
      sentiment: 'onDark',
      className: `
        themevar-[b-fg=button-on-dark-outlined-text-default]
        themevar-[b-icon=button-on-dark-outlined-icon-default]
        themevar-[b-bg-active=button-on-dark-muted-bg-pressed]

        themevar-[b-fg-disabled=button-on-dark-outlined-text-disabled]
        themevar-[b-icon-disabled=button-on-dark-outlined-icon-disabled]
        px-0
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'neutral',
      pressed: true,
      className: `
        themevar-[b-bg=button-neutral-outlined-bg-selected]
        themevar-[b-border=button-neutral-outlined-border-hover]
        themevar-[b-icon=semantic-neutral-icon-strong]
      `,
    },
    {
      variant: 'outlined',
      sentiment: 'neutral',
      pressed: false,
      className: `
        themevar-[b-bg=button-neutral-outlined-bg-default]
        themevar-[b-border=button-neutral-outlined-border-default]
        themevar-[b-icon=semantic-neutral-icon-default]
      `,
    },
    {
      variant: 'muted',
      sentiment: 'neutral',
      pressed: true,
      className: `
        themevar-[b-icon=semantic-neutral-icon-strong]
        themevar-[b-bg=button-neutral-muted-bg-pressed]
      `,
    },
  ],
})

export type ButtonProps = ComponentProps<typeof StyledButton> & {
  // These props are not allowed
  className?: never
  style?: never
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    { variant = 'solid', sentiment = 'primary', neutralBase = false, ...props },
    ref
  ) {
    const finalVariant =
      sentiment != null &&
      ['neutral', 'onDark'].includes(sentiment) &&
      variant === 'solid'
        ? 'outlined'
        : variant

    return (
      <StyledButton
        ref={ref}
        variant={finalVariant}
        sentiment={sentiment}
        neutralBase={neutralBase}
        {...addComponentName('Button')}
        {...props}
      >
        {props.children}
      </StyledButton>
    )
  }
)
