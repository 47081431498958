import { type Mutable } from '@motion/utils/types'

import { type QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import {
  type ComponentProps,
  createContext,
  type ReactNode,
  useContext,
  useMemo,
  useRef,
} from 'react'

import { type FetchContext } from '../../core/fetch'
import { type CommonApiResponseDefinition } from '../../core/types'

export type RpcContextType = {
  readonly token?: FetchContext<CommonApiResponseDefinition>['token']
  readonly baseUri?: FetchContext<CommonApiResponseDefinition>['baseUri']
  readonly headers?: FetchContext<CommonApiResponseDefinition>['headers']
  readonly executor?: FetchContext<CommonApiResponseDefinition>['executor']
}

export const RpcContext = createContext<RpcContextType>({
  token: undefined,
  baseUri: undefined,
  headers: {},
  executor: undefined,
})

export type PersistOptions = ComponentProps<
  typeof PersistQueryClientProvider
>['persistOptions']

type RpcProviderProps = Partial<RpcContextType> & {
  children: ReactNode
  client: QueryClient
  persistOptions?: PersistOptions | null
}

type ClientProviderProps = {
  client: QueryClient
  persistOptions?: PersistOptions | null
  children: ReactNode
}

function ClientProvider(props: ClientProviderProps) {
  if (props.persistOptions) {
    return (
      <PersistQueryClientProvider
        client={props.client}
        persistOptions={props.persistOptions}
      >
        {props.children}
      </PersistQueryClientProvider>
    )
  }
  return (
    <QueryClientProvider client={props.client}>
      {props.children}
    </QueryClientProvider>
  )
}

// TODO: Add callback for reporting errors
export const RpcProvider = (props: RpcProviderProps) => {
  const tokenRef = useRef<Mutable<RpcContextType>>({})

  tokenRef.current.token = props.token
  tokenRef.current.baseUri = props.baseUri
  tokenRef.current.headers = props.headers
  tokenRef.current.executor = props.executor

  const api = useMemo(() => {
    return tokenRef.current as RpcContextType
  }, [])

  return (
    <RpcContext.Provider value={api}>
      <ClientProvider
        client={props.client}
        persistOptions={props.persistOptions}
      >
        {props.children}
      </ClientProvider>
    </RpcContext.Provider>
  )
}

/* eslint react-refresh/only-export-components: ["warn"] */
export const useRpcContext = (): RpcContextType => {
  return useContext(RpcContext)
}
